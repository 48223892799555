import { ChangeEvent } from 'react';

import Textarea, { TextareaProps } from 'react-expanding-textarea';
import { useTheme } from 'react-native-paper';

export type TextInputEvent = ChangeEvent<HTMLTextAreaElement>

export function textForEvent(event: TextInputEvent) { return event.target.value; }

export function TextInput({ style, ...props }: TextareaProps) {
  const { colors } = useTheme();
  return (
    <Textarea
      style={{
        fontFamily: 'sans-serif',
        borderLeftWidth: 0,
        borderRightWidth: 0,
        borderTopWidth: 0,
        borderBottomWidth: 1,
        borderColor: colors.onSurface,
        backgroundColor: colors.surfaceVariant,
        outlineColor: colors.primary,
        color: colors.onSurfaceVariant,
        ...(style || {})
      }}
      {...props}
    />
  );
}
