import React from "react";
import FixtureLabel from "./FixtureLabel";
import { CoreComponentsContext } from "./core-components";

export type LabelledAttrProps = {
  children: string | undefined;
  label: string;
  small?: boolean;
  progressBarWidth?: number;
  style?: {
    marginLeft?: number;
    marginRight?: number;
    marginBottom?: number,
    flexDirection?: "row" | "column"
  }
}

export default function LabelledAttr(
  { children, label, small, style }: LabelledAttrProps
) {
  const { View, Text } = React.useContext(CoreComponentsContext);
  return (
    <View style={style}>
      <FixtureLabel text={label} small={small} />
      {children ?
        <Text style={{ fontSize: small ? 12 : 14 }}>{children}</Text> :
        null
      }
    </View >
  );
}
