import { DocumentReference, DocumentSnapshot, Timestamp } from "@atgof-firebase/firebase";
import React from "react";
import { useProject } from "./useProject";

function seasonsQuery(project: DocumentReference | undefined, includeHistorical: boolean) {
  if (!project) return;
  const coll = project.collection('seasons');
  if (includeHistorical) return coll.orderBy("wrapDate", "desc");
  return coll.orderBy("wrapDate").startAt(Timestamp.now());
}

export function useSeasons(
  includeHistorical: boolean,
  includeSeasonWithId: string | undefined = undefined
) {
  const project = useProject();
  const [seasons, setSeasons] = React.useState<DocumentSnapshot[]>();
  React.useEffect(() => {
    setSeasons(undefined);
    const q = seasonsQuery(project, includeHistorical);
    return q && q.onSnapshot(snapshot => setSeasons(snapshot.docs));
  }, [project, includeHistorical]);
  const [combinedSeasons, setCombinedSeasons] = React.useState<DocumentSnapshot[]>();
  React.useEffect(
    () => {
      setCombinedSeasons(undefined);
      if (!includeSeasonWithId || seasons?.find(({ id }) => id === includeSeasonWithId))
        setCombinedSeasons(seasons);
      else if (seasons) {
        return project?.collection('seasons').doc(includeSeasonWithId).onSnapshot(
          season => setCombinedSeasons([...seasons, season])
        );
      }
    },
    [seasons, includeSeasonWithId]
  );
  return combinedSeasons;
}
