import { DocumentReference, DocumentSnapshot } from "@atgof-firebase/types";
import { Field, MultiSpecExtra, MultiSpecOnAdd, OnValueChange } from "../helpers/fields";

export const takeRow: Field[] = [
  { key: "preferred", labelKey: "pref", kind: { flags: [{ key: 'preferred', labelKey: "" }] } },
  { key: "timecode", kind: 'shortText' },
  { key: "details", kind: 'paragraph' },
  { key: "length", kind: 'shortText' }
];

type OnCameraCardChange = (value: string, camera?: string | undefined) => void;

export function getRows(
  includeTakes: boolean,
  cameras: string[] | undefined,
  onCameraCardChange: OnCameraCardChange | undefined = undefined,
  onSoundCardChange: OnValueChange | undefined = undefined,
  onSlateChange: OnValueChange | undefined = undefined,
  onTakeAdded: MultiSpecOnAdd | undefined = undefined,
  takesExtra: MultiSpecExtra | undefined = undefined
): Field[][] {
  const cameraFields: Field[] = (cameras ?
    cameras.map(camera => ({
      key: "pa:cameraCardNumbers." + camera,
      labelKey: "camera", labelSuffix: " " + camera, kind: 'shortText',
      onChange: onCameraCardChange ?
        (v: string) => onCameraCardChange(v, camera) : undefined
    })) : [
      {
        key: "pa:cameraCardNumber", labelKey: "camera", kind: 'paragraph', // TODO This is needed so that PA can enter multiple
        onChange: onCameraCardChange
      }
    ]);
  const descriptionFields: Field[] = cameras?.length ?
    cameras.map(camera => ({
      key: "descriptions." + camera,
      labelKey: "description", labelSuffix: " Cam " /* TODO */ + camera, kind: 'paragraph'
    })) : [
      { key: "description", kind: 'paragraph' }
    ];
  return [
    [
      {
        labelKey: "card-number",
        kind: {
          composite: [
            ...cameraFields,
            {
              key: "pa:soundCardNumber", labelKey: "pa:sound", kind: 'shortText',
              onChange: onSoundCardChange
            }
          ]
        }
      },
      {
        key: "slate", kind: 'shortText', onChange: onSlateChange
      },
      { key: "sound", kind: { select: ["sync", "mute", "wildtrack", "guidetrack"] } },
    ],
    [
      ...descriptionFields,
      { key: "dialogue", kind: 'paragraph' },
      { key: "notes-for-the-editor", kind: 'paragraph' },
      { key: "sound-details", kind: 'paragraph' },
    ],
    ...(
      includeTakes ?
        [
          [
            {
              key: "takes",
              multi: {
                collection: (parentRef: DocumentReference) => ({
                  ref: parentRef.collection('takes'),
                  indexField: 'index'
                }),
                numbering: (v: any) =>
                  (v.get('index') + 1) + (v.get('isPickup') ? 'PU' : ''),
                explicitAdd: {
                  extra: {
                    icon: "table-column-plus-after",
                    data: { isPickup: true }
                  }
                },
                onAdd: onTakeAdded,
                extra: takesExtra
              },
              kind: { composite: takeRow },
            }
          ]
        ] :
        []
    )
  ];
}

function compareNumberStrings(a: string | undefined, b: string | undefined) { // TODO Move
  if (a?.length && b?.length) return parseInt(a) - parseInt(b);
  if (a?.length) return 1;
  if (b?.length) return -1;
  return 0;
}

export function compareSlateNumbers(a: string | undefined, b: string | undefined) {
  const [matchA, matchB] = [a, b].map(slateNumber => slateNumber?.match(
    /^(?<prefix>\D*)(?<number>\d*)(?<suffix>\D*)$/
  ));
  if (matchA && matchB) {
    let res = 0;
    for (const k of ['prefix', 'number', 'suffix']) {
      res = k === 'number' ?
        compareNumberStrings(matchA.groups!!.number, matchB.groups!!.number) :
        matchA.groups!![k].localeCompare(matchB.groups!![k]);
      if (res) return res;
    }
    return res;
  }
  if (matchA) return 1;
  if (matchB) return -1;
  return 0;
}

export function compareSheets(a: DocumentSnapshot, b: DocumentSnapshot) {
  return compareSlateNumbers(a.get('pa:slate'), b.get('pa:slate'));
}
