import React from "react";
import { DocumentData, DocumentReference, DocumentSnapshot, FieldValue } from "@atgof-firebase/firebase";
import { ProgressBar } from "react-native-paper";
import { UserContext } from "../../data/userContext";
import {
  AnnotationLayerKey, AnnotationLayerProps, getAnnotationLayer, strokeWidth
} from "../../common/script";
import { Platform, ViewStyle } from "react-native";
import { AnnotationCanvas, AnnotationCanvasLayer, AnnotationCanvasRef } from "./AnnotationCanvas";
import { useRefFor } from "./useRefFor";

function useAnnotationLayers(
  script: DocumentSnapshot, viewableAnnotationLayers: AnnotationLayerKey[]
) {
  return React.useMemo<AnnotationLayerProps[] | null>(
    () => {
      const takeLayer = getAnnotationLayer(script, 'takeAnnotations', '#0000ff');
      const scriptLayer = getAnnotationLayer(script, 'annotations', '#000000');
      const layers = viewableAnnotationLayers
        .map(k => k === 'annotations' ? scriptLayer : takeLayer);
      if (!layers.every(layer => layer !== undefined)) return null;
      return layers as AnnotationLayerProps[];
    },
    [script, viewableAnnotationLayers]
  );
}

type PersistenceContext = {
  version: number,
  userRef: DocumentReference,
  scriptRef: DocumentReference,
  annotationLayers: AnnotationLayerProps[] | null
};

function persistLayers(
  { version, userRef, scriptRef, annotationLayers }: PersistenceContext,
  canvasLayers: AnnotationCanvasLayer[]
) {
  let data: DocumentData = {
    lastModifiedBy: userRef,
    lastModifiedAt: FieldValue.serverTimestamp()
  };
  for (const { key, paths } of canvasLayers) {
    const layer = annotationLayers?.find(layer => layer.key === key);
    if (layer) data[layer.key] = paths.map(svg => ({
      svg, version, os: Platform.OS
    }));
  }
  scriptRef.update(data);
}

export type EditingMode =
  (
    ({ pencil: AnnotationLayerKey } | { eraser: AnnotationLayerKey[] }) &
    { available: AnnotationLayerKey[] }
  ) |
  undefined;

export function preferredEditingLayer(candidates: AnnotationLayerKey[]): AnnotationLayerKey {
  return candidates.includes('takeAnnotations') ? 'takeAnnotations' : 'annotations';
}

export function ScriptAnnotationLayers(
  {
    layoutVersion, viewableAnnotationLayers, editingMode,
    style, script
  }:
    {
      layoutVersion: number;
      viewableAnnotationLayers: AnnotationLayerKey[];
      editingMode: EditingMode;
      style: ViewStyle;
      script: DocumentSnapshot;
    }
) {
  const { user } = React.useContext(UserContext);
  const ref = React.useRef<AnnotationCanvasRef>(null);
  const annotationLayers = useAnnotationLayers(script, viewableAnnotationLayers);
  const persistPathsContextRef = useRefFor({
    version: layoutVersion,
    userRef: user.ref,
    scriptRef: script.ref,
    annotationLayers
  }, [layoutVersion, user, script, annotationLayers]);
  React.useEffect(() => {
    ref.current?.setLayers(
      annotationLayers?.map(
        ({ key, colour, paths }): AnnotationCanvasLayer => ({
          key,
          color: colour,
          paths: paths.map(({ svg }) => svg),
          readonly: !(
            editingMode && (
              ('pencil' in editingMode && key === editingMode.pencil) ||
              ('eraser' in editingMode && editingMode.eraser.includes(key))
            )
          )
        })
      ) ?? []
    );
  }, [editingMode, annotationLayers]);
  React.useEffect(() => {
    ref.current?.setEraseMode(editingMode && 'eraser' in editingMode ? true : false);
    return () => {
      const canvas = ref.current;
      if (!canvas) return;
      const context = persistPathsContextRef.current;
      if (!context) return;
      persistLayers(context, canvas.getLayers());
    };
  }, [editingMode]);
  if (!annotationLayers) return <ProgressBar indeterminate />;
  return (
    <AnnotationCanvas
      ref={ref}
      strokeWidth={strokeWidth}
      style={style}
      width="100%" height="100%"
    />
  );
}
