import { DocumentReference } from "@atgof-firebase/firebase";
import { useProject } from "./useProject";
import React from "react";
import { CharChange, ExtendedDiff } from "../common/diffs";
import { Platform } from "react-native";

type FieldDiff = {
  u: DocumentReference | undefined;
  d: ExtendedDiff<any, any>
}

type AnnotatedTextEntry = { createdBy: string; text: string, deletedBy?: string }

export type AnnotatedText = AnnotatedTextEntry[];

type AnnotatedField =
  { kind: 'text', content: AnnotatedText } | { kind: 'array', content: AnnotatedField[] }

export type AnnotatedFields = Record<string, AnnotatedField>

const defaultColour = '#945200';

export function removeSameUserDeletions(annotatedText: AnnotatedText | undefined) {
  return annotatedText?.filter(({ createdBy, deletedBy }) => createdBy !== deletedBy);
}

export function getAnnotatedText(fields: AnnotatedFields | null | undefined, keypath: any[]): AnnotatedText | undefined {
  if (!fields) return;
  const annotatedField = keypath.length > 0 && fields[keypath[0]];
  if (!annotatedField) return;
  if (annotatedField.kind === 'text') {
    if (keypath.length == 1)
      return annotatedField.content;
    console.error(`Invalid keypath ${keypath} for text field ${annotatedField}`);
  }
  if (annotatedField.kind === 'array') {
    if (keypath.length == 2 && typeof keypath[1] === 'number') {
      const candidate = annotatedField.content[keypath[1]];
      if (!candidate) return;
      if (candidate.kind === 'text') return candidate.content;
      console.error(`Nested arrays are unsupported (keypath ${keypath})`);
    }
    else console.error(`Invalid keypath ${keypath} for array field ${annotatedField}`);
  }
}

function applyTextChanges(
  annotatedField: AnnotatedField | undefined, changes: CharChange[], colour: string
): AnnotatedField | undefined {
  let toProcess = [...((annotatedField?.kind === 'text' && annotatedField?.content) || [])].reverse();
  let processed: AnnotatedText = [];
  for (const { value, added, removed } of changes) {
    let entry;
    for (entry = toProcess.pop(); entry?.deletedBy; entry = toProcess.pop()) {
      processed.push(entry);
    }
    if (entry && !entry.deletedBy) toProcess.push(entry);
    if (added) processed.push({ createdBy: colour, text: value });
    else {
      let charsToProcess = value.length;
      for (entry = toProcess.pop(); entry; entry = toProcess.pop()) {
        if (!entry.deletedBy) {
          if (charsToProcess < entry.text.length) {
            processed.push(
              removed ?
                { ...entry, deletedBy: colour } :
                { ...entry, text: entry.text.substring(0, charsToProcess) }
            );
            toProcess.push({ ...entry, text: entry.text.substring(charsToProcess) })
            break;
          }
          charsToProcess -= entry.text.length;
        }
        processed.push(removed ? { ...entry, deletedBy: colour } : entry);
        if (charsToProcess == 0) break;
      }
    }
  }
  return { kind: 'text', content: [...processed, ...toProcess.reverse()] };
}

function applyDeletion(
  annotatedField: AnnotatedField | undefined, colour: string
): AnnotatedField | undefined {
  if (!annotatedField) return;
  if (annotatedField.kind === 'array')
    return {
      ...annotatedField,
      content: annotatedField.content
        .map(af => applyDeletion(af, colour))
        .filter(x => x) as AnnotatedField[]
    };
  const content = annotatedField.content.filter(({ text }) => text.length)
    .map(annotatedText => ({ ...annotatedText, deletedBy: colour }));
  if (content.length == 0) return;
  return { ...annotatedField, content };
}

function applyFieldDiff(
  annotatedField: AnnotatedField | undefined,
  { u, d }: FieldDiff,
  colourMap: Record<string, string>
): AnnotatedField | undefined {
  const colour = (u && colourMap[u.id]) || defaultColour;
  function arrayContent(rhs: any) {
    return rhs.map((text: string) => ({ kind: 'text', content: [{ createdBy: colour, text }] }));
  }
  switch (d.kind) {
    case 'N':
      if (typeof d.rhs === 'object')
        return {
          kind: 'array',
          content: arrayContent(d.rhs)
        };
      return { kind: 'text', content: [{ createdBy: colour, text: d.rhs }] };
    case 'D':
      return applyDeletion(annotatedField, colour);
    case 'E':
      if (d.path?.length && d.path.length > 1 && annotatedField?.kind === 'array') {
        const idx = d.path[1];
        const content = [...annotatedField.content];
        content[idx] = applyFieldDiff(content[idx], { u, d: { ...d, path: d.path.slice(2) } }, colourMap)!;
        return { kind: 'array', content };
      }
      if ('charChanges' in d) {
        return applyTextChanges(annotatedField, d.charChanges, colour);
      }
      const annotatedField_ = applyDeletion(annotatedField, colour);
      if (annotatedField_?.kind === 'array') {
        return {
          kind: 'array',
          content: arrayContent(d.rhs)
        };
      }
      return {
        kind: 'text',
        content: [
          ...(annotatedField_?.content || []),
          { createdBy: colour, text: d.rhs }
        ]
      };
    case 'A':
      if (annotatedField?.kind !== 'array') {
        console.error(`Expected array for diff ${d}: found ${annotatedField}`);
      }
      else {
        const content = annotatedField.content;
        const newVal = applyFieldDiff(content[d.index], { u, d: d.item }, colourMap);
        if (!newVal) delete content[d.index];
        else content[d.index] = newVal;
        return { ...annotatedField, content };
      }
  }
  return annotatedField;
}

export function useAnnotatedFields(docRef: DocumentReference | undefined, fieldKeys: string[]) {
  const project = useProject();
  const [annotatedFields, setAnnotatedFields] =
    React.useState<{ fields: AnnotatedFields, colourMap: Record<string, string> } | null>();
  React.useEffect(
    () => {
      if (Platform.OS === 'web' && // TODO!!
        project && docRef && fieldKeys.length <= 10) { // TODO Warn about this size limit
        return docRef.collection('diffs').where('attr', 'in', fieldKeys)
          .orderBy('timestamp', 'asc')
          .onSnapshot(({ docs }) => {
            Promise.all(
              [
                ...(
                  new Set<string>(docs.map(doc => {
                    const user = doc.get('user') as DocumentReference | undefined;
                    if (user) return user.id;
                  }).filter(x => x) as string[]).keys()
                )
              ].map(userId => project.collection('memberships').doc(userId).get())
            ).then(memberships => {
              const colourMap = memberships.reduce(
                (colourMap, membership) => (
                  { ...colourMap, [membership.id]: membership.get('inkColour') }
                ),
                {} as Record<string, string>
              );
              setAnnotatedFields({
                colourMap,
                fields:
                  docs.reduce(
                    (afs, doc): AnnotatedFields => {
                      const attr = doc.get('attr');
                      if (!attr) return afs;
                      const af = applyFieldDiff(
                        afs[attr],
                        {
                          u: doc.get('user'),
                          d: doc.get('change')
                        },
                        colourMap);
                      return af ? { ...afs, [attr]: af } : afs;
                    },
                    {} as AnnotatedFields
                  )
              });
            });
          });
      }
      setAnnotatedFields(null);
    },
    [project?.path, docRef?.path]
  );
  return annotatedFields;
}
