import { DocumentSnapshot } from "@atgof-firebase/firebase";
import { UploadStatus, Uploader } from "./uploader";

const cacheDbSpec = {
  dbName: "upload_cache",
  version: 1,
  objectStore: {
    name: "entries",
    keyPath: "storagePath"
  }
};

export class UploaderImpl extends Uploader {
  private hasServiceWorker: boolean;
  private statuses = new Map<string, UploadStatus>();
  private cacheDb: IDBDatabase | undefined = undefined;

  public constructor(
    uploadUri: string,
    initialServiceWorkerStatuses: Promise<UploadStatus[]> | undefined
  ) {
    super(uploadUri);
    this.getCacheDb();
    this.hasServiceWorker = initialServiceWorkerStatuses !== undefined;
    initialServiceWorkerStatuses?.then(statuses => {
      for (const status of statuses) {
        this.statuses.set(status.storagePath, status);
      }
      this.handleUpdate([...this.statuses.values()]);
    }).catch(error =>
      console.error("Unable to get upload statuses from service worker", error)
    );
  }

  public onServiceWorkerUploadStart(upload: UploadStatus) {
    const { storagePath } = upload;
    this.statuses.set(storagePath, upload);
    this.handleUpdate([...this.statuses.values()]);
  }

  public onServiceWorkerUploadSuccess(upload: UploadStatus) {
    const { storagePath } = upload;
    this.statuses.delete(storagePath);
    this.handleUpdate([...this.statuses.values()]);
  }

  public onServiceWorkerUploadFailure(upload: UploadStatus) {
    const { storagePath } = upload;
    this.statuses.set(storagePath, upload);
    this.handleUpdate([...this.statuses.values()]);
  }

  public override onUserConnected(user: DocumentSnapshot) {
    // TODO Make service worker respond to change of user
    if (!this.hasServiceWorker) this.retryUploads(user, [...this.statuses.values()]);
  }

  protected override getUploadStatuses() {
    return [...this.statuses.values()];
  }
  protected override getUploadStatus(storagePath: string) {
    return this.statuses.get(storagePath);
  }
  protected override setUploadStatus(storagePath: string, status: UploadStatus) {
    if (!this.hasServiceWorker) {
      this.statuses.set(storagePath, status);
      this.handleUpdate([...this.statuses.values()]);
    }
  }
  protected override setFinished(storagePath: string) {
    if (!this.hasServiceWorker) {
      this.statuses.delete(storagePath);
      this.handleUpdate([...this.statuses.values()]);
    }
  }

  protected override async copyTemporaryContentToCache(
    storagePath: string, dataURI: string
  ) {
    const { objectStore } = cacheDbSpec;
    const transaction = (await this.getCacheDb())!!.transaction(
      [objectStore.name], "readwrite"
    );
    return new Promise<string>(
      (resolve, reject) => {
        const req = transaction.objectStore(objectStore.name).put({ storagePath, dataURI });
        req.onsuccess = _ => resolve(storagePath);
        req.onerror = _ => reject(req.error);
      }
    );
  }

  public override async getUriForCacheKey(storagePath: string) {
    const { objectStore } = cacheDbSpec;
    const transaction = (await this.getCacheDb())!!.transaction(
      [objectStore.name], "readwrite"
    );
    const uri = await new Promise<string>(
      (resolve, reject) => {
        const req = transaction.objectStore(objectStore.name).get(storagePath);
        req.onsuccess = _ => resolve(req.result.dataURI);
        req.onerror = _ => reject(req.error);
      }
    );
    return uri;
  }

  protected override async uploadFromLocalFile(upload: UploadStatus) {
    const { storagePath, cacheKey, mimeType } = upload;
    const localURI = await this.getUriForCacheKey(cacheKey!!);
    const form = new FormData();
    if (!mimeType) throw Error("Invalid data URL for file");
    const blob = await new Promise<Blob>((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onload = () => resolve(xhr.response);
      xhr.onerror = () => reject(new Error("Conversion of data URL to Blob failed"));
      xhr.responseType = "blob";
      xhr.open("GET", localURI!!, true);
      xhr.send(null);
    });
    form.append(storagePath, new File([blob], "file", { type: mimeType }));
    await this.uploadForm(upload, form);
  }

  protected override async retryUploads(user: DocumentSnapshot, uploads: UploadStatus[]) {
    // TODO
  }

  protected override shouldIncludeStatusHeader() { return this.hasServiceWorker }

  private async getCacheDb() { // TODO Refactor what this has in common with downloader.getRefsDb()
    if (this.cacheDb) return this.cacheDb;
    const { dbName, version } = cacheDbSpec;
    try {
      const req = indexedDB.open(dbName, version);
      this.cacheDb = await new Promise((resolve, reject) => {
        req.onsuccess = _ => resolve(req.result);
        req.onerror = _ => reject(req.error);
        req.onupgradeneeded = _ => {
          const { name, keyPath } = cacheDbSpec.objectStore;
          req.result.createObjectStore(name, { keyPath });
        };
      });
    }
    catch (err) {
      console.error(
        `Unable to open file references database ${dbName} version ${version}`
      );
    }
    return this.cacheDb;
  }
}
