import { useNavigation } from "@react-navigation/native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import React from "react";
import { View } from "react-native";
import { Phrase, PhraseKey } from "../common/phrases";
import FieldsRow from "../components/fields/FieldsRow";
import DetailPage from "../components/pages/DetailPage";
import { SheetCategory } from "../common/sheet";
import { UserContext } from "../data/userContext";
import { DocumentSnapshot, FieldValue } from "@atgof-firebase/firebase";
import { LanguageContext } from "../common/language";
import { RootStackParamList } from "../types";
import { SheetSections } from "../components/SheetSections";
import { episodeLabel } from "../common/util";
import PhotoStrip from "../components/PhotoStrip";
import { referencePathForLook } from "../data/backend/downloader";
import { useDocument } from "../data/useDocument";
import { useDocumentReference } from "../data/firestore";

export function lookTitle(
  ph: (k: PhraseKey) => Phrase, look: DocumentSnapshot | undefined, isNew: boolean = false
) {
  return look?.get('displayName') || (isNew ? ph('new-look') : ph('unnamed-look'));
}

export default function LookScreen({ route }: NativeStackScreenProps<RootStackParamList, 'look'>) {
  const { ph } = React.useContext(LanguageContext);
  const { path, episodePath, subjectName } = route.params;
  const isNew = route.params.isNew === 'true';
  const { goBack } = useNavigation();
  const { user } = React.useContext(UserContext);
  const look = useDocument(useDocumentReference(path));
  function deleteLook() {
    if (episodePath || !look) return;
    look.ref.update({ deleted: FieldValue.serverTimestamp(), lastModifiedBy: user.ref })
      .then(() => goBack());
  }
  const category: SheetCategory | undefined = look?.get('category');
  return (
    <DetailPage
      title={
        `${subjectName} - ${episodePath ? episodeLabel(ph, episodePath, true) : lookTitle(ph, look, isNew)}`
      }
      deleteItem={episodePath ? undefined : deleteLook}
    >
      {look && category &&
        <View>
          <PhotoStrip
            sheetRef={look.ref} sheetCategory={category}
            referencePath={referencePathForLook(look?.ref)}
            style={{ marginTop: 16, marginBottom: 16 }}
          />
          {!episodePath &&
            <FieldsRow
              doc={look}
              fields={[
                {
                  key: 'displayName', labelKey: 'look-name',
                  kind: 'text', isTitle: true
                }
              ]}
            />}
          <SheetSections doc={look} category={category} />
        </View>
      }
    </DetailPage>
  );
}
