import { DocumentReference, DocumentSnapshot, FieldValue } from "@atgof-firebase/firebase";
import Handlers from "./handlers";
import { authHeadersForCurrentUser } from "../auth";
import { UPLOAD_STATUS_HEADER } from "../../service-worker/common";
import { getDocumentReference } from "../../common/firestore";

type Primitive = string | boolean | number | null;

export type UploadMetadata =
  Record<string, Primitive | Record<string, Primitive> | Array<Primitive>>;

type UploadError = {
  name: string,
  message: string,
  stack?: string
}

export type UploadStatus = {
  docRefPath: string;
  storagePath: string;
  mimeType?: string | null;
  cacheKey?: string;
  error?: UploadError;
}

export function uploadError(error: unknown): UploadError {
  let name = "Unknown error";
  let message: string | undefined;
  let stack: string | undefined;
  if (typeof error === 'object' && error) {
    if ('name' in error && typeof error.name === 'string') name = error.name;
    if ('message' in error && typeof error.message === 'string') message = error.message;
    if ('stack' in error && typeof error.stack === 'string') stack = error.stack;
  }
  if (!message) message = `${error}`;
  return { message, name, stack };
}

function mimeTypeFromDataURI(uri: string | undefined) {
  const m = uri?.match(/^data:([^;,]+);/);
  return m ? m[1] : null;
}

export function fileExtensionForURI(uri: string) {
  const m = uri.match(/^file:.*(\.[^\.]+)$/);
  return m && m[1];
}

function getUploadFormForFileContent(file: File, storagePath: string) {
  const form = new FormData();
  form.append(storagePath, file);
  const originalExt = fileExtensionForURI('file:' + file.name);
  if (originalExt) form.set('originalExt', originalExt);
  return form;
}

export abstract class Uploader {
  protected readonly endpointURI: string;
  protected handlers = new Handlers<UploadStatus[]>("upload");

  public constructor(endpointURI: string) {
    this.endpointURI = endpointURI;
  }

  public abstract onUserConnected(user: DocumentSnapshot): void;

  public onUploads(handler: (uploads: UploadStatus[]) => void) {
    return this.handlers.add(handler, this.getUploadStatuses());
  }

  public async uploadFile(
    input: string | File | null,
    storagePath: string,
    docRef: DocumentReference,
    user: DocumentSnapshot,
    metadata: UploadMetadata | undefined = undefined
  ) {
    try {
      const localURI = (typeof input === 'string' && input) || undefined;
      const mimeType = mimeTypeFromDataURI(localURI);
      let status = this.getUploadStatus(storagePath);
      if (!status) {
        await docRef.set({
          path: storagePath,
          createdBy: user?.ref,
          lastModifiedBy: user?.ref,
          createdAt: FieldValue.serverTimestamp(),
          ...(metadata ?? {})
        });
        status = {
          docRefPath: docRef.path,
          storagePath, mimeType
        };
      }
      else delete status.error;
      await this.setStatus(storagePath, status);
      if (!input || localURI) {
        let cacheKey;
        if (localURI) {
          try {
            cacheKey = await this.copyTemporaryContentToCache(storagePath, localURI);
            await this.setStatus(storagePath, { ...status, cacheKey });
          }
          catch (error) {
            console.error(`Unable to cache local URI for ${storagePath}`, error);
          }
        }
        if (!localURI) {
          cacheKey = status.cacheKey;
        }
        if (!cacheKey) throw new Error(`No file to upload for ${storagePath}`);
        status.cacheKey = cacheKey;
        await this.uploadFromLocalFile(status);
      }
      else {
        console.debug(`Uploading file contents for ${storagePath}`);
        await this.uploadForm(
          status,
          getUploadFormForFileContent(input as File, storagePath)
        );
      }
      this.setFinished(storagePath);
    }
    catch (error) {
      console.error(`Error uploading file ${storagePath}, ${error}`);
      this.setStatus(storagePath, status => ({
        ...status,
        error: uploadError(error)
      }));
    }
  }

  public cancelUpload(docRef: DocumentReference) {
    // TODO Either implement proper cancellation, or remove this
    // function after re-factoring its callers
    const status = this.getUploadStatuses()
      .find(({ docRefPath }) => docRefPath === docRef.path);
    if (status) this.setFinished(status.storagePath);
  }

  protected abstract getUploadStatuses(): UploadStatus[];
  protected abstract getUploadStatus(storagePath: string): UploadStatus | undefined;
  protected abstract setUploadStatus(storagePath: string, status: UploadStatus): void;
  protected abstract setFinished(storagePath: string): void;

  protected abstract copyTemporaryContentToCache(
    storagePath: string,
    tempFileURI: string
  ): Promise<string | undefined>;
  public abstract getUriForCacheKey(cacheKey: string): Promise<string | undefined>;

  protected abstract uploadFromLocalFile(upload: UploadStatus): Promise<void>;

  protected async retryUploads(user: DocumentSnapshot, uploads: UploadStatus[]) {
    console.debug("Retrying uploads", uploads);
    for (const upload of uploads) {
      console.debug(`Considering upload ${upload}`);
      const { docRefPath, storagePath } = upload;
      let docRef;
      try {
        docRef = getDocumentReference(user, docRefPath);
      }
      catch (err) {
        console.error(`Unable to get ref for doc ${docRefPath}: ${err}`);
        continue;
      }
      this.uploadFile(null, storagePath, docRef, user);
    }
  }


  protected handleUpdate(uploads: UploadStatus[]) {
    this.handlers.handle(uploads); // TODO Sort uploads consistently 
  }

  protected shouldIncludeStatusHeader() { return false; }

  protected async uploadForm(upload: UploadStatus, form: FormData) {
    const headers = await authHeadersForCurrentUser();
    await new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onload = resolve;
      xhr.onerror = () => reject(new Error("Network request failed"));
      xhr.open("POST", this.endpointURI, true);
      for (const header of Object.keys(headers)) {
        xhr.setRequestHeader(header, headers[header]);
      }
      if (this.shouldIncludeStatusHeader()) {
        xhr.setRequestHeader(
          UPLOAD_STATUS_HEADER,
          btoa(JSON.stringify(upload))
        );
      }
      form.set('doc', upload.docRefPath);
      xhr.send(form);
    });
  }

  protected async setStatus(
    storagePath: string,
    status: UploadStatus | ((existingStatus: UploadStatus) => UploadStatus)
  ) {
    if (typeof status === 'function') {
      const existingStatus = this.getUploadStatus(storagePath);
      if (existingStatus) this.setUploadStatus(storagePath, status(existingStatus));
    }
    else this.setUploadStatus(storagePath, status);
  }
}
