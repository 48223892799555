import { storageCacheName } from "../../config/firebase";
import { getStoragePath } from "../cloud-storage";
import { stripToken } from "../url";
import { Downloader, Reference } from "./downloader";

const refsDbSpec = {
  dbName: "file_refs",
  version: 1,
  objectStore: {
    name: "entries",
    keyPath: "key"
  }
};

export class DownloaderImpl extends Downloader {
  private cache: Cache | undefined = undefined;
  private refsDb: IDBDatabase | undefined = undefined;

  public constructor() {
    super();
    this.getRefsDb();
  }

  public onCacheUpdated(cacheName: string, url: string) {
    if (cacheName === storageCacheName) {
      const path = getStoragePath(url);
      if (path) {
        this.registerCompletion(path);
      }
    }
  }

  override async getFreeStorageEstimate() {
    const estimate = await navigator.storage?.estimate();
    if (!estimate) return;
    const { usage, quota } = estimate;
    if (usage === undefined || quota === undefined) return;
    return quota - usage;
  }

  override async getCachedURI(remoteURI: string) {
    const c = await this.getCache();
    if (c && await c.match(stripToken(remoteURI))) return remoteURI;
  }

  override async doDownload(remoteURI: string) {
    // No need to download here, as the service worker will download
    // and cache when it intercepts the subsequent request
    return { status: 0, uri: remoteURI };
  }

  override async readAsString(uri: string) {
    return (await fetch(new Request(uri))).text();
  }

  override async putReference(key: string, reference: Reference) {
    reference.cachedURI = stripToken(reference.cachedURI);
    const { objectStore } = refsDbSpec;
    const transaction = (await this.getRefsDb())!!.transaction(
      [objectStore.name], "readwrite"
    );
    return new Promise<void>(
      (resolve, reject) => {
        const req = transaction.objectStore(objectStore.name).put({ key, reference });
        req.onsuccess = _ => resolve();
        req.onerror = _ => reject(req.error);
      }
    );
  }

  override async getAllCacheKeys() {
    const c = await this.getCache();
    return (await c!!.keys()).map(r => r.url).filter(r => r.match(/[\?&]alt=media/));
  }

  override async getAllReferenceEntries() {
    const { objectStore } = refsDbSpec;
    const req = (await this.getRefsDb())!!.transaction(objectStore.name)
      .objectStore(objectStore.name).getAll();
    return new Promise<[string, Reference][]>(
      (resolve, reject) => {
        req.onsuccess = _ => resolve(
          req.result.map(
            ({ key, reference }) => [key as string, reference as Reference]
          )
        );
        req.onerror = _ => reject(req.error);
      }
    );
  }

  private async getCache() {
    if (this.cache) return this.cache;
    try {
      this.cache = await caches.open(storageCacheName);
    }
    catch (err) {
      console.error(`Unable to open cache ${storageCacheName}`);
    }
    return this.cache;
  }

  private async getRefsDb() {
    if (this.refsDb) return this.refsDb;
    const { dbName, version } = refsDbSpec;
    try {
      const req = indexedDB.open(dbName, version);
      this.refsDb = await new Promise((resolve, reject) => {
        req.onsuccess = _ => resolve(req.result);
        req.onerror = _ => reject(req.error);
        req.onupgradeneeded = _ => {
          const { name, keyPath } = refsDbSpec.objectStore;
          req.result.createObjectStore(name, { keyPath });
        };
      });
    }
    catch (err) {
      console.error(
        `Unable to open file references database ${dbName} version ${version}`
      );
    }
    return this.refsDb;
  }
}
