import React from "react";
import { CoreComponentsContext } from "./core-components";
import LabelledAttr from "./LabelledAttr";

type Attr = { label: string; value: string | undefined }

export function AttrStack(
  { attrs, small, hideEmpties }: { attrs: Attr[], small?: boolean; hideEmpties?: boolean }
) {
  const { View } = React.useContext(CoreComponentsContext);
  const attrsToShow = hideEmpties ? attrs.filter(({ value }) => value?.length ?? 0 > 0) : attrs;
  return (
    <View style={{ justifyContent: "space-between", flexDirection: "row" }}>
      {attrsToShow.map(({ label, value }, i) => (
        <LabelledAttr key={i} label={label} small={small}
          style={{ marginLeft: i > 0 ? 16 : 0 }}>
          {value}
        </LabelledAttr>
      ))}
    </View>
  );
}

