import { DocumentReference, DocumentSnapshot } from "@atgof-firebase/types";
import { useSnapshot } from "./useSnapshot";
import _ from "lodash";

export function useDocument(ref: DocumentReference | undefined) {
  return useSnapshot<DocumentSnapshot | undefined>(
    ref ? _.bind(ref.onSnapshot, ref) : undefined,
    [ref?.path]
  );
}
