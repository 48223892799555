import { SheetSubjectKind } from "./sheet-subject";
import { DocumentReference, Query } from "@atgof-firebase/types";

export type SubjectSpec = { subjectKind: SheetSubjectKind; subjectName: string };

export function hasSubject(q: Query, { subjectKind, subjectName }: SubjectSpec) {
  return q.where(subjectKind === 'character' ? 'cast' : 'sets', 'array-contains', subjectName);
}

export function subjectRef(
  project: DocumentReference | undefined,
  { subjectKind, subjectName }: SubjectSpec
) {
  return project?.collection(`${subjectKind}s`).doc(subjectName.replace(/\//g, '_')) // TODO
}
