import React from "react";
import { ProjectContext } from "./projectContext";
import { useSnapshot } from "./useSnapshot";
import { onUserInkColours, UserInkColour } from "../common/membership";
import _ from "lodash";
import { useProject } from "./useProject";

export function useUserInkColours() {
  const project = useProject();
  const { adminCategories } = React.useContext(ProjectContext);
  return useSnapshot<UserInkColour[] | undefined>(
    _.curry(onUserInkColours)(project, adminCategories),
    [project, adminCategories]
  );
}
