import { AlertBox } from '../components/AlertBox';
import { LanguageContext } from '../common/language';
import ErrorDetail from '../components/ErrorDetail';
import MicrosoftSignin from '../signin/MicrosoftSignin';
import GoogleSignin from '../signin/GoogleSignin';
import React, { FunctionComponent } from 'react';
import { SigninProps, SigninProvider, SigninState, SigninToken } from '../signin';
import { Button, Text } from 'react-native-paper';
import IntroDialog from '../components/IntroDialog';
import { BackendContext } from '../data/backend';

export default function LoginScreen(
  { signinState, onToken }: { signinState: SigninState; onToken: (token: SigninToken) => void }
) {
  const { ph } = React.useContext(LanguageContext);
  const [signinStatus] = signinState;
  const inProgress = signinStatus.activeForProvider;
  const error = signinStatus.error;
  let [errorCode, errorDetail] =
    error?.type
      ? [error.type, error.error]
      : (error?.code
        ? [error.code, error]
        : [null, null]);
  const cancelled = errorCode === 'cancel' || errorCode === 'dismiss';
  const shouldTryAgain = cancelled || errorCode === 'error' || errorCode === 'auth/invalid-credential';
  const errorPhrase = cancelled ? "login-cancelled" : null;
  const [alertTitlePhrase, alertMessagePhrase] = errorDetail && !errorPhrase
    ? [undefined, "login-failed-with-reason"]
    : [errorPhrase || "login-failed", undefined];
  const { functionsURI } = React.useContext(BackendContext);
  return (
    <IntroDialog
      title={ph('log-in-to-app') as string}
      actions={
        ([
          { provider: 'microsoft', component: MicrosoftSignin },
          { provider: 'google', component: GoogleSignin }
        ] as { provider: SigninProvider, component: FunctionComponent<SigninProps> }[])
          .filter(
            ({ provider }) => inProgress === undefined || inProgress === provider
          )
          .map(({ provider, component }) =>
            React.createElement<SigninProps>(
              component,
              {
                key: provider,
                signinState: signinState,
                onToken: onToken,
                functionsURI,
                button: (onPress: () => void) =>
                  <Button
                    mode="contained"
                    loading={inProgress === provider}
                    onPress={onPress}>
                    {
                      ph(inProgress === provider ? 'logging-in' : 'log-in-with-' + provider) as string
                    }
                  </Button>
              }
            )
          )
      }
    >
      <AlertBox visible={error ? true : false} status="error"
        title={alertTitlePhrase && ph(alertTitlePhrase) as string}
        message={alertMessagePhrase && ph(alertMessagePhrase) as string}
      >
        {
          shouldTryAgain ?
            <Text>{ph(cancelled ? "try-again" : "try-again-later") as string}</Text> : null
        }
        {errorDetail ? <ErrorDetail>{errorDetail.toString()}</ErrorDetail> : null}
      </AlertBox>
      <AlertBox
        visible={inProgress || error ? false : true} status="info"
        message={ph("log-in-prompt") as string}
      />
    </IntroDialog>
  );
};
