import { useNavigation } from "@react-navigation/native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import React from "react";
import { View } from "react-native";
import { Phrase, PhraseKey } from "../common/phrases";
import FieldsRow from "../components/fields/FieldsRow";
import DetailPage from "../components/pages/DetailPage";
import { useProject } from "../data/useProject";
import { UserContext } from "../data/userContext";
import { DocumentSnapshot, FieldValue } from "@atgof-firebase/firebase";
import { LanguageContext } from "../common/language";
import { RootStackParamList } from "../types";
import { useDocument } from "../data/useDocument";
import { useDocumentReference } from "../data/firestore";

export function propTitle(
  ph: (k: PhraseKey) => Phrase, prop: DocumentSnapshot | undefined, isNew: boolean = false
) {
  return prop?.get('displayName') || (isNew ? ph('new-prop') : ph('unnamed-prop'));
}

export default function PropScreen({ route }: NativeStackScreenProps<RootStackParamList, 'prop'>) {
  const { ph } = React.useContext(LanguageContext);
  const { path, subjectName } = route.params;
  const { goBack } = useNavigation();
  const { user } = React.useContext(UserContext);
  const prop = useDocument(useDocumentReference(path));
  function deleteProp() {
    if (!prop) return;
    prop.ref.update({ deleted: FieldValue.serverTimestamp(), lastModifiedBy: user.ref })
      .then(() => goBack());
  }
  return (
    <DetailPage
      title={
        `${subjectName} - ${propTitle(ph, prop)}`
      }
      deleteItem={deleteProp}
    >
      {prop &&
        <View>
          <FieldsRow
            doc={prop}
            fields={[
              {
                key: 'displayName', labelKey: 'prop-name',
                kind: 'text', isTitle: true
              }
            ]}
          />
        </View>
      }
    </DetailPage>
  );
}
