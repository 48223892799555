import { DocumentReference, DocumentSnapshot } from "@atgof-firebase/types";
import { OnNext, Unsubscribe, onSnapshots } from "./onSnapshot";
import { FDXScriptText } from "./formats/fdx";

export const strokeWidth = 1;
export const eraserWidth = 10;

export type ScriptTextElement = FDXScriptText
export type ScriptElement = {
  kind?: string | undefined;
  paragraph?: ScriptTextElement[];
  dualCharacter?: number;
  dialogueLineNumber?: number;
}

export type SceneScript = {
  sceneRef: DocumentReference;
  script?: DocumentSnapshot;
  dialogueLineNumberOffset: number,
  yOffset: number | undefined
}

function latestScriptQuery(sceneRef: DocumentReference, limit?: number) {
  const q = sceneRef.collection('scripts').orderBy('lastImportedAt', 'desc');
  return limit === undefined ? q : q.limit(limit);
}

export function onLatestScripts(
  sceneRefs: DocumentReference[],
  onNext: OnNext<SceneScript[] | undefined>,
  prioritiseAnnotatedScripts: boolean = false
) {
  return onSnapshots(
    sceneRefs,
    sceneRef => (
      onNext =>
        latestScriptQuery(sceneRef, prioritiseAnnotatedScripts ? undefined : 1).onSnapshot(
          ({ empty, docs }) =>
            onNext({
              sceneRef, ...(empty ? {} : {
                script: (
                  (prioritiseAnnotatedScripts ?
                    docs.find(doc => doc.get('annotations')) : undefined) ??
                  docs[0]
                )
              })
            })
        )
    ),
    (next: Omit<SceneScript, 'dialogueLineNumberOffset' | 'yOffset'>[] | undefined) => {
      if (!next) {
        onNext(undefined);
        return;
      }
      let next_: SceneScript[] = [];
      let dialogueLineNumberOffset = 0;
      let yOffset: number | undefined = -40;
      for (const sceneScript of next) {
        next_.push({
          ...sceneScript,
          dialogueLineNumberOffset,
          yOffset
        });
        const script = sceneScript.script;
        dialogueLineNumberOffset += script?.get('dialogueLines') ?? 0;
        const height: number | undefined = script?.get('height');
        yOffset = (yOffset == null || height == null) ?
          undefined : (yOffset + height - 40);
      }
      onNext(next_);
    }
  )
}

export function onScriptElements(
  scriptPath: string | undefined,
  getScript: (storagePath: string) => Promise<string>,
  onNext: OnNext<ScriptElement[]>
): Unsubscribe | void {
  if (!scriptPath) {
    onNext([]);
    return;
  }
  let active = true;
  getScript(scriptPath).then(content => {
    if (!active) return;
    onNext(JSON.parse(content)); // TODO Catch errors
  }); // TODO .catch()
  return () => {
    active = false;
  };
}

export type AnnotationPath = {
  svg: string;
  width?: number;
  color?: string;
  erasing?: boolean;
  version: number;
  os: string
};

export type AnnotationLayerProps = {
  key: AnnotationLayerKey;
  label: string;
  colour: string;
  paths: AnnotationPath[]
};

export type AnnotationLayerKey = 'takeAnnotations' | 'annotations';

export function getAnnotationLayer(
  script: DocumentSnapshot,
  key: AnnotationLayerKey,
  colour: string
): AnnotationLayerProps {
  const v = script?.get(key);
  const paths = (v ? JSON.parse(JSON.stringify(v)) : undefined) ?? [];
  return { key, label: '', colour, paths };
}
