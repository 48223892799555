import React from 'react';
import { OnSnapshot, Unsubscribe } from '../common/onSnapshot';

export function transformSnapshot<A, B>(
  onSnapshot: OnSnapshot<A, Unsubscribe | void> | undefined,
  transform: (value: A) => B
): OnSnapshot<B, Unsubscribe | void> | undefined {
  if (onSnapshot) {
    return onNext => onSnapshot(value => onNext(transform(value)));
  }
}

export function useSnapshot<T>(
  onSnapshot: OnSnapshot<T, Unsubscribe | void> | undefined,
  deps: React.DependencyList
): T | undefined {
  const onSnapshot_ =
    React.useCallback<OnSnapshot<T, Unsubscribe | void>>(
      onSnapshot ?? (_ => { }),
      deps
    );
  const [value, setValue] = React.useState<T>();
  React.useEffect(
    () => {
      setValue(undefined);
      return onSnapshot_(setValue);
    },
    [onSnapshot_]
  );
  return value;
}
