import { z } from 'zod';
import { reference } from "../reference";
import { displayableSpec } from '../displayable';

export const characterReference = reference;
export const characterId = z.string();

export const characterSpec = displayableSpec;

export type Character = z.infer<typeof characterSpec.validator>

