import { DocumentReference, DocumentSnapshot } from "@atgof-firebase/types";
import { SceneScript } from "./script";
import { OnNext, flattening, onSnapshots } from "./onSnapshot";
import { ScriptLoc, Take, takeSchema } from "./model/project/sheet/take";
import { onSheets, onTakes, sheetColour } from "./sheet";

export const tramlineDisplayProps = {
  width: 60,
  minLineLength: 22,
  lineWidth: 22,
  endRotation: -22.5
};

export type TramlineProps = {
  takeRef: DocumentReference;
  slate: string | undefined;
  startY: number | undefined;
  endY: number | undefined;
  description?: string | undefined;
  colour?: string | undefined;
  sheet?: DocumentSnapshot | undefined;
} & Take

function yPosFor(
  loc: ScriptLoc | undefined,
  scripts: SceneScript[]
): number | undefined {
  if (loc === undefined || typeof loc === 'number' || loc.yPos === undefined) return;
  const sceneScript = scripts.find(({ sceneRef }) => sceneRef.id == loc.sceneId);
  const offset = sceneScript?.yOffset;
  if (offset === undefined) return;
  return 40 + offset + loc.yPos;
}

function multiCameraShotDescription(
  cameras: string[] | undefined,
  descriptions: Record<string, string> | undefined
) {
  if (!(cameras?.length && descriptions)) return;
  let parts = [];
  for (const camera of cameras) {
    const desc = descriptions[camera];
    if (!desc) continue;
    parts.push(camera + ': ' + desc);
  }
  return parts.join(' | ');
}

export function tramlineForTake(
  take: DocumentSnapshot,
  sheet: DocumentSnapshot | undefined,
  scripts: SceneScript[]
): TramlineProps {
  const takeData = (takeSchema.parse(take.data()));
  return {
    takeRef: take.ref,
    slate: sheet?.get('pa:slate'),
    description:
      multiCameraShotDescription(sheet?.get('pa:cameras'), sheet?.get('pa:descriptions')) ??
      sheet?.get('pa:description'),
    colour: sheetColour(sheet),
    sheet,
    ...takeData,
    startY: yPosFor(takeData.startScriptLoc, scripts),
    endY: yPosFor(takeData.endScriptLoc, scripts)
  };
}

export function onAllTramlines(
  project: DocumentReference | undefined,
  scripts: SceneScript[] | undefined,
  onNext: OnNext<TramlineProps[] | undefined>
) {
  return onSheets(
    project,
    scripts?.map(script => script.sceneRef),
    ['pa'],
    undefined,
    sheets => onSnapshots(
      sheets,
      sheet =>
        onNext => onTakes(
          sheet.ref, true,
          takes => onNext(takes?.map(take => tramlineForTake(take, sheet, scripts!!)))
        ),
      flattening<TramlineProps>(onNext)
    )
  );
}

