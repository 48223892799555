import { Dialog, Portal } from "react-native-paper";
import { FirestoreError } from "@atgof-firebase/firebase";
import { LanguageContext } from "../common/language";
import { AlertBox } from "./AlertBox";
import ErrorDetail from "./ErrorDetail";
import LogOutButton from "./LogOutButton";
import SessionPreferences from "./SessionPreferences";
import React from "react";

export default function ErrorScreen({ error }: { error: FirestoreError }) {
  const { ph } = React.useContext(LanguageContext);
  return (
    <Portal>
      <Dialog visible={true} onDismiss={() => { }}>
        <Dialog.Title>{ph('app-name') as string}</Dialog.Title>
        <Dialog.Content style={{ gap: 8 }}>
          <SessionPreferences />
          <AlertBox
            status="error"
            title={ph('unable-to-load') as string}
            message={ph('unable-to-load-with-reason') as string}
          >
            <ErrorDetail>{error.message}</ErrorDetail>
          </AlertBox>
        </Dialog.Content>
        <Dialog.Actions><LogOutButton /></Dialog.Actions>
      </Dialog>
    </Portal>
  );
}
