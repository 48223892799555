import { Phrase, PhraseKey } from "../common/phrases";
import { DocumentReference, DocumentSnapshot, Timestamp } from "@atgof-firebase/firebase";
import { SheetCategory } from "../common/sheet";
import { SheetSubjectKind } from "../common/sheet-subject";

export function sheetSubjectCollection(project: DocumentReference, kind: SheetSubjectKind) {
  return project.collection(kind + 's');
}

export type SheetDisplayData = {
  path: string;
  category: SheetCategory;
  scene: DocumentReference | undefined;
  episode: DocumentReference | undefined;
  character: string | undefined;
  set: string | undefined;
  look: PhraseKey | undefined;
  paSlate: string | undefined;
  createdAt: Timestamp | undefined;
}

export function dataForSheet(sheet: DocumentSnapshot): SheetDisplayData {
  return {
    path: sheet.ref.path,
    category: sheet.get('category'),
    scene: sheet.get('scene'),
    episode: sheet.get('episode'),
    character: sheet.get('character'),
    set: sheet.get('set'),
    look: sheet.get('look'),
    paSlate: sheet.get('pa:slate'),
    createdAt: sheet.get('createdAt'),
  };
}

type SortKey = 'createdAt' | 'scene' | 'episode'
export type SortByOption = {
  key: SortKey; compareFn: ((a: SheetDisplayData, b: SheetDisplayData) => number) | undefined; label: Phrase
}

export function sheetMatches(sheet: DocumentSnapshot, category: SheetCategory, character: string | undefined) {
  return sheet.get('category') === category && (!character || sheet.get('character') === character);
}
