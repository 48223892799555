import React, { ReactNode } from "react";
import { ActivityIndicator, View } from "react-native";

export function ProgressEntry({ children, isActive }: { children: ReactNode; isActive: boolean; }) {
  if (!children) return null;
  return (
    <View style={{ flexDirection: "row", gap: 8 }}>
      {isActive && <ActivityIndicator />}
      {children}
    </View>
  );
}
