import { FieldValue } from "@atgof-firebase/firebase";
import { DocumentReference } from "@atgof-firebase/types";

export function objectCreationAttributes(userRef: DocumentReference) {
  return {
    deleted: false,
    createdBy: userRef,
    createdAt: FieldValue.serverTimestamp(),
    lastModifiedBy: userRef,
    lastModifiedAt: FieldValue.serverTimestamp()
  };
}
