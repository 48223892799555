import { View } from "react-native";
import { FIELD_SPECS } from "../common/sheet";
import { useSheets } from "../data/useSheets";
import { DocumentReference } from "@atgof-firebase/firebase";
import FieldsRow from "./fields/FieldsRow";

export default function PASceneInfo({ scene }: { scene: DocumentReference | undefined }) {
  const artsSheets = useSheets(scene, ['arts']);
  if (!scene) return null;
  return (
    <View>
      {artsSheets?.map(sheet =>
        <FieldsRow
          key={sheet.id} category="arts" readonly
          doc={sheet} fields={FIELD_SPECS.arts.arts}
        />
      )}
    </View>
  );
}
