import { z } from 'zod';
import { objectAttrs } from '../../objectAttrs';
import { setId } from '../set';
import { characterId } from '../character';
import { timestamp } from '../../timestamp';

export const episodeSchema = objectAttrs.extend({
  displayName: z.string(),
  hasScripts: z.boolean().default(false),
  sceneCount: z.number().default(0),
  sets: z.array(setId).default([]),
  cast: z.array(characterId).default([]),
  director: z.string().optional(), // TODO Abstract this as a director ID
  txDate: timestamp.optional()
});

export type Episode = z.infer<typeof episodeSchema>
