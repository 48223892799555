import { DocumentReference, DocumentSnapshot, UpdateData } from "@atgof-firebase/firebase";
import FieldsRow from "./fields/FieldsRow";
import { Field } from "../common/helpers/fields";
import { View } from "react-native";
import React from "react";
import { updateLatestSlate } from "../data/team";
import { Checkbox, IconButton } from "react-native-paper";
import * as PA from "../common/sheet/pa";
import FixtureLabel from "../common/FixtureLabel";
import { LanguageContext } from "../common/language";
import { useDocument } from "../data/useDocument";

function navigateToScript(navigation: any, sheet: DocumentSnapshot, take: DocumentReference) {
  navigation.navigate(
    'script', { sheetPath: sheet.ref.path, currentTakePath: take.path }
  );
}

function getRows(
  sheet: DocumentSnapshot | undefined,
  teamRef?: DocumentReference | undefined, includeTakes: boolean = true
): Field[][] {
  return PA.getRows(
    includeTakes,
    sheet?.get("pa:cameras"),
    (v, camera) => teamRef?.update({
      [camera ? "pa:cameraCardNumbers." + camera : "pa:cameraCardNumber"]: v
    }),
    v => teamRef?.update({ "pa:soundCardNumber": v }),
    (newValue, oldValue, doc) => updateLatestSlate(teamRef, newValue, oldValue, doc?.id),
    navigateToScript,
    (navigation: any, doc: DocumentSnapshot, v: any) =>
      <IconButton style={{ alignSelf: "flex-end" }} icon="script-text"
        onPress={() => navigateToScript(navigation, doc, v.ref)} />
  );
}
export default function PASheet(
  { sheet, scene }: { sheet: DocumentSnapshot; scene: DocumentSnapshot }
) {
  const { ph } = React.useContext(LanguageContext);
  const teamRef: DocumentReference | undefined = scene.get('team');
  const team = useDocument(teamRef);
  const allCameras: string[] | undefined = team?.get('cameras');
  const rows = React.useMemo(() => getRows(sheet, teamRef), [sheet, teamRef?.path]);
  const cameras = sheet.get('pa:cameras');
  return (
    <View>
      {allCameras?.length ?
        <View style={{ margin: 6, flexDirection: "row" }}>
          <FixtureLabel text={ph('cameras') as string} />
          {allCameras.map(camera =>
            <Checkbox.Item
              key={camera}
              label={camera}
              status={cameras?.includes(camera) ? 'checked' : 'unchecked'}
              onPress={() => {
                const removing = cameras?.includes(camera);
                const newSel = removing ?
                  allCameras.filter(cam => cameras?.includes(cam) && cam !== camera) :
                  allCameras.filter(cam => cameras?.includes(cam) || cam === camera);
                let extraData: UpdateData = {};
                if (!removing) {
                  for (const generalK of ['pa:cameraCardNumber', 'pa:description']) {
                    const specificK = generalK + 's.' + camera;
                    console.log("SP", specificK, sheet.get(specificK));
                    if (sheet.get(specificK)) continue;
                    const general: string | undefined = sheet.get(generalK);
                    if (general) extraData[specificK] = general;
                  }
                }
                sheet.ref.update({ 'pa:cameras': newSel, ...extraData });
              }
              }
            />)}
        </View> : null}
      {rows.map((fields, i) =>
        <FieldsRow key={i} doc={sheet} category="pa" fields={fields} />)}
    </View>
  );
}
