import React, { ReactElement } from "react";
import { View } from "react-native";
import { Button } from "react-native-paper";
import { LanguageContext } from "../common/language";

export function Revealer({ title, children }: { title: ReactElement; children: ReactElement }) {
  const { ph } = React.useContext(LanguageContext);
  const [isExpanded, setIsExpanded] = React.useState(false);
  return (
    <View style={{ gap: 4 }}>
      <View style={{ flexDirection: "row", gap: 4, alignItems: "center" }}>
        {title}
        <Button onPress={() => setIsExpanded(!isExpanded)}>
          {ph(isExpanded ? 'hide' : 'show') as string}
        </Button>
      </View>
      {isExpanded && <View style={{ marginLeft: 8 }}>{children}</View>}
    </View>
  );
}
