import React from "react";
import { Button } from "react-native-paper";
import { LanguageContext } from "../common/language";
import { DocumentSnapshot } from "@atgof-firebase/firebase";
import { sceneLabel } from "../common/scene";

export type SceneNavigationButtonProps = {
  scene: DocumentSnapshot | undefined;
  moveToScene: ((sceneId: string) => void) | undefined
  isForward?: boolean;
};

export default function SceneNavigationButton(
  { scene, moveToScene, isForward }: SceneNavigationButtonProps) {
  const { ph } = React.useContext(LanguageContext);
  if (!(scene && moveToScene))
    return null;
  return (
    <Button style={{ margin: 16 }} onPress={() => moveToScene(scene.id)}>
      {(isForward ? '↓' : '↑') + ' ' +
        ph(isForward ? 'on-to' : 'back-to') + ' ' +
        sceneLabel(scene.ref.path)}
    </Button>
  );
}
