import { useNavigation } from "@react-navigation/native";
import { IconButton } from "react-native-paper";
import { SubjectSpec } from "../common/subject";
import { HasScripts } from "../common/has-scripts";

export function ScriptButton(
  { parent, wholeEpisode, color, subjectKind, subjectName }: {
    parent: HasScripts | undefined;
    wholeEpisode?: boolean;
    color?: string
  } & Partial<SubjectSpec>) {
  const { navigate } = useNavigation();
  if (!parent?.hasScripts) return null;
  return (
    <IconButton icon="script-text"
      iconColor={color}
      onPress={() => navigate('script', {
        [wholeEpisode ? 'episodePath' : 'scenePath']: parent.path,
        subjectKind, subjectName
      })} />
  );
}
