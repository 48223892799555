import { v4 as uuidv4 } from "uuid";
import { MaterialIcons } from "@expo/vector-icons";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { DocumentData, DocumentReference, DocumentSnapshot, QuerySnapshot } from "@atgof-firebase/firebase";
import React from "react";
import { Platform, TextStyle, View } from "react-native";
import { LanguageContext } from "../common/language";
import { listOfItems, PhraseFunction } from "../common/phrases";
import NavPage from "../components/pages/NavPage";
import { useProject } from "../data/useProject";
import { UserContext } from "../data/userContext";
import { BackendContext, uploadFile } from "../data/backend";
import { RootStackParamList } from "../types";
import { formatDate } from "../common/util";
import { useNavigation } from "@react-navigation/native";
import { FILE_TYPES, fileType } from "../data/imports";
import { Button, List, Text, useTheme } from "react-native-paper";
import { useSnapshot } from "../data/useSnapshot";
import { notDeleted } from "../common/general";

const ACCEPT_TYPES = Object.keys(FILE_TYPES).map(ext => '.' + ext).join(', ');

const width = 320;
const height = 200;
const fileInputElementId = "atgof-file-input";

function TextPrompt({ children, style }: { children: React.ReactNode; style?: TextStyle }) {
  return (
    <Text
      variant="titleMedium"
      style={{ alignSelf: "center", textAlign: "center", ...(style ?? {}) }}
    >
      {children}
    </Text>
  );
}

type ImportEntryProps = { identifier: string, file?: File, doc?: DocumentSnapshot<DocumentData> | undefined }

function ImportEntry(
  { identifier, file, doc, retryUpload }:
    ImportEntryProps & {
      retryUpload: (docRef: DocumentReference | undefined) => void
    }
) {
  const { ph } = React.useContext(LanguageContext);
  const { uploads } = React.useContext(BackendContext);
  const { navigate } = useNavigation();
  const error = doc ? uploads.find(({ docRefPath }) => docRefPath === doc.ref.path)?.error : undefined;
  const metadata = doc?.get('originalFileMetadata');
  const lastModified = file?.lastModified || metadata?.lastModified;
  const uploading = !doc?.get('uploadCompletedAt');
  const importing = doc?.get('importRequestAt') !== undefined;
  const inProgress = !error && (uploading || importing);
  const onPress = inProgress ? undefined : () => error ?
    retryUpload(doc?.ref) :
    navigate('fileImport', { id: identifier });
  return (
    <List.Item title={file?.name || metadata?.name}
      description={error ? error.name /* TODO And error.message */ : formatDate(new Date(lastModified))}
      onPress={onPress}
      right={
        () =>
          <Button
            onPress={onPress}
            loading={inProgress}
          >
            {ph(error ? 'retry' :
              (uploading ? 'uploading' : (importing ? 'importing' : 'import'))) as string}
          </Button>}
    />
  );
}

type FileEntry = { identifier: string, file: File, needsUploading: boolean }
type FilesAction =
  { append: Iterable<File | DataTransferItem> } | { identifier: string; needsUploading: boolean }

function fileEntriesReducer(entries: FileEntry[], action: FilesAction) {
  if ('append' in action) {
    let entries_ = [...entries];
    function appendFile(file: File) {
      const type = fileType(file.name);
      if (!(type && type in FILE_TYPES)) return;
      const existingIndex = entries_.findIndex(({ file: { name, size, lastModified } }) =>
        file && file.name === name && file.size == size && file.lastModified == lastModified);
      if (existingIndex != -1) entries_.splice(existingIndex, 1);
      entries_.push({ identifier: uuidv4(), file: file, needsUploading: true });
    }
    for (const item of action.append) {
      if (!item) continue;
      if ('getAsFile' in item) {
        if (item.kind === 'file') {
          const file = item.getAsFile();
          if (file) appendFile(file);
        }
      }
      else appendFile(item);
    }
    return entries_;
  }
  if ('identifier' in action) {
    const i = entries.findIndex(({ identifier }) => identifier === action.identifier);
    if (i != -1) {
      entries[i].needsUploading = action.needsUploading;
      return [...entries];
    }
  }
  return entries;
}

export default function FileImportsScreen({ }: NativeStackScreenProps<RootStackParamList, 'fileImports'>) {
  const { ph } = React.useContext(LanguageContext);
  const { colors } = useTheme();
  const project = useProject();
  const { user } = React.useContext(UserContext);
  const backend = React.useContext(BackendContext);
  const imports = useSnapshot<DocumentSnapshot[] | undefined>(
    project ?
      (
        onNext => notDeleted(project.collection('imports').where('importComplete', '==', false))
          .orderBy('createdAt', 'asc')
          .onSnapshot(snapshot => onNext(snapshot.docs))
      ) : undefined,
    [project?.path]
  );
  const [fileEntries, dispatchFileEntries] = React.useReducer(fileEntriesReducer, []);
  React.useEffect(() => {
    const entriesToUpload = fileEntries.filter(({ needsUploading }) => needsUploading);
    if (entriesToUpload.length == 0) return;
    for (const { identifier, file } of entriesToUpload) {
      dispatchFileEntries({ identifier: identifier, needsUploading: false });
      uploadFile(
        backend, project!, 'admin', user, identifier, project!.collection('imports'), file,
        {
          deleted: false,
          importComplete: false,
          originalFileMetadata: {
            name: file.name, size: file.size, lastModified: file.lastModified
          }
        }
      );
    }
  }, [fileEntries]);
  function retryUpload(docRef: DocumentReference | undefined) {
    if (docRef) {
      backend.uploader.cancelUpload(docRef);
      dispatchFileEntries({ identifier: docRef.id, needsUploading: true })
    }
  }
  const entries: ImportEntryProps[] = [
    ...(
      imports?.filter(({ id }) => fileEntries.findIndex(({ identifier }) => identifier === id) == -1)
        .map(doc => ({ identifier: doc.id, doc: doc })) ||
      []
    ),
    ...fileEntries.map(entry => ({ ...entry, doc: imports?.find(({ id }) => id === entry.identifier) })),
  ];
  function dragOverHandler(event: React.DragEvent) { event.preventDefault(); }
  function dropHandler(event: React.DragEvent) {
    event.preventDefault();
    dispatchFileEntries({
      append: (event.dataTransfer.items || event.dataTransfer.files) as any
    });
  }
  function fileSelectionHandler(event: React.ChangeEvent<HTMLInputElement>) {
    const fileList = event.target.files;
    if (fileList) dispatchFileEntries({ append: fileList as any });
  }
  return (
    <NavPage>
      <View style={{ marginTop: 16 }}>
        {
          Platform.OS === "web" ?
            <View>
              <View style={{ marginBottom: 16, gap: 8 }}>
                {entries.map(entry =>
                  <ImportEntry key={entry.identifier} retryUpload={retryUpload}
                    {...entry} />)}
              </View>
              <div onDragOver={dragOverHandler} onDrop={dropHandler} style={{ width, height }}>
                <View style={{
                  borderWidth: 4, borderColor: colors.outline, borderStyle: "dashed",
                  borderRadius: 6, gap: 8, paddingTop: 8, paddingBottom: 8, width, height
                }}
                >
                  <TextPrompt style={{ maxWidth: width }}>
                    {(ph('drag-prompt') as PhraseFunction)({
                      fileTypeList: listOfItems(
                        ph('or') as string,
                        Object.keys(FILE_TYPES).map(ext =>
                          FILE_TYPES[ext].label + ' (.' + ext + ')'
                        )
                      )
                    }) as string}
                  </TextPrompt>
                  <MaterialIcons name="arrow-circle-down" size={48} color={colors.outline}
                    style={{ flex: 1, alignSelf: "center" }}
                  />
                  <TextPrompt>{ph('or') as string}</TextPrompt>
                  <View style={{ alignSelf: "center" }}>
                    <label htmlFor={fileInputElementId}>
                      <Button>{ph('choose-files') as string}</Button>
                    </label>
                  </View>
                </View>
                <input id={fileInputElementId} style={{ opacity: 0 }}
                  type="file" multiple accept={ACCEPT_TYPES}
                  onChange={fileSelectionHandler}
                />
              </div>
            </View>
            :
            <Text variant="titleMedium" style={{ alignSelf: "center" }}>
              {ph('uploads-not-possible') as string}
            </Text>
        }
      </View>
    </NavPage>
  );
}
