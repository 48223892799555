import { DocumentReference, DocumentSnapshot, DocumentData } from "@atgof-firebase/firebase";
import React from "react";
import { useProject } from "../../data/useProject";
import Select from "../Select";

type ReferenceSelectProps = {
  doc?: DocumentSnapshot<DocumentData>; // TODO Check references and remove this
  collection: string;
  item: DocumentSnapshot<DocumentData> | undefined;
  onItemChange: (item: DocumentReference<DocumentData> | undefined) => void
}

type Items = { [id: string]: DocumentSnapshot<DocumentData> } | undefined

function itemsReducer(
  state: Items,
  { item, itemRefs, items }:
    {
      item?: DocumentSnapshot<DocumentData>,
      itemRefs?: DocumentReference<DocumentData>[],
      items?: DocumentSnapshot<DocumentData>[]
    }): Items {
  if (item) return { ...state, [item.id]: item };
  if (items) return items.reduce((s, item) => ({ ...s, [item.id]: item }), {});
  if (itemRefs) return itemRefs.reduce((s, r) => ({ ...s, [r.id]: null }), {});
  return;
}

export default function ReferenceField({ collection, item, onItemChange }: ReferenceSelectProps) {
  const project = useProject();
  const [items, dispatchItems] = React.useReducer(itemsReducer, undefined as Items);
  React.useEffect(() => {
    dispatchItems({});
    if (!project) return;
    return project.collection(collection).onSnapshot(({ docs }) => dispatchItems({ items: docs }));
  }, [collection, project]);
  const [sortedItems, setSortedItems] = React.useState<DocumentSnapshot[]>();
  React.useEffect(() => {
    if (items === undefined || Object.values(items).findIndex(v => v === null) != -1)
      setSortedItems(undefined);
    else
      setSortedItems(
        Object.keys(items).map(id => items[id]).
          sort((a, b) => a.get('displayName').localeCompare(b.get('displayName')))
      );
  }, [items]);

  React.useEffect(() => {
    if (!item && sortedItems?.length === 1) onItemChange(sortedItems[0].ref);
  }, [item, sortedItems]);

  return (
    <Select
      entries={sortedItems} entryKey={item => item.id}
      entryLabel={item => item.get('displayName')}
      selectedEntry={item} onEntryChange={item => onItemChange(item && item.ref)}
    />
  );
}
