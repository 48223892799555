import { makeRedirectUri, ResponseType, useAuthRequest, useAutoDiscovery } from 'expo-auth-session';
import React from 'react';
import { SigninProps } from '../signin';

export default function MicrosoftSignin({ signinState, onToken, button, functionsURI }: SigninProps) {
  const [signinStatus, setSigninStatus] = signinState;
  const discovery = useAutoDiscovery('https://login.microsoftonline.com/common/v2.0');
  const [_, response, promptAsync] = useAuthRequest(
    {
      clientId: '7aa1f791-e401-4314-991e-ffcc165928c8',
      scopes: ['openid', 'profile', 'email'],
      redirectUri: makeRedirectUri({
        scheme: 'atgof'
      }),
      responseType: ResponseType.IdToken,
      usePKCE: false,
      extraParams: { 'nonce': 'default' }
    },
    discovery
  );
  React.useEffect(() => {
    if (signinStatus.activeForProvider !== 'microsoft') return;
    if (response?.type === 'success' && response.params.id_token) {
      const token = response.params.id_token;
      const xhr = new XMLHttpRequest();
      xhr.onload = () => {
        if (xhr.status == 200) onToken({ provider: 'microsoft', value: xhr.response });
        else setSigninStatus(
          { error: { type: 'error', error: `Unable to retrieve token (status ${xhr.status})` } }
        );
      }
      xhr.onerror = () => setSigninStatus({ error: xhr.response });
      xhr.responseType = "text";
      xhr.open("POST", functionsURI + '/tokenForAzureSignin', true);
      const form = new FormData();
      form.append('token', token);
      xhr.send(form);
    }
    else if (response) setSigninStatus({ error: response });
  }, [signinStatus.activeForProvider, response]);

  function signIn() {
    setSigninStatus({ activeForProvider: 'microsoft' });
    promptAsync();
  }

  return button(signIn);
}
