import React from "react";
import { View } from "react-native";
import { DocumentSnapshot } from "@atgof-firebase/firebase";
import PhotoStrip from "./PhotoStrip";
import SceneInfo from "./SceneInfo";
import { SheetSections } from "./SheetSections";
import { LanguageContext } from "../common/language";
import { SheetCategory } from "../common/sheet";
import { useNavigation } from "@react-navigation/native";
import NewSheetButton from "./NewSheetButton";
import { Button } from "react-native-paper";
import { SubjectSpec } from "../common/subject";
import { referencePathForScene } from "../data/backend/downloader";
import { Scene } from "../common/scene";

function SheetView({ scene, sheet, subject, episodePath }: {
  scene?: Scene;
  sheet: DocumentSnapshot;
  subject?: SubjectSpec;
  episodePath?: string;
}) {
  const { ph } = React.useContext(LanguageContext);
  const { navigate } = useNavigation();
  const category = sheet.get('category');
  return (
    <View>
      <PhotoStrip
        sheetRef={sheet.ref} sheetCategory={category}
        referencePath={referencePathForScene(scene?.ref, sheet.id)}
        style={{ marginTop: 2, marginBottom: 2 }} readonly
      />
      <SheetSections doc={sheet} category={category} readonly />
      {subject &&
        <View style={{ flexDirection: "row" }}>
          <Button onPress={() => {
            if (scene)
              navigate('sheet', { path: sheet.ref.path });
            else
              navigate('look', { path: sheet.ref.path, episodePath, ...subject });
          }}>
            {ph('edit') as string}
          </Button>
        </View>
      }
    </View>
  );
}

export function BreakdownSheets({
  scene, sheets, subjectKind, subjectName, episodePath, sheetCategories
}: {
  scene?: Scene;
  sheets: DocumentSnapshot[] | undefined;
  episodePath?: string;
  sheetCategories: SheetCategory[];
} & SubjectSpec) {
  const [categories, setCategories] = React.useState<SheetCategory[]>([]);
  React.useEffect(
    () => setCategories(sheets?.map(sheet => sheet.get('category')) || []),
    [sheets]);
  const [availableCategories, setAvailableCategories] = React.useState<SheetCategory[]>([]);
  React.useEffect(
    () => {
      if (!scene)
        setAvailableCategories([]);
      else
        setAvailableCategories(sheetCategories.filter(category => !categories.includes(category)));
    },
    [scene, sheets]
  );
  if (!(scene || (sheets && sheets.length > 0)))
    return null;
  const subject = { subjectKind, subjectName };
  return (
    <View style={{ borderBottomColor: '#dddddd', borderBottomWidth: 1, paddingTop: 16, paddingBottom: 16 }}>
      {scene && <SceneInfo scene={scene} />}
      {sheets?.map(sheet =>
        <SheetView
          key={sheet.id}
          scene={scene}
          sheet={sheet}
          subject={subject}
          episodePath={episodePath}
        />)}
      {scene && availableCategories.length > 0 &&
        <View style={{ flexDirection: "row" }}>
          <NewSheetButton sceneRef={scene.ref} subject={subject} restrictToCategories={availableCategories} />
        </View>}
    </View>
  );
}
