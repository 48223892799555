import { z } from 'zod';
import { TokenisedCandidate } from '../search';

type SpecOptions<T> = {
  searchTokeniser?: (item: T) => TokenisedCandidate
}

export class Spec<T> {
  constructor(
    readonly validator: z.ZodSchema<T>,
    readonly options: SpecOptions<T> = {}
  ) { }
}
