import React from "react";
import { TextStyle } from "react-native";
import { TextInput, textForEvent } from "./fields/TextInput";
import { Text } from "react-native-paper";

export function Editable({ text, readonly = false, onChange, style = {} }: {
  text: string;
  readonly?: boolean;
  onChange: (v: string) => void;
  style?: TextStyle
}) {
  const timeout = React.useRef<ReturnType<typeof setTimeout>>();
  const [value, setValue] = React.useState(text ?? "");
  React.useEffect(() => {
    setValue(text ?? "");
  }, [text]);
  if (readonly) return <Text style={style}>{text}</Text>;
  return (
    <TextInput
      style={{ ...(style as any), backgroundColor: "none", background: "none", borderWidth: 0, resize: "none" }}
      value={value}
      onChange={event => {
        if (timeout.current !== undefined) {
          clearTimeout(timeout.current);
          timeout.current = undefined;
        }
        const v = textForEvent(event);
        setValue(v);
        timeout.current = setTimeout(() => onChange(v), 1000);
      }}
      onBlur={() => {
        if (value !== text) {
          if (timeout.current) {
            clearTimeout(timeout.current);
            timeout.current = undefined;
          }
          onChange(value);
        }
      }}
    />
  );
}
