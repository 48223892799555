import { Avatar, Card } from "react-native-paper";
import { UserContext } from "../data/userContext";
import { initials } from "../common/util";
import LogOutButton from "./LogOutButton";
import React from "react";

export default function UserPreferences() {
  const { user } = React.useContext(UserContext);
  if (!user) return null;
  return (
    <Card style={{ margin: 2 }}>
      <Card.Title
        title={user.get('displayName')}
        subtitle={user.get('email')}
        left={props => <Avatar.Text {...props} label={initials(user.get('displayName')) ?? ''} />}
      />
      <Card.Actions>
        <LogOutButton />
      </Card.Actions>
    </Card>
  );
}
