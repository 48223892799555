import { CollectionReference, QueryDocumentSnapshot, QuerySnapshot } from "@atgof-firebase/types";
import { transformSnapshot, useSnapshot } from "./useSnapshot";
import _ from "lodash";

export function useDocuments(ref: CollectionReference | undefined) {
  return useSnapshot(
    transformSnapshot<QuerySnapshot, QueryDocumentSnapshot[]>(
      ref ? _.bind(ref.onSnapshot, ref) : undefined,
      ({ docs }) => docs
    ),
    [ref?.path]
  );
}
