import { storageBaseUri } from "../config/firebase";

export function isStorageUrl(url: string) {
  return url.startsWith(storageBaseUri);
}

export function getStoragePath(url: string) {
  if (!isStorageUrl(url)) return;
  const i = url.lastIndexOf('/');
  if (i == -1) return;
  return decodeURIComponent(url.substring(i + 1));
}
