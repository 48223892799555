export default class Handlers<T> {
  protected readonly label: string;
  protected handlers = new Map<number, (value: T) => void>();

  public constructor(label: string) {
    this.label = label;
  }

  public add(handler: (value: T) => void, current: T) {
    const ks = [...this.handlers.keys()];
    const handlerId = ks.length ? Math.max(...ks) + 1 : 0;
    this.handlers.set(handlerId, handler);
    try { handler(current); }
    catch (err) { console.error("Error in `${this.label}` handler", err); }
    return () => { this.handlers.delete(handlerId); };
  }

  public handle(value: T) {
    for (const handler of this.handlers.values()) {
      try { handler(value); }
      catch (err) { console.error(`Error in ${this.label} handler`, err); }
    }
  }
}
