import { Downloader } from "./downloader";
import { Uploader } from "./uploader";

export abstract class StorageManager {
  public readonly uploader: Uploader;
  public readonly downloader: Downloader;

  protected constructor(uploader: Uploader, downloader: Downloader) {
    this.uploader = uploader;
    this.downloader = downloader;
  }

  public abstract onUpdateAvailable(handler: (updateAvailable: boolean) => void)
    : () => void;
  public abstract checkForUpdates(): Promise<void>;
}
