import { QueryDocumentSnapshot } from "@atgof-firebase/firebase";
import React from "react";
import { useNavigation } from "@react-navigation/native";
import ListItem from "../ListItem";
import { sheetSubjectCollection } from "../../data/sheet";
import { useProject } from "../../data/useProject";
import ListPage from "./ListPage";
import { CollectionListSpec } from "../CollectionList";
import { SheetSubjectKind } from "../../common/sheet-subject";

type SheetSubject = { id: string; displayName: string; isPinned: boolean };

export default function SheetSubjectListPage({ kind, pinnedSubjects }: {
  kind: SheetSubjectKind;
  pinnedSubjects?: string[]
}) {
  const navigation = useNavigation();
  const project = useProject();
  function renderSubject({ item }: { item: SheetSubject }) {
    return (
      <ListItem label={item.displayName}
        onPress={() =>
          navigation.navigate('sheetSubjectDetail', {
            id: item.id,
            subjectKind: kind,
            subjectName: item.displayName
          })}
      />
    );
  }
  function mapItem(item: QueryDocumentSnapshot) {
    const displayName = item.get('displayName');
    const isPinned = pinnedSubjects?.includes(displayName) ? true : false;
    return { id: item.id, displayName, isPinned };
  }
  const [collections, setCollections] = React.useState<CollectionListSpec<SheetSubject, void>[]>();
  React.useEffect(() => {
    setCollections(
      project ?
        [{
          collection: sheetSubjectCollection(project, kind),
          addConstraints: q => q.orderBy('displayName', 'asc'),
          process: (docs: QueryDocumentSnapshot[]) => {
            const all = docs.map(mapItem);
            return [...all.filter(item => item.isPinned), ...all.filter(item => !item.isPinned)];
          },
          renderItem: renderSubject,
          searchTokens: item => [item.displayName]
        }] :
        undefined);
  }, [project?.path, kind]);

  return <ListPage collections={collections} estimatedItemSize={52} />;
}
