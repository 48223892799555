import { Workbox } from 'workbox-window';

import { StorageManager } from "./storage-manager";
import { UploaderImpl } from "./uploader-impl";
import { DownloaderImpl } from "./downloader-impl";
import {
  CACHE_DID_UPDATE,
  GET_UPLOAD_STATUSES,
  GET_VERSION,
  UPLOAD_DID_FAIL,
  UPLOAD_DID_START,
  UPLOAD_DID_SUCCEED
} from '../../service-worker/common';
import Handlers from './handlers';

export class StorageManagerImpl extends StorageManager {
  private readonly workbox: Workbox | undefined;
  private updateAvailable = false;
  protected handlers = new Handlers<boolean>("updateAvailable");

  public constructor(uploadUri: string) {
    const wb = 'serviceWorker' in navigator ? new Workbox('/sw.js') : undefined;
    wb?.addEventListener('installed', () => {
      this.updateAvailable = true;
      this.handlers.handle(this.updateAvailable);
    });
    wb?.register();
    const uploader = new UploaderImpl(uploadUri, wb?.messageSW({
      type: GET_UPLOAD_STATUSES
    }));
    const downloader = new DownloaderImpl();
    wb?.addEventListener('message', async event => {
      const t = event.data.type;
      if (t === CACHE_DID_UPDATE) {
        const { cacheName, url } = event.data.payload;
        downloader.onCacheUpdated(cacheName, url);
      }
      else if (t === UPLOAD_DID_START) {
        uploader.onServiceWorkerUploadStart(event.data.payload);
      }
      else if (t === UPLOAD_DID_SUCCEED) {
        uploader.onServiceWorkerUploadSuccess(event.data.payload);
      }
      else if (t === UPLOAD_DID_FAIL) {
        uploader.onServiceWorkerUploadFailure(event.data.payload);
      }
    });
    super(uploader, downloader);
    this.workbox = wb;
    this.workbox?.messageSW({ type: GET_VERSION })
      .then(version => console.debug(`Connected to service worker version ${version}`))
      .catch(err => console.error("Unable to get service worker version", err));
  }

  public override onUpdateAvailable(handler: (updateAvailable: boolean) => void) {
    return this.handlers.add(handler, this.updateAvailable);
  }

  public override async checkForUpdates() {
    if (!this.workbox) return;
    return this.workbox.update();
  }
}
