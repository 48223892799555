import { StyleProp, TextStyle } from "react-native";
import { CoreComponentsContext } from "./core-components";
import React from "react";

export default function FixtureLabel({ text, small, style }: { text: string, small?: boolean; style?: StyleProp<TextStyle> }) {
  const { Text, colors } = React.useContext(CoreComponentsContext);
  return (
    <Text style={{
      color: colors.fixture,
      letterSpacing: small ? 0.1 : 0.15,
      fontWeight: "medium",
      fontSize: small ? 14 : 16,
      lineHeight: small ? 20 : 24,
      ...(style as object || {})
    }}>
      {small ? text : text.toLocaleUpperCase()}
    </Text>
  );
}
