import { z } from 'zod';
import { reference } from "./reference";
import { emailAddressSchema } from './contact/emailAddress';

export const userReference = reference;

export const userSchema = z.object({
  displayName: z.string(),
  email: emailAddressSchema,
  language: z.string(),
  projectOwnershipQuota: z.number()
});
