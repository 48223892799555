export function stripQuery(urlString: string) {
  const i = urlString.indexOf('?');
  return i == -1 ? urlString : urlString.substring(0, i);
}

export function stripToken(urlString: string) {
  return urlString.replace(/[\?&]token=[^&]*&?/, s =>
    s.endsWith('&') ?
      (s.startsWith('?') ? '?' : '&') : ''
  );
}
