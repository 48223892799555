import { useTheme } from "react-native-paper";
import { TableCellComponent, TableColumnGroup, TableProps } from "./types/table";
import React from "react";

function TableRow({ children }: { children: React.ReactNode }) {
  return (
    <tr style={{
      breakInside: "avoid",
      pageBreakInside: "avoid"
    }}>
      {children}
    </tr>
  );
}

export function Table<T, CG extends TableColumnGroup>(
  { columnGroups, sections, totalCols, Row }: TableProps<T, CG>
) {
  const { colors, fonts, dark } = useTheme();
  const borderColor = colors.outline;
  const thStyle: React.CSSProperties = {
    fontWeight: "normal",
    borderColor,
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    padding: 6,
    breakInside: "avoid",
    pageBreakInside: "avoid"
  };

  const TableCell = React.useCallback<TableCellComponent>(
    ({ key, children, loading, rowIndex, groupIndex, columnIndex, rowSpan, backgroundColor }) => {
      const isFirst = groupIndex == 0 && columnIndex == 0;
      return (
        <td
          key={key} rowSpan={rowSpan}
          style={{
            height: 1, minHeight: 1, // Required in order for height: 100% to work for children
            padding: 0,
            borderColor,
            borderStyle: loading ? 'none' : 'solid',
            borderWidth: 0,
            borderBottomWidth: 1,
            borderRightWidth: 1,
            borderTopWidth: rowIndex == 0 ? 1 : 0,
            breakInside: "avoid",
            pageBreakInside: "avoid",
            ...(
              isFirst ? {
                position: "sticky",
                left: 0,
                zIndex: 1
              } : {}),
            backgroundColor: loading ? colors.surfaceDisabled : (
              backgroundColor ?? (dark ? '#000000' : '#ffffff')
            ),
            borderLeftWidth: isFirst ? 1 : 0
          }}
        >
          {children}
        </td>
      );
    },
    [borderColor, colors.surfaceDisabled]
  );
  return (
    <div style={{
      overflow: "scroll",
      width: "100%"
    }}>
      <table style={{
        ...fonts.bodyMedium,
        lineHeight: undefined,
        color: colors.onBackground,
        borderSpacing: 0,
        width: "100%"
      }}>
        <thead style={{
          position: "sticky",
          top: 0,
          zIndex: 2,
          background: colors.surfaceVariant,
          color: colors.onSurfaceVariant,
          breakInside: "avoid",
          pageBreakInside: "avoid",
          display: "table-row-group"
        }}>
          <tr>
            {columnGroups.map(({ key, label, columns }, i) =>
              label ?
                <th
                  key={key}
                  style={{
                    ...thStyle,
                    borderBottomWidth: 0,
                    borderLeftWidth: i == 0 ? 1 : 0
                  }}
                  scope="col"
                  colSpan={columns.length}
                >
                  {label}
                </th> :
                columns.map(({ key, label }, j) =>
                  <th
                    key={key}
                    style={{
                      ...thStyle,
                      borderLeftWidth: i == 0 && j == 0 ? 1 : 0
                    }}
                    scope="col" rowSpan={2}>{label}</th>
                )
            )}
          </tr>
          <tr>
            {columnGroups.flatMap(({ key, label, columns }, i) =>
              label ?
                columns.map((column, j) =>
                  <th
                    key={`${key} ${column.key}`}
                    style={{
                      ...thStyle,
                      ...(
                        i == 0 && j == 0 ?
                          {
                            borderLeftWidth: 1,
                            position: "sticky",
                            left: 0,
                            zIndex: 1
                          } : {
                            borderLeftWidth: 0
                          }
                      )
                    }}
                    scope="col"
                    rowSpan={label ? 1 : 2}
                    colSpan={1}
                  >
                    {column.label}
                  </th>
                ) :
                []
            )}
          </tr>
        </thead>
        <tbody>
          {sections.map(({ key, label, entries }) => [
            label ?
              <tr key={key}>
                <td
                  colSpan={totalCols}
                  style={{
                    ...fonts.headlineSmall,
                    lineHeight: undefined,
                    padding: 10,
                  }}
                >
                  {label}
                </td>
              </tr> :
              null,
            ...(entries?.map((entry, index) =>
              <React.Fragment key={index}>
                {React.createElement(Row, { entry, index, columnGroups, TableRow, TableCell })}
                <tr>
                  <td colSpan={totalCols}
                    style={{
                      height: 2,
                      borderBottomWidth: 1,
                      borderBottomColor: borderColor,
                      borderBottomStyle: "solid",
                      backgroundColor: colors.surfaceDisabled
                    }}
                  />
                </tr>
              </React.Fragment>
            ) ?? [])
          ]
          )}
        </tbody>
      </table>
    </div>
  );
}
