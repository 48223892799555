import { RootTabScreenProps } from "../types";
import { ProjectContext } from "../data/projectContext";
import React from "react";
import { useOnDayChange } from "../helpers/days";
import NavPage from "../components/pages/NavPage";
import { useSheetsCollectionSpec } from "../data/useSheetsCollectionSpec";
import CollectionList from "../components/CollectionList";

export default function SheetsScreen({ }: RootTabScreenProps<'sheets'>) {
  const { adminCategories, categories } = React.useContext(ProjectContext);
  const sheetsCollection = useSheetsCollectionSpec({
    userOnly: (
      (adminCategories.length == 0 && !categories.includes('postProduction')) ||
      (adminCategories.includes('arts') && adminCategories.length == 1) // TODO Remove this (added to avoid memory problem on iPad)
    ),
    since: true
  });
  return (
    <NavPage fullScreen isLoading={!sheetsCollection}>
      {sheetsCollection && <CollectionList collections={[sheetsCollection]} estimatedItemSize={85} />}
    </NavPage>
  );
}
