import { View } from "react-native";
import { Timestamp } from "@atgof-firebase/firebase";
import Select from "../Select";

function range(start: number, end: number): number[] {
  if (start === end) return [start];
  return [start, ...range(start + 1, end)];
}

function numberToString(n: number) { return n.toString(); }

function NumberRangeSelect({ start, end, selected, onChange, placeholder }: {
  start: number;
  end: number;
  selected: number;
  onChange: (n: number | undefined) => void;
  placeholder: string
}) {
  return <Select
    entries={range(start, end)}
    entryKey={numberToString}
    entryLabel={numberToString}
    selectedEntry={selected}
    onEntryChange={onChange}
    placeholder={placeholder}
  />
}

export default function DateField({ value, onChange }: {
  value: Timestamp;
  onChange: (v: Timestamp | undefined) => void;
}) {
  const date = value?.toDate();
  const year = date?.getFullYear();
  const month = date?.getMonth();
  const maxDay = (year && month && new Date(year, month, 0).getDate()) || 31;
  function handleChange(modifyDate: (d: Date, n: number) => void) {
    return (n: number | undefined) => {
      if (!n) onChange(undefined);
      else {
        const d = date ? new Date(date) : new Date(0, 0, 0);
        modifyDate(d, n);
        return onChange(Timestamp.fromDate(d));
      }
    };
  }
  return (
    <View style={{ flexDirection: "row", gap: 8 }}>
      <NumberRangeSelect start={2020} end={2030} placeholder="YYYY"
        selected={year}
        onChange={handleChange((d, n) => d.setFullYear(n))} />
      <NumberRangeSelect start={1} end={12} placeholder="MM"
        selected={month}
        onChange={handleChange((d, n) => d.setMonth(n))} />
      <NumberRangeSelect start={1} end={maxDay} placeholder="DD"
        selected={date?.getDate()}
        onChange={handleChange((d, n) => d.setDate(n))} />
    </View>
  );
}
