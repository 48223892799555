import { v4 as uuidv4 } from "uuid";
import React from 'react';
import { IconButton, Menu, ProgressBar, Text } from 'react-native-paper';
import { LanguageContext } from '../common/language';
import { Linking, View } from 'react-native';
import { MaterialIcons } from '@expo/vector-icons';
import { DocumentReference, FieldValue, getStorage } from '@atgof-firebase/firebase';
import { ProjectContext } from '../data/projectContext';
import { UserContext } from "../data/userContext";

export function ScriptExportButton({ sceneRef }: { sceneRef: DocumentReference | undefined }) {
  const { ph, language } = React.useContext(LanguageContext);
  const { project } = React.useContext(ProjectContext);
  const { user } = React.useContext(UserContext);
  const [menuVisible, setMenuVisible] = React.useState(false);
  const [result, setResult] = React.useState<undefined | true | null | string>();
  function openMenu() { setMenuVisible(true); }
  function closeMenu() { setMenuVisible(false); }
  const exportPDF = React.useCallback(
    async () => {
      const exportRef = project?.collection('exports').doc(uuidv4());
      if (!exportRef) return;
      setResult(true);
      try {
        await exportRef.set(
          {
            createdBy: user.ref,
            createdAt: FieldValue.serverTimestamp(),
            deleted: false,
            kind: 'postProductionScene',
            scene: sceneRef,
            parameters: {
              lang: language
            }
          }
        );
        exportRef.onSnapshot(async snapshot => {
          if (snapshot.get('errorAt')) {
            setResult(null);
            return;
          }
          const path = snapshot.get('path');
          if (path) {
            try {
              setResult(await getStorage().ref(path).getDownloadURL());
            }
            catch (err) {
              console.error(err);
              setResult(null);
            }
          }
        });
      }
      catch (err) {
        console.error(err);
        setResult(null);
      }
    },
    [project, user, sceneRef, language]
  );
  function dismiss() {
    closeMenu();
    setResult(result => result === true ? result : undefined);
  }
  const onPress = React.useCallback(
    () => {
      if (result === true) return;
      if (result === null) dismiss();
      else if (result) Linking.openURL(result);
      else exportPDF();
    },
    [result, exportPDF]
  );
  const menuItem = React.useMemo(
    () => result === true ?
      <View style={{ gap: 2 }}>
        <Text>{ph('generating-pdf') as string}</Text>
        <ProgressBar indeterminate />
      </View> :
      (result ?
        ph('download-pdf') as string :
        result === null ?
          <View style={{ flexDirection: "row", gap: 4, alignItems: "center" }}>
            <MaterialIcons name="error" size={20} />
            <Text>{ph('unable-to-generate-pdf') as string}</Text>
          </View > :
          ph('generate-pdf') as string
      ),
    [result]
  );
  if (!sceneRef) return null;
  return (
    <Menu
      visible={menuVisible} anchorPosition="bottom"
      onDismiss={dismiss}
      anchor={<IconButton icon="export-variant" onPress={openMenu} />}
    >
      <Menu.Item
        title={menuItem}
        onPress={onPress}
      />
    </Menu>
  );
}
