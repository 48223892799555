import React from "react";
import { ActivityIndicator } from "react-native";
import { useSeasons } from "../data/useSeasons";
import { DocumentSnapshot } from "@atgof-firebase/firebase";
import { LanguageContext } from "../common/language";
import Labelled from "./LabelledSelect";
import Select from "./Select";

export default function SeasonSelector(
  { includeHistorical = false, selectedSeason, setSelectedSeason }:
    {
      includeHistorical?: boolean
      selectedSeason: DocumentSnapshot | undefined;
      setSelectedSeason: (season: DocumentSnapshot | undefined) => void
    }
) {
  const { ph } = React.useContext(LanguageContext);
  const seasons = useSeasons(includeHistorical);
  if (!seasons) return <ActivityIndicator />;
  return (
    <Labelled label={ph('season') as string}>
      <Select
        entries={seasons}
        entryKey={doc => doc.id} entryLabel={doc => doc.id}
        selectedEntry={selectedSeason}
        onEntryChange={setSelectedSeason}
        autoselect
      />
    </Labelled>
  );
}
