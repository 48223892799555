import { OK, ParseInput, ZodType } from 'zod';

class ZodTimestamp extends ZodType {
  _parse(input: ParseInput) {
    const { ctx } = this._processInputParams(input);
    return OK(ctx.data);
  }
}

export const timestamp = new ZodTimestamp({});
