import { DocumentSnapshot } from "@atgof-firebase/firebase";
import React from "react";
import { LanguageContext } from "../common/language";
import { View } from "react-native";
import FixtureLabel from "../common/FixtureLabel";
import { Checkbox } from "react-native-paper";

export function TeamsSelector({
  availableTeams, selectedTeamPaths, setSelectedTeamPaths
}: {
  availableTeams: DocumentSnapshot[] | undefined;
  selectedTeamPaths: string[] | null;
  setSelectedTeamPaths: (update: (teams: string[] | null) => string[]) => void
}) {
  const { ph } = React.useContext(LanguageContext);
  return (
    <View style={{ flexDirection: "row" }}>
      <FixtureLabel text={ph('teams') as string} />
      {availableTeams?.map(doc =>
        <Checkbox.Item
          key={doc.id}
          label={doc.id}
          status={selectedTeamPaths?.find(path => path === doc.ref.path) ? 'checked' : 'unchecked'}
          onPress={() => setSelectedTeamPaths(paths => {
            const path = doc.ref.path;
            const idx = paths?.indexOf(path) ?? -1;
            return idx == -1 ?
              [...(paths ?? []), path] :
              [...(paths?.slice(0, idx) ?? []), ...(paths?.slice(idx + 1) ?? [])];
          })}
        />
      )}
    </View>
  );
}

