import React from "react";
import { GetPosResult } from "./useTramlines";
import { LayoutChangeEvent } from "react-native";

export interface SceneDetailProvider {
  getParagraphPos(paragraphIndex: number): GetPosResult;
}
export type RegisterSceneDetailProvider =
  (sceneDetailProvider: SceneDetailProvider) => (() => void);

type ParagraphPos = { y: number; height: number };

export class ParagraphPosManager implements SceneDetailProvider {
  protected _sceneId: string | undefined;
  protected _registerSceneDetailProvider: RegisterSceneDetailProvider | undefined;
  protected paragraphCount: number | undefined;
  protected positions = new Map<number, ParagraphPos>();
  protected _unregister: (() => void) | undefined;

  get sceneId() { return this._sceneId; }
  set sceneId(value) {
    if (value !== this._sceneId) {
      this._sceneId = value;
      this.registerIfPossible();
    }
  }

  get registerSceneDetailProvider() { return this._registerSceneDetailProvider; }
  set registerSceneDetailProvider(value) {
    if (value !== this._registerSceneDetailProvider) {
      this._registerSceneDetailProvider = value;
      this.registerIfPossible();
    }
  }

  private hasValidBasis()
    : this is (ParagraphPosManager & {
      sceneId: string, paragraphCount: number
    }) {
    return !(
      this.sceneId === undefined ||
      this.paragraphCount === undefined
    );
  }

  unregister() {
    if (this._unregister) {
      this._unregister();
      this._unregister = undefined;
    }
  }

  private registerIfPossible() {
    this.unregister();
    if (
      this.registerSceneDetailProvider &&
      this.hasValidBasis() &&
      this.positions.size == this.paragraphCount
    ) {
      this._unregister = this.registerSceneDetailProvider(this);
    }
  }

  onParagraphLayout(paragraphIndex: number, evt: LayoutChangeEvent) {
    const { y, height } = evt.nativeEvent.layout;
    this.positions.set(paragraphIndex, { y, height });
    this.registerIfPossible();
  }

  reset(paragraphCount: number | undefined) {
    this.positions.clear();
    this.paragraphCount = paragraphCount;
    this.registerIfPossible();
  }

  getParagraphPos(paragraphIndex: number): GetPosResult {
    if (!this.hasValidBasis()) return;
    const pos = this.positions.get(paragraphIndex);
    return pos ? {
      top: pos.y,
      height: pos.height,
      sceneId: this.sceneId,
      paragraphIndex
    } : undefined;
  }
}

export function useParagraphPosManager(
  sceneId: string | undefined,
  registerSceneDetailProvider: RegisterSceneDetailProvider,
  paragraphCount: number | undefined
): ParagraphPosManager {
  const { current } = React.useRef(new ParagraphPosManager());
  React.useEffect(() => {
    current.sceneId = sceneId;
  }, [sceneId]);
  React.useEffect(() => {
    current.registerSceneDetailProvider = registerSceneDetailProvider;
    return () => { current.unregister() };
  }, [registerSceneDetailProvider]);
  React.useEffect(() => {
    current.reset(paragraphCount);
  }, [paragraphCount]);
  return current;
}
