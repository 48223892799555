import React from "react";
import { PhraseKey } from "../common/phrases";
import { DocumentSnapshot } from "@atgof-firebase/firebase";
import { LanguageContext } from "../common/language";
import Labelled from "./LabelledSelect";
import Select, { SelectConfigProps } from "./Select";
import { useTeams } from "../data/team";

export function TeamSelector(
  { labelPh, selectedTeam, onTeamChange, ...props }:
    {
      labelPh?: PhraseKey
      selectedTeam: DocumentSnapshot | undefined;
      onTeamChange: (team: DocumentSnapshot | undefined) => void
    } & SelectConfigProps
) {
  const { ph } = React.useContext(LanguageContext);
  const teams = useTeams();
  return (
    <Labelled label={ph(labelPh ?? 'team') as string}>
      <Select
        {...props}
        entries={teams} entryKey={({ id }) => id}
        entryLabel={doc => doc.get('displayName')}
        selectedEntry={selectedTeam} onEntryChange={onTeamChange} autoselect
      />
    </Labelled>
  );
}
