import React from "react";
import { retrieveItem, storeItem } from "./prefs";


export function useStoredPref<T>(key: string, defaultValue: T): [T | null, React.Dispatch<React.SetStateAction<T | null>>] {
  const stored = React.useRef<T | null>(null);
  const [value, setValue] = React.useState<T | null>(null);
  React.useEffect(() => {
    if (stored.current !== null) return;
    retrieveItem(key).then(json => {
      const v = json === null ? defaultValue : JSON.parse(json);
      stored.current = v;
      setValue(v);
    });
  }, []);
  React.useEffect(() => {
    const s = JSON.stringify(value);
    if (value == null || s === JSON.stringify(stored.current)) return;
    storeItem(key, s).then(() => {
      stored.current = value;
    });
  }, [value]);
  return [value, setValue];
}
