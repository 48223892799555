import { useNavigation } from "@react-navigation/native";
import { Platform } from "react-native";
import { IconButton } from "react-native-paper";

export default function FileImportButton() {
  const { navigate } = useNavigation();
  return (
    Platform.OS === "web" ?
      <IconButton icon="import" onPress={() => navigate('fileImports', {})} /> : null
  );
}
