import React from "react";
import { GestureResponderEvent, View } from "react-native";
import { List } from "react-native-paper";

export type ListItemProps = {
  label?: string;
  descriptionItem?: React.ReactNode;
  children?: React.ReactNode;
  onPress?: (event: GestureResponderEvent) => void;
  extra?: React.ReactElement
}

export default function ListItem({
  label, descriptionItem, children, onPress, extra
}: ListItemProps) {
  return (
    <List.Item onPress={onPress} title={label ?? ''}
      descriptionEllipsizeMode="clip"
      description={() => <View>{descriptionItem}{children}{extra}</View>}
      right={onPress ? props => <List.Icon {...props} icon="chevron-right" /> : undefined}
    />
  );
}
