import { Path, Svg, SvgProps } from "react-native-svg";
import Animated, { runOnJS, useSharedValue } from "react-native-reanimated";
import React from "react";
import { useRefFor } from "./useRefFor";
import { AnnotationOverlayView } from "./AnnotationOverlayView";

const pathPrefix = "M ";
const pathSeparator = "L ";

const AnimatedPath = Animated.createAnimatedComponent(Path);

export type AnnotationCanvasLayer = {
  key: React.Key;
  readonly: boolean;
  color: string;
  paths: string[]
}

function updateLayer(
  layers: AnnotationCanvasLayer[],
  key: React.Key | undefined,
  update: (paths: string[]) => string[]
) {
  if (key === undefined) return layers;
  const i = layers.findIndex(layer => layer.key === key);
  if (i == -1) return layers;
  const layers_ = [...layers];
  layers_[i] = { ...layers_[i], paths: update(layers_[i].paths) };
  return layers_;
}

export interface AnnotationCanvasRef {
  setEraseMode: (erase: boolean) => void;
  getLayers: () => AnnotationCanvasLayer[];
  setLayers: (layers: AnnotationCanvasLayer[]) => void
}

function getDrawingLayer(layers: AnnotationCanvasLayer[], isErasing: boolean) {
  if (isErasing) return;
  for (let i = layers.length - 1; i >= 0; i--) {
    if (!layers[i].readonly) return layers[i];
  }
}

const erasableMargin = 2;

type MinMax = {
  min: number;
  max: number;
}

type Point = { x: number, y: number }

type PathGeometry = {
  x: MinMax;
  y: MinMax;
  points: Point[];
}

type Erasable = PathGeometry & {
  layerKey: React.Key;
  indexInLayer: number
}

function pointsForPath(path: string): Point[] {
  return path.substring(pathPrefix.length).split(pathSeparator)
    .map(s => {
      const [x, y] = s.split(" ").map(x => parseFloat(x));
      return { x, y };
    });
}

function geometryForPath(path: string): PathGeometry {
  const points = pointsForPath(path);
  const [x, y] = (['x', 'y'] as (keyof Point)[]).map(k => {
    const vals = points.map(point => point[k]);
    const min = Math.min(...vals) - erasableMargin; // TODO erasableMargin here is leaky abstraction
    const max = Math.max(...vals) + erasableMargin; // TODO erasableMargin here is leaky abstraction
    return { min, max };
  });
  return { x, y, points };
}

export const AnnotationCanvas = React.forwardRef<
  AnnotationCanvasRef,
  {
    strokeWidth: number;
  } & SvgProps
>(({ strokeWidth, ...props }, ref) => {
  const [currentLayers, setCurrentLayers] = React.useState<AnnotationCanvasLayer[]>([]);
  const [isErasing, setIsErasing] = React.useState(false);
  const erasables = useSharedValue<Erasable[] | undefined>(undefined);
  React.useEffect(() => {
    erasables.value =
      isErasing ?
        currentLayers
          .filter(({ readonly }) => !readonly)
          .flatMap(({ key, paths }) => paths.map((path, indexInLayer) => ({
            ...geometryForPath(path),
            layerKey: key,
            indexInLayer
          }))) : undefined;
  }, [currentLayers, isErasing]);
  const isErasingRef = useRefFor(isErasing);
  const drawingLayer = React.useMemo(
    () => getDrawingLayer(currentLayers, isErasing),
    [currentLayers, isErasing]);
  const currentPath = useSharedValue<string>("M 0 0");
  function commitPath(path: string | undefined) {
    setCurrentLayers(layers => updateLayer(
      layers, getDrawingLayer(layers, isErasingRef.current ?? true)?.key,
      paths => path ? [...paths, path] : paths
    ));
  }
  function erase(toErase: Erasable[]) {
    setCurrentLayers(layers =>
      toErase.reduce((layers, { layerKey, indexInLayer }) =>
        updateLayer(layers, layerKey, paths => ([
          ...paths.slice(0, indexInLayer),
          ...paths.slice(indexInLayer + 1)
        ])),
        layers
      )
    );
  }
  function startPath({ x, y }: any) {
    if (!erasables.value) currentPath.value = pathPrefix + `${x} ${y}`;
  }
  function updatePath({ x, y }: any) {
    'worklet';
    if (!erasables.value) currentPath.value += pathSeparator + `${x} ${y}`;
    else {
      let toErase: Erasable[] = [];
      for (const erasable of erasables.value) {
        if (x >= erasable.x.min && x <= erasable.x.max &&
          y >= erasable.y.min && y <= erasable.y.max
        ) {
          for (const point of erasable.points) {
            if (x >= point.x - erasableMargin && x <= point.x + erasableMargin &&
              y >= point.y - erasableMargin && y <= point.y + erasableMargin
            ) {
              toErase.push(erasable);
              break;
            }
          }
        }
      }
      if (toErase.length > 0) runOnJS(erase)(toErase);
    }
  }
  function commitPathIfNeeded() {
    'worklet';
    if (!erasables.value) runOnJS(commitPath)(currentPath.value);
  }
  React.useImperativeHandle(ref, () => ({
    setLayers: setCurrentLayers,
    getLayers: () => currentLayers,
    setEraseMode: setIsErasing
  }));
  return (
    <AnnotationOverlayView
      startPath={startPath}
      updatePath={updatePath}
      commitPath={commitPathIfNeeded}
    >
      <Svg {...props}>
        {currentLayers.map(({ key, color, paths }) =>
          <React.Fragment key={key}>
            {paths.map((path, i) =>
              <Path
                key={i}
                d={path}
                stroke={color}
                fill="none"
                strokeWidth={strokeWidth}
              />
            )}
          </React.Fragment>
        )}
        <AnimatedPath
          d={isErasing ? undefined : currentPath}
          stroke={drawingLayer?.color}
          fill="none"
          strokeWidth={strokeWidth}
        />
      </Svg>
    </AnnotationOverlayView>
  );
});
