import React from "react";

export function AnnotationOverlayView({ startPath, updatePath, commitPath, children }: {
  startPath: ({ x, y }: any) => void;
  updatePath: ({ x, y }: any) => void;
  commitPath: () => void;
  children: React.ReactNode;
}) {
  const viewRef = React.useRef<HTMLDivElement>(null);
  const currentPointerType = React.useRef<"pen" | "mouse" | "touch">();
  const [isPointerDown, setIsPointerDown] = React.useState(false);

  function getCoordinates(event: React.PointerEvent<HTMLDivElement>) {
    const boundingBox = viewRef.current?.getBoundingClientRect();
    return boundingBox ?
      {
        x: event.pageX - boundingBox.left - (window.scrollX ?? 0),
        y: event.pageY - boundingBox.top - (window.scrollY ?? 0),
      } : {
        x: 0,
        y: 0
      };
  }
  function preventPenEventPropagation(event: Event | React.PointerEvent) {
    if (currentPointerType.current === "pen") {
      event.preventDefault();
      event.stopPropagation();
    }
  }
  function onPointerDown(event: React.PointerEvent<HTMLDivElement>) {
    currentPointerType.current = event.pointerType;
    if (event.pointerType === "touch") return;
    if (event.pointerType === 'mouse' && event.button !== 0) return;
    preventPenEventPropagation(event);
    setIsPointerDown(true);
    startPath(getCoordinates(event));
  };

  function onPointerMove(event: React.PointerEvent<HTMLDivElement>) {
    if (!isPointerDown) return;
    if (event.pointerType === "touch") return;
    updatePath(getCoordinates(event));
  }
  function onPointerUp(event: React.PointerEvent<HTMLDivElement> | PointerEvent) {
    if (!isPointerDown) return;
    if (event.pointerType === 'mouse' && event.button !== 0) return;
    if (event.pointerType === "touch") return;
    setIsPointerDown(false);
    commitPath();
  }

  React.useEffect(() => {
    document.addEventListener('pointerup', onPointerUp);
    return () => {
      document.removeEventListener('pointerup', onPointerUp);
      commitPath();
    };
  }, []);

  React.useEffect(() => {
    viewRef.current?.addEventListener(
      "touchstart", preventPenEventPropagation,
      { passive: false }
    );
    return () => viewRef.current?.removeEventListener("touchstart", preventPenEventPropagation);
  }, []);

  return (
    <div
      ref={viewRef}
      role="presentation"
      style={{
        touchAction: "pan-y",
        width: "100%", height: "100%"
      }}
      touch-action="pan-y"
      onPointerDown={onPointerDown}
      onPointerMove={onPointerMove}
      onPointerUp={onPointerUp}
    >
      {children}
    </div>
  );
}
