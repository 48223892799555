import { useNavigation } from '@react-navigation/native';
import { DocumentReference, FieldValue } from '@atgof-firebase/firebase';
import { useContext } from 'react';
import { useProject } from "../data/useProject";
import {
  isCharacterCategory, SheetCategory, sheetsCollection, SheetSubjectKind
} from '../common/sheet';
import { SubjectSpec } from '../common/subject';
import { UserContext } from '../data/userContext';
import { generateNextSlateAttrs } from '../data/team';
import NewSheetOrLookButton from './NewSheetOrLookButton';
import { episodeForScene } from '../common/episode';
import { objectCreationAttributes } from '../data/util';

export type NewSheetContext = {
  episodeRef?: DocumentReference;
  sceneRef?: DocumentReference;
  subject?: SubjectSpec
}

export default function NewSheetButton(
  { episodeRef, sceneRef, subject, restrictToCategories }:
    NewSheetContext & { restrictToCategories?: SheetCategory[] }
) {
  const { navigate } = useNavigation();
  const { user } = useContext(UserContext);
  const project = useProject();
  const newSheetAction = (category: SheetCategory) => () => {
    if (!project) return;
    const forCharacter = isCharacterCategory(category);
    const kind: SheetSubjectKind | undefined =
      forCharacter ? 'character' : (category === 'arts' ? 'set' : undefined);
    const newDoc = sheetsCollection(project).doc();
    const isPA = category === 'pa';
    (isPA ? generateNextSlateAttrs(sceneRef) : Promise.resolve({})).then(extra =>
      newDoc.set({
        ...(
          sceneRef ? {
            scene: sceneRef,
            ...(episodeRef ?
              { episode: episodeRef } :
              (sceneRef ? { episode: episodeForScene(sceneRef) } : {}))
          } : {}),
        ...(subject && subject.subjectKind === kind ? { [kind]: subject.subjectName } : {}),
        category,
        ...objectCreationAttributes(user.ref),
        ...(forCharacter ? { look: 'day' } : {}),
        ...(isPA ? { 'pa:sound': { key: 'sync' } } : {}),
        ...extra
      }));
    navigate('sheet', { isNew: 'true', path: newDoc.path });
  };
  return <NewSheetOrLookButton kind="sheet" actionForCategory={newSheetAction} restrictToCategories={restrictToCategories} />;
}
