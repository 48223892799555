import { View } from "react-native";

export function ColourWell({ value, size }: { value: string, size: number }) {
  return (
    <View
      style={{
        minWidth: size, minHeight: size,
        borderRadius: 2,
        backgroundColor: value
      }}
    />
  );
}
