import { DrawerHeaderProps } from "@react-navigation/drawer";
import { Appbar } from "react-native-paper";
import { getHeaderTitle } from "@react-navigation/elements";
import { NativeStackHeaderProps } from "@react-navigation/native-stack";
import OpenDrawerButton from "./OpenDrawerButton";

export default function NavigationBar({ navigation, route, options, ...props }: DrawerHeaderProps | NativeStackHeaderProps) {
  const title = getHeaderTitle(options, route.name);
  const canGoBack = navigation.canGoBack();
  return (
    <Appbar.Header>
      {
        options.headerLeft ? options.headerLeft({ canGoBack }) :
          ('back' in props && props.back ?
            <Appbar.BackAction onPress={navigation.goBack} /> :
            ('openDrawer' in navigation ? <OpenDrawerButton navigation={navigation} /> : null))
      }
      <Appbar.Content title={title} />
      {options.headerRight ? options.headerRight({ canGoBack }) : null}
    </Appbar.Header>
  );
}
