import { Icon } from "react-native-paper";
import { TimeOfDay } from "../common/phrases";
import { TimeOfDayRepresentationProps } from "../common/core-components";

export const timeOfDayIcons: Record<TimeOfDay, string> = {
  "day": "weather-sunny",
  "night": "weather-night",
  "dawn": "weather-sunset-up",
  "dusk": "weather-sunset"
}

export function TimeOfDayRepresentation({ timeOfDay }: TimeOfDayRepresentationProps) {
  const timeOfDayIcon = timeOfDay ? timeOfDayIcons[timeOfDay] : undefined;
  if (!timeOfDayIcon) return null;
  return <Icon source={timeOfDayIcon} size={18} />;
}
