import { Platform, View } from "react-native";
import { Banner, Text } from "react-native-paper";

export type AlertBoxProps = {
  status: 'info' | 'error';
  visible?: boolean;
  title?: string;
  message?: string;
  children?: React.ReactNode
}

export function AlertBox({ status, visible, title, message, children }: AlertBoxProps) {
  return (
    <Banner
      visible={visible === undefined ? true : visible}
      icon={status === 'error' ? "alert" : "information"}
    >
      <View style={{
        flexWrap: 'wrap',
        flexDirection: Platform.OS === 'web' ? 'column' : 'row'
      }}>
        {title ? <Text variant="bodyLarge">{title}</Text> : null}
        <Text variant="bodyMedium">{message}</Text>
        {children}
      </View>
    </Banner>
  );
}
