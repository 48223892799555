import React from "react";
import { DocumentSnapshot } from "@atgof-firebase/firebase";
import { GetPos, useTramlines, xOffset } from "./useTramlines";
import { useNavigation } from "@react-navigation/native";
import { SceneScript } from "../../common/script";
import { TramlineProps, tramlineDisplayProps } from "../../common/take";
import { Platform, Pressable, Text, TextStyle, View, ViewStyle } from "react-native";

function TramlineText(
  { style, color, text }:
    {
      style?: TextStyle;
      color: string;
      text: string | undefined;
    }
) {
  if (!text) return null;
  return (
    <Text
      style={{
        fontFamily: "RobotoCondensed_400Regular", fontSize: 16,
        textAlign: "center",
        backgroundColor: "#ffffffcc",
        overflow: "hidden",
        ...(style ?? {}),
        color,
      }}
      numberOfLines={1}
    >
      {text}
    </Text>
  );
}

function TramlinePart(
  { style, color, rotation, text, textStyle, noLine, dotted }:
    {
      style: ViewStyle;
      color: string;
      rotation: number;
      text: string | undefined;
      textStyle?: TextStyle;
      noLine?: boolean;
      dotted?: boolean;
    }
) {
  return (
    <View style={{
      display: "flex",
      position: 'absolute',
      borderStyle: noLine ? undefined : (dotted && Platform.OS == 'web' ? 'dotted' : 'solid'),
      borderBottomWidth: noLine ? undefined : 2,
      borderBottomColor: noLine ? undefined : color,
      transform: `rotate(${rotation}deg)`,
      transformOrigin: "50% 50%",
      paddingRight: 10,
      ...style
    }}>
      <TramlineText
        style={{ ...textStyle, alignSelf: "flex-start" }}
        color={color}
        text={text} />
    </View>
  );
}

function TramlineEnd(
  { color, x, y, text, bottomText }:
    {
      color: string;
      x: number;
      y: number;
      text?: string;
      bottomText?: string
    }) {
  const w = tramlineDisplayProps.width;
  const h = 28;
  const left = x - w;
  return (
    <React.Fragment>
      <TramlinePart
        style={{
          top: y - h,
          left,
          minWidth: w,
          height: 28
        }}
        color={color}
        rotation={tramlineDisplayProps.endRotation}
        text={text}
        textStyle={{ margin: "auto", fontWeight: "bold" }}
      />
      <TramlineText
        style={{
          position: 'absolute',
          top: y + 10,
          left: left + 5,
          fontWeight: "bold",
          minWidth: w / 2,
          transform: `rotate(${tramlineDisplayProps.endRotation}deg)`,
        }}
        color={color}
        text={bottomText}
      />
    </React.Fragment>
  );
}

function TramlineTitle(
  { color, slate, index, isPickup, x, y }:
    {
      color: string; slate: string | undefined; index: number | undefined;
      isPickup: boolean;
      x: number; y: number
    }
) {
  const text = React.useMemo(
    () => slate ? `${slate}/${index !== undefined ? index + 1 : ''}` : undefined,
    [slate, index, isPickup]);
  return (
    <TramlineEnd
      color={color} x={x} y={y} text={text}
      bottomText={isPickup ? "PU" : undefined} />
  );
}

function Tramline(
  {
    slate, index, isPickup = false, description, colour, sheet,
    endScriptLoc,
    startY, endY, xPos, takeRef
  }: TramlineProps) {
  const { navigate } = useNavigation();
  const hasEnd = endScriptLoc !== undefined;
  const onClick = sheet ? (() => navigate('sheet', { path: sheet.ref.path })) : undefined;
  const strokeColour = colour || "#000000";
  if (xPos === undefined) xPos = xOffset;
  if ((startY === undefined && endY === undefined)) {
    console.warn(`Unable to determine position of tramline ${takeRef?.path}`);
    return null;
  }
  return (
    <Pressable onPress={onClick}>
      {
        hasEnd && (endY !== undefined) ?
          <TramlineEnd color={strokeColour} x={xPos} y={endY} /> : null
      }
      {
        startY !== undefined && (!hasEnd || endY !== undefined) ?
          <TramlinePart
            style={{
              height: tramlineDisplayProps.lineWidth,
              width: hasEnd ? endY!! - startY : tramlineDisplayProps.minLineLength,
              left: xPos,
              top: startY - 2,
              transformOrigin: "0% 0%"
            }}
            color={strokeColour} rotation={90}
            text={description}
            textStyle={{ marginRight: 12 }}
            dotted={!hasEnd}
          /> : null
      }
      {
        startY !== undefined ?
          <TramlineTitle
            color={strokeColour}
            slate={slate} index={index} isPickup={isPickup}
            x={xPos} y={startY}
          /> : null
      }
    </Pressable>
  );
}

export default function Tramlines(
  { sheet, hasTake, take, getPos, scripts, style }:
    {
      sheet: DocumentSnapshot | undefined;
      hasTake: boolean;
      take: DocumentSnapshot | undefined;
      getPos: GetPos
      scripts: SceneScript[] | undefined;
      style: ViewStyle;
    }
) {
  const tramlines = useTramlines(scripts, hasTake, take, sheet, getPos);
  return (
    <View style={style}>
      {tramlines?.map(props => <Tramline key={props.takeRef.id} {...props} />)}
    </View>
  );
}
