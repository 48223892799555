import { useNavigation } from "@react-navigation/native";
import React from "react";
import { LanguageContext } from "../common/language";
import { getScenePath } from "../common/scene";
import ListItem from "./ListItem";
import { formatTimestamp, UNKNOWN_LABEL } from "../common/util";
import { SheetDisplayData } from "../data/sheet";
import { View } from "react-native";
import { Chip, Text } from "react-native-paper";

export default function SheetListEntry(
  { sheet, withoutSubject, withCategory, withMetadata }:
    { sheet: SheetDisplayData, withoutSubject?: boolean, withCategory: boolean, withMetadata?: boolean }
) {
  const { navigate } = useNavigation();
  const { ph } = React.useContext(LanguageContext);
  const subject = !withoutSubject && (sheet.character || sheet.set);
  const { season, episode, scene } = getScenePath(sheet.scene || sheet.episode);
  const subjectLabel = subject || sheet.paSlate;
  const sceneOrEpisodeLabel = episode !== undefined && scene === undefined ?
    (ph('ep') + ' ' + episode) :
    ((episode || UNKNOWN_LABEL) + "/" + (scene || UNKNOWN_LABEL));
  const seasonLabel = ph('season') + ' ' + (season || UNKNOWN_LABEL);
  return (
    <ListItem onPress={() => navigate('sheet', { path: sheet.path })}>
      <View style={{ flexDirection: 'row', gap: 10 }}>

        <View style={{ alignSelf: "flex-start", paddingRight: 10 }}>
          <Text variant="headlineSmall">
            {sceneOrEpisodeLabel}
          </Text>
          <Text variant="bodySmall">
            {seasonLabel.toLocaleUpperCase()}
          </Text>
        </View>
        <View>
          <View style={{ flexDirection: 'row', gap: 10 }}>
            {subjectLabel && <Text variant="titleLarge">{subjectLabel}</Text>}
            {sheet.look && <Chip mode="outlined">{(ph(sheet.look) as string).toLocaleUpperCase()}</Chip>}
            {withCategory && <Chip>{ph(sheet.category) as string}</Chip>}
          </View>
          {withMetadata && <Text>{formatTimestamp(sheet.createdAt, true)}</Text>}
        </View>
      </View>
    </ListItem>
  );
}
