import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { DocumentSnapshot, getStorage } from "@atgof-firebase/firebase";
import React from "react";
import { RootStackParamList } from "../types";
import { useProject } from "../data/useProject";
import DetailPage from "../components/pages/DetailPage";
import { LanguageContext } from "../common/language";
import { fileType } from "../data/imports";
import ScriptImporter from "../components/ScriptImporter";
import SeasonImporter from "../components/SeasonImporter";
import { AlertBox } from "../components/AlertBox";
import ErrorDetail from "../components/ErrorDetail";
import LoadingView from "../components/LoadingView";
import { Importer } from "./Importer";
import { Button } from "react-native-paper";

export default function FileImportScreen(
  { route }: NativeStackScreenProps<RootStackParamList, 'fileImport'>
) {
  const { ph } = React.useContext(LanguageContext);
  const { id } = route.params;
  const storage = getStorage();
  const project = useProject();
  const [importEntry, setImportEntry] = React.useState(undefined as DocumentSnapshot | undefined);
  const [data, setData] = React.useState(
    undefined as undefined | { error: any } | { content: any }
  );
  React.useEffect(() => {
    return project?.collection("imports").doc(id).onSnapshot(setImportEntry);
  }, [project, id]);
  const fileName = importEntry?.get('originalFileMetadata').name;
  const kind = fileType(fileName);
  const path = importEntry ? importEntry.get('path') + '.json' : undefined;
  function downloadData() {
    setData(undefined);
    storage.ref(path).getDownloadURL().then(url => {
      const xhr = new XMLHttpRequest();
      xhr.onerror = e => setData({ error: e });
      xhr.onload = () => {
        try {
          setData({ content: JSON.parse(xhr.responseText) });
        } catch (e) {
          setData({ error: e });
        }
      };
      xhr.responseType = "text";
      xhr.open("GET", url, true);
      xhr.send(null);
    }).catch(e => setData({ error: e }));
  }
  React.useEffect(() => {
    if (!path) return;
    downloadData();
  }, [path]);
  return (
    <DetailPage title={fileName || ph('import-file') as string}>
      {
        data ?
          ('content' in data ?
            <Importer
              importEntry={importEntry} kind={kind}
              component={kind === 'fdx' ? ScriptImporter : SeasonImporter}
              content={data.content}
            />
            :
            <AlertBox
              status="error" title={ph('unable-to') + ' ' + ph('load')}
              message={ph('try-again') as string}
            >
              <ErrorDetail>{data.error.toString()}</ErrorDetail>
              <Button onPress={downloadData}>{ph('retry') as string}</Button>

            </AlertBox>
          ) :
          <LoadingView />
      }
    </DetailPage>
  );
}
