import { LanguageContext } from "../common/language";
import dayjs from "dayjs";
import React from "react";
import DayPicker from "./DayPicker";
import { View } from "react-native";
import { Text } from "react-native-paper";

export function DateRangePicker(
  { startDay, setStartDay, endDay, setEndDay }:
    {
      startDay: dayjs.Dayjs; setStartDay: (d: dayjs.Dayjs) => void;
      endDay: dayjs.Dayjs; setEndDay: (d: dayjs.Dayjs) => void
    }
) {
  const { ph } = React.useContext(LanguageContext);
  return (
    <View style={{ flexDirection: "row", gap: 8 }}>
      <DayPicker day={startDay} setDay={setStartDay} />
      <Text style={{ alignSelf: "center" }}>{ph('until') as string}</Text>
      <DayPicker day={endDay} setDay={setEndDay} />
    </View>
  );
} 
