import { View } from "react-native";
import { ScriptScene } from "../common/file-import";
import { Scene, SceneData } from "../common/scene";
import { Checkbox, SegmentedButtons } from "react-native-paper";
import { LanguageContext } from "../common/language";
import React from "react";
import { TimeOfDay } from "../common/phrases";
import { timeOfDayIcons } from "./TimeOfDayRepresentation";
import { ChipEditor } from "./ChipEditor";
import { normaliseSubjectName } from "../common/util";

export function SceneLocationEditor<T extends (SceneData | Scene | ScriptScene)>(
  { scene, updateScene, setsOptions }:
    {
      scene: T;
      updateScene: (data: Partial<T>) => Promise<void>;
      setsOptions: string[];
    }
) {
  const { ph } = React.useContext(LanguageContext);
  return (
    <View style={{ gap: 4 }}>
      <View style={{ flexDirection: "row", alignItems: "center", gap: 4 }}>
        <Checkbox.Item
          label={ph('int') as string}
          status={scene.isInterior ? 'checked' : 'unchecked'}
          onPress={() => updateScene({ isInterior: !scene.isInterior } as any) /* TODO type? */}
        />
        <Checkbox.Item
          label={ph('ext') as string}
          status={scene.isExterior ? 'checked' : 'unchecked'}
          onPress={() => updateScene({ isExterior: !scene.isExterior } as any) /* TODO type? */}
        />
        <SegmentedButtons
          value={scene.timeOfDay ?? ""}
          onValueChange={timeOfDay => updateScene({ timeOfDay } as any) /* TODO type? */}
          buttons={(Object.keys(timeOfDayIcons) as TimeOfDay[]).map(timeOfDay => ({
            value: timeOfDay,
            label: ph(timeOfDay) as string,
            icon: timeOfDayIcons[timeOfDay]
          }))}
        />
      </View>
      <ChipEditor
        label={ph('location') as string}
        newItemToAddPhrase={ph('location') as string}
        entries={
          ('sets' in scene && scene.sets?.length ? scene.sets :
            scene.location ? [scene.location] : []) ?? []}
        entryKey={displayName => displayName}
        entryLabel={displayName => displayName}
        labelFromTextInput={normaliseSubjectName}
        searchTokens={displayName => ([displayName])}
        candidateList={setsOptions}
        onChange={sets => updateScene({
          sets,
          location: sets.length ? sets[0] : undefined
        } as any) /* TODO type? */}
        min={1} max={1}
      />
    </View>
  );
}
