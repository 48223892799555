import { FirebaseStorage, DocumentReference } from "@atgof-firebase/firebase";
import Handlers from "./handlers";
import { getScenePath } from "../../common/scene";
import _ from 'lodash';

export const downloadKinds = ['scripts', 'images'] as const;

export type DownloadKind = typeof downloadKinds[number];

type DownloadStatus = {
  path: string;
  remoteURI: string;
  kind: DownloadKind;
  error?: any
};

export type Downloads = { [path: string]: DownloadStatus }

const delay = (ms: number) => new Promise(res => setTimeout(res, ms));

export const scenesKey = 'scenes';
export const looksKey = 'looks';

export type Reference = {
  cachedURI: string,
  cachedDate: string
};

export type EntryTree = Record<string, Reference>
export type SuffixTree = Record<string, EntryTree>
export type SceneTree = Record<string, SuffixTree>
export type EpisodeTree = Record<string, SceneTree>
export type SeasonTree = Record<string, EpisodeTree>
export type ProjectTree = Record<string, SeasonTree>
export type ReferencesTree = Record<string, ProjectTree>

export type AllDownloaded = { referenced: ReferencesTree, unreferenced: string[] }

const referencePathLength = 6;

export function referencePathForScene(
  sceneRef: DocumentReference | undefined,
  suffix: string | undefined
) {
  const { project, season, episode, scene } = getScenePath(sceneRef);
  if (!(project && season && episode && scene && suffix)) return;
  return [scenesKey, project, season, episode, scene, suffix].join('/');
}

export function referencePathForLook(
  lookRef: DocumentReference | undefined
) {
  return undefined; // TODO
}


export abstract class Downloader {
  protected downloads: Downloads;
  protected handlers = new Handlers<Downloads>("download");

  protected constructor() {
    this.downloads = {};
  }

  public async getURI(
    storage: FirebaseStorage, path: string, kind: DownloadKind,
    referencePath: string | undefined
  ) {
    const remoteURI = await storage.ref(path).getDownloadURL();
    let cachedURI;
    try {
      cachedURI = await this.getCachedURI(remoteURI, path, kind);
    }
    catch (err) { console.error("Error in cache lookup", err); }
    const shouldDownload = !cachedURI;
    if (shouldDownload) {
      this.registerDownload(path, remoteURI, kind);
      try {
        const { status, uri } = await this.doDownload(remoteURI, path, kind);
        if (status != 0 && (status < 200 || status >= 300)) {
          throw new Error(`Status ${status}`);
        }
        cachedURI = uri;
      }
      catch (err) {
        console.error(`Failed to download ${path}`, err); // TODO Retries etc
        this.registerFailure(path, err);
      }
    }
    if (cachedURI) {
      if (referencePath) {
        const i = path.lastIndexOf('/');
        this.putReference(referencePath + '/' + path.substring(i + 1), {
          cachedURI,
          cachedDate: new Date().toISOString()
        }).catch(err => console.error(
          `Unable to create reference for ${path} at ${referencePath}`, err
        ));
      }
      if (shouldDownload) this.registerCompletion(path);
      return cachedURI;
    }
    return remoteURI;
  }

  public async retrieveAsString(
    storage: FirebaseStorage, path: string, kind: DownloadKind,
    referencePath: string | undefined
  ) {
    return this.readAsString(await this.getURI(storage, path, kind, referencePath));
  }

  public onDownloads(handler: (downloads: Downloads) => void) {
    return this.handlers.add(handler, { ...this.downloads });
  }

  public async allDownloaded(): Promise<AllDownloaded> {
    const entries = await this.getAllReferenceEntries();
    const referencedURIs = entries.map(([_, r]) => r.cachedURI);
    const cacheKeys = await this.getAllCacheKeys();
    const unreferenced = cacheKeys.filter(uri => !referencedURIs.includes(uri));
    const referenced = entries
      .filter(([_, r]) => cacheKeys.includes(r.cachedURI))
      .reduce(
        (tree, [k, r]) => {
          const parts = k.split('/');
          if (parts.length != referencePathLength + 1) {
            console.error("Unexpected reference path", parts);
            return tree;
          }
          return _.set(tree, parts, r);
        },
        {}
      );
    return { referenced, unreferenced };
  }

  public abstract getFreeStorageEstimate(): Promise<number | undefined>;

  protected abstract getCachedURI(remoteURI: string, path: string, kind: DownloadKind)
    : Promise<string | undefined>;

  protected abstract doDownload(
    remoteURI: string, path: string, kind: DownloadKind
  ): Promise<{ status: number, uri: string }>;

  protected abstract readAsString(uri: string): Promise<string>;

  protected abstract putReference(key: string, reference: Reference): Promise<void>;

  protected abstract getAllCacheKeys(): Promise<string[]>;

  protected abstract getAllReferenceEntries(): Promise<[string, Reference][]>;

  protected registerDownload(path: string, remoteURI: string, kind: DownloadKind) {
    this.downloads[path] = { path, remoteURI, kind };
    this.handlers.handle({ ...this.downloads });
  }

  protected registerCompletion(path: string) {
    delete this.downloads[path];
    this.handlers.handle({ ...this.downloads });
  }

  protected registerFailure(path: string, error: any) {
    this.downloads[path].error = error;
    this.handlers.handle({ ...this.downloads });
  }
}
