import { DocumentSnapshot, DocumentReference } from "@atgof-firebase/firebase";
import React from "react";
import { useProject } from "../data/useProject";
import { initials } from "../common/util";
import { Avatar, Menu, ProgressBar, Text, TouchableRipple } from "react-native-paper";
import { ProjectsContext } from "../data/projectsContext";

function ProjectTitle({ project }: { project: DocumentReference }) {
  const [displayName, setDisplayName] = React.useState<string>();
  React.useEffect(() => project.onSnapshot(doc => { if (doc) setDisplayName(doc.get('displayName')) }), [project]);
  return displayName ? <Text>{displayName}</Text> : <ProgressBar indeterminate />;
}

export default function ProjectChooser() {
  const projectRef = useProject();
  const { projects, setCurrentProject } = React.useContext(ProjectsContext);
  const [menuVisible, setMenuVisible] = React.useState(false);
  function openMenu() { setMenuVisible(true); }
  function closeMenu() { setMenuVisible(false); }
  const [project, setProject] = React.useState<DocumentSnapshot>();
  React.useEffect(
    () => {
      setProject(undefined);
      return projectRef?.onSnapshot(setProject);
    },
    [projectRef]);
  const displayName: string | undefined = project?.get('displayName');
  const label = displayName?.length ? initials(displayName) : '';
  const brand = project?.get('brand');
  return (
    <Menu
      visible={menuVisible}
      onDismiss={closeMenu}
      anchor={
        <TouchableRipple onPress={openMenu} style={{ marginRight: 8 }}>
          <Avatar.Text size={24} label={label ?? ''}
            color={brand?.color || '#ffffff'}
            style={{ backgroundColor: brand?.backgroundColor || '#000000' }}
          />
        </TouchableRipple>
      }
      anchorPosition="bottom"
    >
      {projects.map((entry) => {
        const id = entry.project?.id;
        return (
          <Menu.Item
            key={id}
            onPress={() => { closeMenu(); setCurrentProject(entry) }}
            title={<ProjectTitle project={entry.project!} />}
            leadingIcon={id === project?.id ? "radiobox-marked" : "radiobox-blank"}
          />
        );
      })}
    </Menu>
  );
}
