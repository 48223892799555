import NavPage from "../components/pages/NavPage";
import { RootTabScreenProps } from "../types";
import React from "react";
import { BackendContext } from "../data/backend";
import { useProject } from "../data/useProject";
import { DocumentSnapshot } from "@atgof-firebase/firebase";
import { View } from "react-native";
import { Text } from "react-native-paper";
import { LanguageContext } from "../common/language";
import { compareSceneIds } from "../common/scene";
import { PhraseFunction } from "../common/phrases";
import { sheetsCollection } from "../common/sheet";
import { AllDownloaded, EntryTree, EpisodeTree, SuffixTree } from "../data/backend/downloader";
import { formatDate, formatTime } from "../common/util";
import { stripQuery } from "../data/url";

function Sheet({ id, assets }: { id: string, assets: string[] }) {
  const { ph } = React.useContext(LanguageContext);
  const project = useProject();
  const [sheet, setSheet] = React.useState<DocumentSnapshot>();
  React.useEffect(
    () => {
      setSheet(undefined);
      if (!project) return;
      return sheetsCollection(project).doc(id).onSnapshot(setSheet);
    },
    [project, id]
  );
  const thumbnailCount = assets.filter(s => s.endsWith('_sm')).length;
  return (
    <React.Fragment>
      <Text variant="titleMedium">
        {sheet?.get('character') || sheet?.get('set')}
      </Text>
      <Text variant="bodyLarge">
        {thumbnailCount.toString() + ' ' + (ph('count-thumbnail') as PhraseFunction)(thumbnailCount)}
      </Text>
      <Text variant="bodyLarge">
        {(assets.length - thumbnailCount) + ' ' + ph('full-size')}
      </Text>
    </React.Fragment>
  );
}

function Entry({ id, entry }: { id: string, entry: EntryTree }) {
  const { ph } = React.useContext(LanguageContext);
  const dateStrings = Object.values(entry).map(r => r.cachedDate).sort().reverse();
  const latestDate = dateStrings.length ? new Date(dateStrings[0]) : undefined;
  if (!latestDate) return;
  return (
    <View style={{ flexDirection: "row", gap: 8, alignItems: "center" }}>
      <Text variant="bodyMedium">
        {formatDate(latestDate) + " " + formatTime(latestDate)}
      </Text>
      {
        id === 'scripts' ?
          <Text variant="titleMedium">{ph('script') as string}</Text> :
          <Sheet id={id}
            assets={Object.values(entry).map(r => stripQuery(r.cachedURI))}
          />
      }
    </View>
  )
}

function Scene({ label, sheets }: { label: string; sheets: SuffixTree }) {
  return (
    <View>
      <Text variant="headlineMedium">{label}</Text>
      {Object.keys(sheets || {})
        .map(sheet => <Entry key={sheet} id={sheet} entry={sheets[sheet]} />)}
    </View>
  );
}

function Season({ label, episodes }: { label: string; episodes: EpisodeTree }) {
  const { ph } = React.useContext(LanguageContext);
  return (
    <View style={{ gap: 32 }}>
      <View style={{ gap: 8 }}>
        <Text variant="headlineLarge">{ph('season') + ' ' + label}</Text>
        <View style={{ gap: 16 }}>
          {(Object.keys(episodes || {})).sort().reverse().flatMap(episode => {
            const scenes = episodes[episode];
            return Object.keys(scenes || {}).sort(compareSceneIds).map(scene => {
              const label = episode + '/' + scene;
              return <Scene key={label} label={label} sheets={scenes[scene]} />;
            })
          })}
        </View>
      </View>
    </View>
  );
}

function Downloads({ }) {
  const { ph } = React.useContext(LanguageContext);
  const project = useProject();
  const { downloads, downloader } = React.useContext(BackendContext);
  const [freeStorageDesc, setFreeStorageDesc] = React.useState<string>();
  const [all, setAll] = React.useState<AllDownloaded>();
  React.useEffect(() => {
    let cancelled = false;
    setAll(undefined);
    setFreeStorageDesc(undefined);
    downloader.allDownloaded().then(setAll).catch(err => console.error(err));
    downloader.getFreeStorageEstimate().then(bytes => {
      if (bytes !== undefined) {
        const units = [
          { suffix: "GB", inBytes: 1000000000 },
          { suffix: "MB", inBytes: 1000000 },
          { suffix: "kB", inBytes: 1000 },
        ];
        const { inBytes, suffix } = units.find(({ inBytes }) => bytes >= inBytes)!;
        const value = bytes / inBytes;
        setFreeStorageDesc(
          value.toFixed(1) + ' ' + suffix
        );
      }
    }
    );
    return () => { cancelled = true };
  }, [downloads, downloader]);
  const projectTree = all?.referenced['scenes'];
  const seasons = project && projectTree ? projectTree[project.id] : undefined;
  return (
    <View style={{ gap: 16 }}>
      {freeStorageDesc ?
        <Text variant="bodyLarge">
          {freeStorageDesc} {ph('free') as string}
        </Text> :
        null}
      {[...(Object.keys(seasons || {}))].sort().reverse().map(season =>
        <Season key={season} label={season} episodes={seasons![season]} />)}
      {
        all?.unreferenced?.length ?
          <View style={{ gap: 8 }}>
            <Text variant="headlineLarge">{ph('unknown') as string}</Text>
            <Text variant="bodyLarge">
              {
                all.unreferenced.length.toString() + ' ' +
                (ph('count-file') as PhraseFunction)(
                  all.unreferenced.length
                )
              }
            </Text>
          </View> : null
      }
    </View>
  );
}

export default function DownloadsScreen({ }: RootTabScreenProps<'downloads'>) {
  return (
    <NavPage>
      <Downloads />
    </NavPage>
  );
}
