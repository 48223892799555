import { DocumentSnapshot } from "@atgof-firebase/firebase";
import React, { ReactNode } from "react";
import { LanguageContext } from "../common/language";
import FieldsRow from "./fields/FieldsRow";
import { View } from "react-native";
import FixtureLabel from "../common/FixtureLabel";
import { FIELD_SPECS, SheetCategory } from "../common/sheet";


export function SheetSection({ title, children, small }: { title: string; children: ReactNode; small?: boolean }) {
  if (!children) return null;
  return (
    <View style={{ marginBottom: small ? 0 : 16 }}>
      <FixtureLabel style={{ marginBottom: small ? 0 : 4, fontSize: small ? 16 : 20 }} text={title} />
      {children}
    </View>
  );
}

export function SheetSections(
  { doc, category, readonly }: { doc: DocumentSnapshot | undefined; category: SheetCategory | undefined; readonly?: boolean }
) {
  const { ph } = React.useContext(LanguageContext);
  if (!(category && doc)) return null;
  const sections = FIELD_SPECS[category];
  const sectionKs = Object.keys(sections || {});
  const concise = readonly;
  return (
    <View>
      {concise && sectionKs.length == 1 ?
        <FieldsRow category={category} fields={sections![sectionKs[0]]} doc={doc} readonly={readonly} /> :
        sectionKs.map(section => (
          <SheetSection key={section} title={ph(section) as string} small={concise}>
            <FieldsRow category={category} fields={sections![section]} doc={doc} readonly={readonly} />
          </SheetSection>
        ))}
    </View>
  );
}
