import { DocumentReference, DocumentSnapshot, Query, QuerySnapshot, Timestamp } from "@atgof-firebase/types";
import { UNKNOWN_LABEL } from "./util";
import dayjs from "dayjs";
import { TimeOfDay } from "./phrases";
import { EpisodePath, parseEpisodePath } from "./episode";
import { OnNext, onSnapshot } from "./onSnapshot";
import { HasScripts } from "./has-scripts";
import { Team } from "./model/project/team";
import { notDeleted } from "./general";
import _ from "lodash";

export type SceneData = {
  id: string;
  bdsId: string;
  sheetNumber: string;
  synopsis: string;
  sequence: string;
  episodeId: string;
  baseSceneId?: string;
  isInterior: boolean;
  isExterior: boolean;
  timeOfDay?: TimeOfDay;
  sets: string[] | undefined;
  location: string;
  scriptDay: string;
  scriptPageNumbers: string;
  pages: string;
  unit: string;
  comment: string;
  cast: string[] | undefined;
  team?: string;
  filmingDate?: Date;
  orderInFilmingDate?: number;
}

export interface TimingData {
  pa?: number;
  rehearsal?: number;
  shot?: number;
  edit?: number
}

export interface Scene extends SceneData, HasScripts {
  ref: DocumentReference;
  teamRef?: DocumentReference;
  teamData?: Team;
  isComplete?: boolean;
  actions?: { isDone: boolean; description: string }[];
  continuousFilmingSequence?: DocumentReference[];
  extras?: string[]; // TODO This is never set
  timings?: TimingData;
  ignoreInTimings?: boolean;
  paNotes?: string;
  director?: string;
}

type ScenePath = EpisodePath & { scene?: string }

export function parseScenePath(path: string | undefined): ScenePath {
  // path is of the form ${EPISODE_PATH}/scenes/SCENE_ID
  const parts = path?.split('/');
  return {
    ...parseEpisodePath(path),
    scene: (parts && parts?.length > 7) ? parts[7] : undefined
  };
}


export function fullSceneIdentifier(episodeId: string | undefined, id: string | undefined) {
  if (!(episodeId && id)) return;
  return `${episodeId}_${id}`;
}

export function filmingDateIdentifier({ filmingDate }: SceneData) {
  if (!filmingDate) return;
  return dayjs(filmingDate).format('YYYY-MM-DD');
}

export function onScenesInContinuousFilmingSequence(
  sceneRef: DocumentReference | undefined,
  onNext: OnNext<DocumentReference[] | undefined>
) {
  return onSnapshot<DocumentSnapshot, DocumentReference[]>(
    onNext => sceneRef?.onSnapshot(onNext),
    scene => scene.get('continuousFilmingSequence') ?? [sceneRef],
    onNext
  );
}

export function getScenePath(scene: DocumentReference | undefined) {
  return parseScenePath(scene?.path);
}

export function compareSceneIds(a: string, b: string) {
  const [matchA, matchB] = [a, b].map(id => id.match(/^(\d+)(.*)$/));
  if (matchA && matchB) {
    const res = parseInt(matchA[1]) - parseInt(matchB[1]);
    if (res) return res;
    return matchA[2].localeCompare(matchB[2]);
  }
  if (matchA) return 1;
  if (matchB) return -1;
  return a.localeCompare(b);
}

export function compareScenes(
  a: DocumentSnapshot | DocumentReference | Scene,
  b: DocumentSnapshot | DocumentReference | Scene
) {
  return compareSceneIds(a.id, b.id);
}

export function sortScenes<T extends (DocumentSnapshot | DocumentReference)>(scenes: T[]) {
  return scenes.sort(compareScenes);
}

export function toScene(doc: DocumentSnapshot, teams?: DocumentSnapshot[]) {
  const data = doc.data();
  const teamRef = doc.get('team') as DocumentReference | undefined;
  const teamData = teams?.find(doc => doc.ref.path === teamRef?.path)?.data();
  const filmingDate = (doc.get('filmingDate') as Timestamp | undefined)?.toDate();
  return {
    ...data,
    id: doc.id,
    ref: doc.ref,
    path: doc.ref.path,
    team: teamRef?.id,
    teamRef,
    teamData,
    filmingDate
  } as Scene;
}

export function onScenes(
  sceneOrDayScenes: DocumentSnapshot[] | undefined,
  teams: DocumentSnapshot[] | undefined,
  onNext: (next: Scene[] | undefined) => void
): () => void {
  // TODO Refactor using onSnapshot.ts
  let unsubs: (() => void)[] = [];
  onNext(undefined);
  if (sceneOrDayScenes) {
    const vals = new Map<string, Scene>();
    function setVal(origDoc: DocumentSnapshot, doc: DocumentSnapshot) {
      vals.set(origDoc.ref.path, toScene(doc, teams));
      if (vals.size == sceneOrDayScenes?.length) {
        const next = sceneOrDayScenes?.map(({ ref }) => vals.get(ref.path));
        onNext(!next?.includes(undefined) ? (next as Scene[]) : undefined);
      }
    }
    for (const doc of sceneOrDayScenes) {
      const sceneRef: DocumentReference | undefined = doc.get('scene');
      if (sceneRef) unsubs.push(sceneRef.onSnapshot(d => setVal(doc, d)));
      else setVal(doc, doc);
    }
  }
  return () => {
    for (const unsub of unsubs) unsub();
  };
}

export function onScenesInParent(
  parent: DocumentReference,
  onNext: OnNext<Scene[] | undefined>,
  queryTransformer?: (q: Query) => Query
) {
  let q = notDeleted(parent.collection('scenes'));
  if (queryTransformer) q = queryTransformer(q);
  return onSnapshot(
    _.bind(q.onSnapshot, q),
    ({ docs }: QuerySnapshot) => sortScenes(docs).map(doc => toScene(doc)),
    onNext
  );
}

export function sceneLabel(path: string) {
  const m = path.match(/episodes\/([^/]+)\/scenes\/([^/]+)$/);
  return (m ? m[1] : UNKNOWN_LABEL) + '/' + (m ? m[2] : UNKNOWN_LABEL);
}
