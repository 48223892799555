import { useProject } from "./useProject";
import { DocumentReference, DocumentSnapshot } from "@atgof-firebase/firebase";
import { SheetCategory, onSheets } from "../common/sheet";
import { useSnapshot } from "./useSnapshot";
import { SubjectSpec } from "../common/subject";


export function useSheets(
  sceneRef: DocumentReference | undefined,
  categories: SheetCategory[],
  subject?: SubjectSpec | undefined
) {
  const project = useProject();
  return useSnapshot<DocumentSnapshot[] | undefined>(
    onNext => onSheets(
      project, sceneRef ? [sceneRef] : undefined, categories, subject, onNext
    ),
    [
      project, sceneRef?.path,
      categories.sort().join('\n'),
      subject?.subjectKind, subject?.subjectName
    ]
  );
}
