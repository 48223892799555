export const UPLOAD_STATUS_HEADER = "X-Atgof-Upload-Status";

// Message types posted by the Service Worker
export const CACHE_DID_UPDATE = 'CACHE_DID_UPDATE';
export const UPLOAD_DID_START = 'UPLOAD_DID_START';
export const UPLOAD_DID_SUCCEED = 'UPLOAD_DID_SUCCEED';
export const UPLOAD_DID_FAIL = 'UPLOAD_DID_FAIL';

// Message types posted to the Service Worker
export const GET_VERSION = 'GET_VERSION';
export const GET_UPLOAD_STATUSES = 'GET_UPLOAD_STATUSES';

