import { ReactNode } from "react";
import { View, ViewStyle } from "react-native";
import { Surface, useTheme } from "react-native-paper";

export default function HeaderStrip(
  { children, style, mode = 'default' }:
    {
      children: ReactNode;
      style?: ViewStyle;
      mode?: 'default' | 'prompt' | 'error'
    }) {
  const theme = useTheme();
  return (
    <View style={{ zIndex: 2, position: "absolute", top: 3, left: 0, right: 0, margin: "auto", gap: 8, alignItems: "center", justifyContent: "center" }}>
      <Surface
        elevation={4}
        style={{
          backgroundColor: mode === 'error' ?
            theme.colors.errorContainer :
            (mode === 'prompt' ? theme.colors.primaryContainer : theme.colors.background),
          ...(style ?? {})
        }}>
        {children}
      </Surface>
    </View>
  );
}
