import { LanguageContext } from "../common/language";
import { UserContext } from "../data/userContext";
import { Button } from "react-native-paper";
import React from "react";

export default function LogOutButton() {
  const { ph } = React.useContext(LanguageContext);
  const { logOut } = React.useContext(UserContext);
  return <Button onPress={logOut}>{ph('log-out') as string}</Button>;
}
