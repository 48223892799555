import {
  CollectionReference, DocumentReference, DocumentSnapshot, Query
} from "@atgof-firebase/types";
import { PhraseKey } from "../phrases";

export type FieldAttr = string | {
  key?: string;
  labelKey?: PhraseKey;
  labelSuffix?: string
}

export type ReferenceOptions = Query | (DocumentReference | undefined)[]

export type OptionsGetter = (project: DocumentReference, doc: DocumentSnapshot) => ReferenceOptions

export type ReferenceSpec = {
  collection: string;
}

export type MultiSpecExtra =
  (navigation: any /* TODO */, doc: DocumentSnapshot, item: any, index: number) => JSX.Element

export type MultiSpecOnAdd =
  (navigation: any /* TODO */, doc: DocumentSnapshot, item: any, index: number) => void;

export type MultiSpec = {
  collection?: (parentRef: DocumentReference) => { ref: CollectionReference; addToQuery?: (q: Query) => Query, indexField: string };
  numbering?: (docOrValue: any /* TODO */, index: number) => string;
  explicitAdd?: { data?: object, extra?: { icon: string, data: object } };
  onAdd?: MultiSpecOnAdd;
  extra?: MultiSpecExtra;
}

export type OnValueChange =
  (newValue: any, oldValue: any, doc: DocumentSnapshot | undefined) => void

export type Field = FieldAttr & {
  kind?: 'text' | 'shortText' | 'longText' | 'paragraph' | 'date' |
  { reference: ReferenceSpec } | { composite: Field[] } | { select: FieldAttr[] } | { stringSelect: string[] } | { flags: FieldAttr[] };
  isTitle?: boolean;
  multi?: MultiSpec;
  tableColumn?: string;
  onChange?: OnValueChange
}

export function normaliseAttrs(attrs: FieldAttr[]) {
  return attrs.map(field => typeof field === 'string' ? { key: field } : field);
}

export function defaultValue(field: Field) {
  if (field.kind && typeof field.kind !== 'string' && 'composite' in field.kind) return {};
  return undefined;   // TODO This will be an error
}

export function keysForField(field: Field, category: string | undefined) {
  const k = 'key' in field ? ((category ? category + ':' : '') + field.key) : undefined;
  const labelK = 'labelKey' in field ? field.labelKey : k;
  return { k, labelK };
}
