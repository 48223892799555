import { DocumentSnapshot } from '@atgof-firebase/firebase';
import { onScenes } from '../common/scene';
import { Scene } from '../common/scene';

type DayWithScenes = {
  day: DocumentSnapshot,
  scenes: Scene[] | undefined
}

export function onDaysWithScenes(
  days: DocumentSnapshot[] | undefined,
  teams: DocumentSnapshot[] | undefined,
  onNext: (next: (DayWithScenes[] | undefined)) => void
): () => void {
  let unsubs: (() => void)[] = [];
  const innerUnsubs = new Map<number, () => void>();
  onNext(undefined);
  if (days && teams?.length) {
    const value: DayWithScenes[] = days.map(day => ({ day, scenes: undefined }));
    onNext(value);
    for (let i = 0; i < value.length; i++) {
      unsubs.push(
        value[i].day.ref.collection('scenes')
          .where('team', 'in', teams.map(({ ref }) => ref))
          .where('deleted', '==', false)
          .orderBy('orderInFilmingDate', 'asc')
          .onSnapshot(({ docs }) => {
            const oldUnsub = innerUnsubs.get(i);
            if (oldUnsub) oldUnsub();
            innerUnsubs.set(i, onScenes(docs, teams, scenes => {
              value[i].scenes = scenes;
              onNext(value)
            }));
          })
      );
    }
  }
  return () => {
    for (const unsub of innerUnsubs.values()) unsub();
    for (const unsub of unsubs) unsub();
  };
}
