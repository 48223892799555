import dayjs from "dayjs";
import React from "react";

export default function DayPicker({ day, setDay }: { day: dayjs.Dayjs; setDay: (d: dayjs.Dayjs) => void }) {
  return (
    <input type="date"
      value={day.format('YYYY-MM-DD')}
      onChange={evt => setDay(dayjs(evt.target.value))}
    />
  );

}

