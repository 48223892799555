import { canonicalMonthName, languages, PhraseKey, phrases, TIME_OF_DAY_PHRASE_KEYS, TimeOfDay } from "./phrases";
import { SceneData } from "./scene";
import { SheetSubjectKind } from "./sheet-subject";
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { ScriptElement } from "./script";
import { FDXScriptElement, FDXScriptText } from "./formats/fdx";

type ScriptTextAnnotation = any; // TODO

dayjs.extend(customParseFormat);

type BreakdownSheet = {
  "BDSID": string;
  "Scenes": string;
  "SheetNumber": string;
  "Synopsis": string;
  "Sequence": string;
  "IE": string;
  "DN": string;
  "Set": string;
  "Location": string;
  "ScriptDay": string;
  "ScriptPageNumbers": string;
  "NumScriptPages": string;
  "Unit": string;
  "EstimateTimeB": string;
  "EstimateTimeA": string;
  "Comments": string;
  "Cast Members"?: string[]
}

type CalendarExceptions = {
  specialDays: {
    date: string;
    off: boolean;
    companyTravel: boolean;
    holiday: boolean;
    exceptionWorkday: boolean
  }[],
  daysOff: boolean[]
};

type Calendar = {
  displayName: string;
  prepStartDate: string;
  startDate: string;
  endDate: string;
  wrapDate: string;
} & CalendarExceptions

type CalendarEntry = string | BreakdownSheet

type StripBoardContents = {
  days: CalendarEntry[][];
  remaining: CalendarEntry[]
}

type StripBoard = {
  displayName: string;
  calendar: Calendar;
  description: string;
  scriptVersion: string;
  scheduled: StripBoardContents;
  unscheduled: StripBoardContents
}

type StripBoards = {
  activeStripBoard: string;
  stripBoards: Record<string, StripBoard>
}

export type Modification = {
  bdsIds: string[];
  subjectKind: SheetSubjectKind;
  originalName: string;
  newName: string;
}

export type Modifications = Record<SheetSubjectKind, Modification[]>

function possiblePhrasesFor(...phraseKeys: PhraseKey[]) {
  const prefixes = new Set<string>();
  for (const k of phraseKeys) {
    for (const phrase of Object.values(phrases[k]) as string[]) {
      prefixes.add(phrase.toLocaleUpperCase());
    }
  }
  return [...prefixes];
}

const seasonPhrases = possiblePhrasesFor('season', 'series');
export const episodePhrases = possiblePhrasesFor('episode', 'ep');
const txPhrases = possiblePhrasesFor('tx', 'tx-date');
const endPhrases = possiblePhrasesFor('end');
const scenePhrases = possiblePhrasesFor('scene', 'teaser');
const interiorPhrases = possiblePhrasesFor('interior', 'int').map(s => s.replace(/\./g, ''));
const exteriorPhrases = possiblePhrasesFor('exterior', 'ext').map(s => s.replace(/\./g, ''));
const todPhraseKinds = TIME_OF_DAY_PHRASE_KEYS.map(k => ({ k: k, phrases: possiblePhrasesFor(k) }));
const dayOrdinalPhrases = possiblePhrasesFor('day-ordinal');

function escapeForRegExp(s: string) {
  return s.replace(/[|\\{}()[\]^$+*?.]/g, '\\$&').replace(/-/g, '\\x2d');
}

function extractPhrase(phraseAlternatives: string[], text: string) {
  const s = text.trim();
  for (const phrase of phraseAlternatives) {
    const m = s.match(
      new RegExp("(^|\\W+)" + escapeForRegExp(phrase) + "(\\W+|$)", 'i')
    );
    if (m?.index !== undefined) {
      return {
        wasFound: true,
        before: s.substring(0, m.index),
        phrase,
        after: s.substring(m.index + m[0].length)
      };
    }
  }
  return { wasFound: false, before: s, after: '' };
}

export function extractPrefixed(possiblePrefixes: string[], displayName: string, anywhere: boolean = false) {
  // TODO Use extractPhrase here
  const name = displayName?.trim();
  for (const prefix of possiblePrefixes) {
    const rest =
      name?.match(
        new RegExp((anywhere ? "(^|\\W)" : "^") + escapeForRegExp(prefix) + "[\\s\\.:]*(?<rest>.*)$", 'i')
      )?.groups?.rest;
    if (rest) return rest;
  }
  return;
}

type BannerDate = {
  date: dayjs.Dayjs;
  suffix?: string
}

function parseBannerDate(
  prevDate: dayjs.Dayjs, currDate: dayjs.Dayjs, s: string,
  { specialDays, daysOff }: CalendarExceptions
): BannerDate | undefined {
  function parse(re: RegExp) {
    // TODO Return undefined if dayjs returned invalid date
    const m = s.match(re);
    if (m) {
      const fields = m.groups!;
      for (const { lang, months } of languages) {
        const localeData = dayjs().locale(lang).localeData();
        if ('weekday' in fields && !localeData.weekdays().map(s => s.toLocaleUpperCase())
          .includes(fields['weekday'].toLocaleUpperCase())) {
          continue;
        }
        if ('year' in fields && fields['year'] !== undefined) {
          if ('monthNumber' in fields) {
            return { date: dayjs(`20${fields['year']}-${fields['monthNumber']}-${fields['day']}`) };
          }
          if ('month' in fields) {
            let month = canonicalMonthName(lang, fields['month']);
            for (const months of [localeData.months(), localeData.monthsShort()]) { // TODO This is because dayjs is case-sensitive. Factor this out as a dayjs patch
              for (const m of months) {
                if (month === m.toLocaleLowerCase()) month = m;
              }
            }
            return {
              date: dayjs(
                [fields['day'], month, fields['year']].join(' '),
                [
                  'D MMMM YYYY', // TODO All 2^3 combinations of D|DD MMM|MMMM YY|YYYY
                ],
                lang)
            };
          }
        }
        else {
          let d: dayjs.Dayjs | undefined = dayjs(prevDate);
          if ('month' in fields && fields['month'] !== undefined) {
            const i = months.map(s => s.toLocaleLowerCase()).indexOf(canonicalMonthName(lang, fields['month']));
            if (i != -1) d = d.month(i);
            else d = undefined;
          }
          if (d) {
            d = d.date(parseInt(fields['day'])).hour(12).minute(0).second(0).millisecond(0);
            for (const yearOffset of [0, 1, -1]) {
              const possibleD = d.add(yearOffset, 'year');
              if (possibleD.locale(lang).format('dddd').toLocaleUpperCase() === fields['weekday'].toLocaleUpperCase()) {
                d = possibleD;
                break;
              }
            }
            return {
              date: d,
              suffix: fields['suffix']
            };
          }
        }
      }
    }
    return;
  }
  console.debug("Banner", s);
  const res =
    parse(/^(?<weekday>[\w\s]+)\W?(\W+|\s+)(?<day>\d+)\S*\s+((o|O)\s+)?(?<month>\S+)\s?(?<suffix>.*)$/) ||
    parse(/^(?<weekday>[\w\s]+)\W?(\W+|\s+)(?<month>\S+)\s+(?<day>\d+)\S*\s?(?<suffix>.*)$/) ||
    parse(/(?<weekday>\w+)\W?(\W+|\s+)(?<day>\d+)[\/\.](?<monthNumber>\d+)[\/\.](?<year>\d+)/) ||
    parse(/(?<weekday>\w+)\s+(?<day>\d+)[^\d\s]*\s+(?<month>\w+)\s+(?<year>\d{4})?/) ||
    parse(/^(?<weekday>^\D+)\W?(\W+|\s+)(?<day>\d+)\S*\s+(?<suffix>.*)$/);
  if (res) return res;
  console.debug("(Not a date)");
  return;
}

export function processIdentifier(identifier: string) {
  return (identifier.match(/^\d\D*$/) ? // TODO This assumes double digits
    ('0' + identifier) : identifier
  ).replace(/\s/g, '').toLocaleUpperCase();
}

function processIntExt(intExt: string) {
  const parts = intExt.replace(/\./g, '').split(/\W/).map(s => s.trim().toLocaleUpperCase());
  return {
    isInterior: parts.findIndex(part => interiorPhrases.indexOf(part) != -1) != -1,
    isExterior: parts.findIndex(part => exteriorPhrases.indexOf(part) != -1) != -1
  };
}

function processTimeOfDay(timeOfDay: string) {
  const s = timeOfDay.replace(/\W/g, '');
  const phraseKind = todPhraseKinds.find(({ phrases }) => phrases.indexOf(s) != -1);
  if (!phraseKind) return;
  return phraseKind.k;
}

function processSubjects(subjects: string[], modifications: Modification[]) {
  return subjects.map(s => {
    let displayName = s.toLocaleUpperCase();
    const separatorI = displayName.indexOf(' - ');
    if (separatorI != -1) displayName = s.substring(0, separatorI); // TODO PyC specific, but extract actor name in that case
    const annotationSeparatorI = displayName.indexOf(' *');
    if (annotationSeparatorI != -1) displayName = s.substring(0, annotationSeparatorI); // TODO PyC specific, but extract scene notes in that case, in particular *OMMITTED*
    let modification;
    while (modification = modifications.find(({ originalName }) => originalName === displayName)) {
      // TODO Break out of this if there's a cycle of originalName -> displayName -> originalName
      displayName = modification.newName.toLocaleUpperCase();
    }
    return displayName;
  });
}

function sceneSuffix(sheet: BreakdownSheet) {
  const m = sheet.Sequence.match(/^[A-Z]$/); // TODO (/^([A-Z])(\s.*$|$)/);
  return m ? m[0] : '';
}

function sceneForBreakdownSheet(sheet: BreakdownSheet, modifications: Modifications): SceneData | undefined {
  const [setModifications, characterModifications] = (['set', 'character'] as SheetSubjectKind[]).map(
    kind => (modifications[kind] || []).filter(
      ({ bdsIds }) => bdsIds.length == 0 || bdsIds.indexOf(sheet.BDSID) != -1
    ));
  const m = sheet.Scenes.match(/(?<episodeId>\w+)\s*\/+\s*(?<sceneId>\w+)/);
  if (!m?.groups) {
    const v = sheet.Scenes.trim();
    if (v.length && !v.match(/^\d+$/)) throw new Error(`Invalid Scenes value ${sheet.Scenes}`);
    return;
  }
  const sceneIdentifier = processIdentifier(m.groups.sceneId);
  const timeOfDay = processTimeOfDay(sheet.DN);
  return {
    id: sceneIdentifier + sceneSuffix(sheet),
    bdsId: sheet.BDSID,
    sheetNumber: sheet.SheetNumber,
    synopsis: sheet.Synopsis,
    sequence: sheet.Sequence,
    episodeId: processIdentifier(m.groups.episodeId),
    baseSceneId: sceneIdentifier,
    ...processIntExt(sheet.IE),
    ...(timeOfDay ? { timeOfDay: timeOfDay } : {}),
    sets: processSubjects([sheet.Set], setModifications),
    location: sheet.Location,
    scriptDay: sheet.ScriptDay,
    scriptPageNumbers: sheet.ScriptPageNumbers,
    pages: sheet.NumScriptPages,
    unit: sheet.Unit,
    comment: sheet.Comments,
    cast: processSubjects(sheet["Cast Members"] || [], characterModifications),
  };
}

type EpisodeMetadata = { director: string, txDate?: Date }
type Episodes = Record<string, EpisodeMetadata>
type EpisodesAndScenes = {
  scenes: SceneData[];
  episodes: Episodes
}

function canonicalDateString(d: any) {
  return dayjs(d).format('YYYY-MM-DD');
}

function incorporateStripBoard(acc: EpisodesAndScenes, stripBoard: StripBoard, modifications: Modifications) {
  const { calendar, scheduled, unscheduled } = stripBoard;
  const { startDate, specialDays, daysOff } = calendar;
  const extraWorkdays = specialDays.filter(specialDay => specialDay.exceptionWorkday).map(({ date }) => canonicalDateString(date));
  const holidays = specialDays.filter(({ holiday, off }) => holiday || off).map(({ date }) => canonicalDateString(date));
  let currDate = dayjs(startDate);
  function processEntries(entries: CalendarEntry[]) {
    let currentBannerDate;
    let indexWithinDate = 0;
    for (const entry of entries) {
      if (typeof entry === 'string') {
        let bannerDate: BannerDate | undefined;
        if (entry.toLocaleLowerCase().includes("{shooting date}")) {
          bannerDate = { date: currDate };
          let s;
          do {
            currDate = currDate.add(1, 'days');
            s = canonicalDateString(currDate);
          } while (
            (daysOff[currDate.day()] && !extraWorkdays.includes(s)) ||
            holidays.includes(s)
          );
        }
        else {
          bannerDate = parseBannerDate(
            currentBannerDate?.date ?? dayjs(startDate),
            currDate,
            entry,
            { specialDays, daysOff }
          );
        }
        if (bannerDate) {
          currentBannerDate = bannerDate;
          indexWithinDate = 0;
          continue;
        }
        const episodeMetadata = extractPrefixed(episodePhrases, entry);
        if (episodeMetadata) {
          currentBannerDate = undefined;
          const episodeM = episodeMetadata.match(/^(\d+)\W+(.*)$/);
          if (episodeM) {
            const episodeId = processIdentifier(episodeM[1]);
            const txAndRemainder = extractPrefixed(txPhrases, episodeM[2]);
            const m = txAndRemainder?.match(/^(\d+\W\d+\W\d+)\W+(.*)$/); // TODO This is RaR-specific
            if (m) {
              const date = dayjs(m[1], ["DD.MM.YY", "DD.M.YY", "D.MM.YY", "D.M.YY"], "en-gb"); // TODO RaR-specific
              const director = m[2];
              let episodeMetadata: EpisodeMetadata = { director };
              if (date.isValid()) episodeMetadata.txDate = date.toDate();
              else console.warn(`Invalid TX date ${m[1]} for episode ${episodeId}`);
              acc.episodes[episodeId] = episodeMetadata;
            }
          }
          continue;
        }
        const end = extractPrefixed(endPhrases, entry);
        if (end !== undefined) {
          currentBannerDate = undefined;
          continue;
        }
      }
      else {
        console.debug("Scenes", entry.Scenes);
        if (entry.Scenes.length > 0) {
          const teamMatch = currentBannerDate?.suffix?.match(/[A-Za-z]+/); // TODO This is RaR-specific
          let team = teamMatch ? teamMatch[0].toLocaleUpperCase() : undefined;
          const possibleTeams = ['GLAS', 'MELYN', 'A', 'B']; // TODO Do this properly
          if (team && !possibleTeams.includes(team)) team = undefined; //
          const scene = sceneForBreakdownSheet(entry, modifications);
          if (scene) acc.scenes.push({
            ...scene,
            ...(
              currentBannerDate ?
                {
                  filmingDate: currentBannerDate.date.toDate(),
                  orderInFilmingDate: indexWithinDate++,
                  team
                } : {}
            )
          });
        }
        else { } // TODO Warn about this
      }
    }
  }
  function processContents(contents: StripBoardContents) {
    const { days, remaining } = contents;
    for (const day of days) processEntries(day);
    processEntries(remaining);
  }
  processContents(scheduled);
  processContents(unscheduled);
  return acc;
}

export type ProcessedSeason = {
  id: string | undefined;
  prepStartDate?: Date;
  startDate?: Date;
  endDate?: Date;
  wrapDate?: Date;
} & EpisodesAndScenes

export function processStripBoards(data: StripBoards, modifications: Modifications): ProcessedSeason[] {
  const { activeStripBoard, stripBoards } = data;
  const mainStripBoard: StripBoard | undefined = stripBoards[activeStripBoard];
  const otherStripBoards =
    Object.keys(data.stripBoards).filter(k => k !== activeStripBoard).map(k => stripBoards[k]);
  const calendar: Calendar | undefined = mainStripBoard?.calendar;
  const startDate = calendar ? new Date(calendar.startDate) : undefined;
  const { episodes, scenes } = [...otherStripBoards, mainStripBoard].reduce(
    (acc, stripBoard) => incorporateStripBoard(acc, stripBoard, modifications),
    { episodes: {}, scenes: [] } as EpisodesAndScenes);
  return [{
    id: extractPrefixed(seasonPhrases, mainStripBoard.displayName),
    ...(
      calendar ?
        {
          prepStartDate: new Date(calendar.prepStartDate),
          startDate,
          endDate: new Date(calendar.endDate),
          wrapDate: new Date(calendar.wrapDate)
        } :
        {}
    ),
    episodes,
    scenes
  }];
}

export function subjectsForSeasons(seasons: ProcessedSeason[]) {
  let res: Record<SheetSubjectKind, Record<string, string[]>> = {
    character: {}, set: {}
  };
  for (const { scenes } of seasons) {
    for (const { episodeId, id, sets, cast } of scenes) {
      for (
        const [kind, subjects] of
        [['character', cast], ['set', sets]] as [SheetSubjectKind, string[]][]
      ) {
        for (const subject of subjects)
          res[kind][subject] = [...(res[kind][subject] || []), episodeId + '/' + id];
      }
    }
  }
  return res;
}

export function modificationsFromSnapshot(snapshot: any) {
  return snapshot.docs.reduce(
    (res: Modifications, doc: any) => {
      const modification = doc.data();
      const kind = modification.subjectKind as SheetSubjectKind;
      return { ...res, [kind]: [...(res[kind] || []), modification] };
    },
    {} as Modifications
  );
}

function extractId(possiblePrefixes: string[], text: string, anywhere: boolean = false) {
  const suffix = extractPrefixed(possiblePrefixes, text, anywhere);
  if (!suffix) return;
  const m = suffix.match(/^(\S+?)(\W+|$)/);
  if (m) {
    const textRemainder = suffix.substring(m[0].length);
    return {
      result: processIdentifier(m[1]).toLocaleUpperCase(),
      remainder: textRemainder.length ? [{ text: textRemainder }] : []
    };
  }
  return;
}

function flattenParagraphText(paragraph: FDXScriptText[]) {
  return paragraph.map(({ text }) => text).join('');
}

function extractIdFromParagraph(
  possiblePrefixes: string[], paragraph: FDXScriptText[], anywhere: boolean = false
) {
  const text = flattenParagraphText(paragraph);
  for (const line of text.split('\n')) {
    const id = extractId(possiblePrefixes, line, anywhere);
    if (id) return id;
  }
  return;
}

function extractTimeOfDay(s: string): { before: string, timeOfDay: TimeOfDay | undefined } {
  for (const k of TIME_OF_DAY_PHRASE_KEYS) {
    const { wasFound, before } = extractPhrase(Object.values(phrases[k]) as string[], s);
    if (wasFound) return { before, timeOfDay: k };
  }
  return { before: s, timeOfDay: undefined };
}

export type ScriptScene = { // TODO Look into merging this with SceneData
  id: string | undefined;
  episodeId: string | undefined;
  content: ScriptElement[];
  textAnnotations: ScriptTextAnnotation[];
  location?: string;
  isInterior?: boolean;
  isExterior?: boolean;
  timeOfDay?: TimeOfDay;
  cast?: string[];
  scriptDay?: string;
  dialogueLines: number
}

function addCastMembers(
  scene: ScriptScene, ...castMembers: Omit<ScriptTextAnnotation, 'color'>[]
): ScriptScene {
  const cast = new Set(scene.cast ?? []);
  for (const s of castMembers) cast.add(s.text.trim().toLocaleUpperCase());
  return {
    ...scene,
    cast: [...cast],
    textAnnotations: [
      ...scene.textAnnotations,
      ...castMembers.map(m => ({ ...m, color: '#00ffff' }))
    ]
  };
}

function incorporateSceneMetadata(
  scene: ScriptScene, contentEntry: FDXScriptElement, paragraph: number | undefined
) {
  if (!('kind' in contentEntry)) return scene;
  const { kind } = contentEntry;
  const textElements = contentEntry.paragraph;
  if (kind === 'Character' && textElements.length && paragraph !== undefined) {
    const { text } = textElements[0];
    return addCastMembers(scene, {
      paragraph,
      element: 0,
      text,
      range: { start: 0, length: text.length }
    });
  }
  if (kind === 'Action' && paragraph !== undefined) {
    return addCastMembers(
      scene,
      ...textElements.flatMap(({ text }, i) =>
        [...text.matchAll(/(?<before>^|\s)(?<word>([A-Z]+(\s[A-Z]+)?)+)(?<after>\W|$)/g)]
          .map(m => {
            const { before, word } = m.groups!!;
            return {
              paragraph,
              element: i,
              text: word,
              range: {
                start: m.index + before.length, length: word.length
              }
            };
          })
      )
    );
  }
  if (kind === 'Scene Heading') {
    const text = flattenParagraphText(textElements);
    const interior = extractPhrase(interiorPhrases, text);
    const exterior =
      extractPhrase(exteriorPhrases, interior.wasFound ? interior.after : interior.before);
    const dayOrdinal =
      extractPhrase(dayOrdinalPhrases, exterior.wasFound ? exterior.after : exterior.before);
    const dayOrdinalMatch = dayOrdinal.wasFound ? dayOrdinal.after.match(/^(?<day>\d+)(?<remainder>.*)$/) : undefined;
    const remainder = dayOrdinal.wasFound ?
      (dayOrdinalMatch ? dayOrdinalMatch.groups!!.remainder : dayOrdinal.after) :
      dayOrdinal.before;
    const { timeOfDay, before } = extractTimeOfDay(remainder);
    return {
      ...scene,
      isInterior: interior.wasFound,
      isExterior: exterior.wasFound,
      scriptDay: dayOrdinalMatch ? dayOrdinalMatch.groups!!.day : undefined,
      location: (dayOrdinal.wasFound ? dayOrdinal.before : before).toLocaleUpperCase(),
      timeOfDay
    }
  }
  return scene;
}


export function processScript(script: FDXScriptElement[], episodeId?: string | undefined, sceneId?: string | undefined) {
  let dialogueLines = 0;
  let scenes: ScriptScene[] = [];
  function emptyScene(id: string | undefined): ScriptScene {
    return { id, episodeId, content: [], textAnnotations: [], dialogueLines: 0 };
  }
  let scene = emptyScene(sceneId);
  let started = false;
  let manualSceneNumbering = false;
  function pushScene(nextSceneId?: string | undefined) {
    scenes.push({ ...scene, id: processIdentifier(scene.id ?? (scenes.length + 1).toString()) });
    scene = emptyScene(nextSceneId);
  }
  for (const contentEntry of script) {
    let dialogueLineNumber: number | undefined = undefined;
    const kind = 'kind' in contentEntry ? contentEntry.kind : undefined;
    const paragraph = 'paragraph' in contentEntry ? contentEntry.paragraph : undefined;
    if (kind === 'General' && paragraph) {
      episodeId = episodeId ?? extractIdFromParagraph(episodePhrases, paragraph)?.result;
    }
    else if (kind === 'Penawd' && paragraph) { // TODO This is specific to RaR
      const episodeMatch = extractIdFromParagraph(episodePhrases, paragraph);
      episodeId = episodeId || episodeMatch?.result;
      const sceneMatch =
        extractIdFromParagraph(scenePhrases, episodeMatch ? episodeMatch.remainder : paragraph, true);
      if (sceneMatch) {
        started = true;
        manualSceneNumbering = true;
        if (scene.id) pushScene(sceneMatch.result);
        else scene.id = sceneMatch.result;
      }
    }
    else if (!manualSceneNumbering && kind === 'Scene Heading' && 'paragraph' in contentEntry) {
      started = true;
      if (scene.content.length) pushScene(contentEntry.number);
      else scene.id = contentEntry.number;
    }
    else if (kind === 'Dialogue' && !contentEntry.dualCharacter) {
      if (scene && paragraph?.map((elt: any) => 'text' in elt ? elt.text : '').join('').trim().length) {
        dialogueLines++;
        scene.dialogueLines++;
        dialogueLineNumber = scene.dialogueLines;
      }
    }

    scene = incorporateSceneMetadata(
      scene, contentEntry, started ? scene.content.length : undefined)
      ;
    if (started) scene.content.push({
      ...contentEntry,
      ...(dialogueLineNumber === undefined ? {} : { dialogueLineNumber })
    });
  }
  if (scene.content.length) pushScene();
  return {
    episodeId: episodeId,
    dialogueLines: dialogueLines,
    scenes: scenes
  };
}
