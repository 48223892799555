import { z } from 'zod';
import { deletable } from '../../deletable';
import { timestamp } from '../../timestamp';

const paragraphIndex = z.number();

const scriptLoc = z.union([
  paragraphIndex,
  z.object({
    sceneId: z.string(),
    paragraphIndex,
    yPos: z.number().optional()
  })
]);

export type ScriptLoc = z.infer<typeof scriptLoc>;

export const takeSchema = deletable.extend({
  index: z.number(),
  startDate: timestamp.optional(),
  endDate: timestamp.optional(),
  timecode: z.string().optional(),
  startScriptLoc: scriptLoc.optional(),
  endScriptLoc: scriptLoc.optional(),
  xPos: z.number().optional(),
  length: z.string().optional(),
  details: z.string().optional(),
  preferred: z.boolean().optional(),
  isPickup: z.boolean().optional()
});

export type Take = z.infer<typeof takeSchema>;
