import { ViewStyle } from "react-native";
import { View } from "react-native";
import { ProgressBar, Text } from "react-native-paper";
import { LanguageContext } from "../common/language";
import React from "react";

export default function LoadingView({ style }: { style?: ViewStyle }) {
  const { ph } = React.useContext(LanguageContext);
  return (
    <View style={{ ...(style || {}), gap: 8 }}>
      <Text variant="bodyLarge">{ph('loading') as string}</Text>
      <ProgressBar indeterminate />
    </View>
  );
}
