import React from "react";
import { Picker } from "@react-native-picker/picker";
import { LanguageContext } from "../common/language";
import { TextInput, useTheme } from "react-native-paper";
import { TextInput as RNTextInput, View } from "react-native";

export type SelectConfigProps = {
  placeholder?: string;
  autoselect?: boolean;
  highlightUnselected?: boolean;
  includeNone?: boolean;
  resetSelectionIfNotPresent?: boolean;
}

export type SelectProps<T> = {
  entries: T[] | undefined;
  entryKey: (entry: T) => string;
  entryLabel: (entry: T) => string;
  onEntryChange: (entry: T | undefined) => void
  selectedEntry?: T | undefined;
  other?: {
    placeholder?: string;
    transform: (enteredValue: string) => T | undefined
  }
} & SelectConfigProps

export default function Select<T>(
  {
    entries, entryKey, entryLabel, selectedEntry, placeholder,
    onEntryChange, autoselect, highlightUnselected = true, includeNone,
    resetSelectionIfNotPresent = true, other,
    ...props
  }:
    SelectProps<T>
) {
  const { colors } = useTheme();
  const { ph } = React.useContext(LanguageContext);
  const textInput = React.useRef<RNTextInput | null>(null);
  const [otherValue, setOtherValue] = React.useState("");
  const [isOtherSelected, setIsOtherSelected] = React.useState(false);
  function onValueChange(k: string) {
    if (other && k === '_other') {
      setIsOtherSelected(true);
      onEntryChange(undefined);
    }
    else {
      setIsOtherSelected(false);
      setOtherValue("");
      onEntryChange(entries?.find(entry => entryKey(entry) === k));
    }
  }
  React.useEffect(() => {
    if (isOtherSelected) textInput.current?.focus();
  }, [isOtherSelected]);
  React.useEffect(() => {
    if (resetSelectionIfNotPresent && entries && selectedEntry &&
      !entries.find(entry => entryKey(entry) === entryKey(selectedEntry))) {
      onEntryChange(undefined);
    }
  }, [entries, entryKey, selectedEntry, onEntryChange, resetSelectionIfNotPresent]);
  React.useEffect(() => {
    if (autoselect && !selectedEntry && entries && entries.length === 1) onEntryChange(entries[0]);
  }, [autoselect, selectedEntry, entries]);
  const placeholderText = entries ?
    (includeNone ? `( ${ph('none')} )` : (placeholder || ph('choose...') as string)) :
    ph('loading') as string;
  const selectedValue = isOtherSelected ? "_other" :
    (selectedEntry && entryKey(selectedEntry)) || '';
  const noSelection = selectedValue === '';
  const highlight = highlightUnselected && entries && noSelection;
  const color = highlight ? colors.primary : colors.onBackground;
  const allEntries = (
    selectedEntry && !entries?.map(entryKey).includes(entryKey(selectedEntry)) ?
      [selectedEntry, ...(entries ?? [])] : (entries ?? [])
  );
  return (
    <View style={{ gap: 4 }}>
      <Picker placeholder={placeholderText}
        itemStyle={{
          color
        }}
        style={{
          minWidth: 200,
          borderBottomWidth: 1,
          borderColor: colors.outline,
          backgroundColor: highlight ? colors.primaryContainer : colors.background,
          color
        }}
        selectedValue={selectedValue}
        onValueChange={onValueChange}
        {...props}
      >
        {[
          ...(noSelection || includeNone ?
            [<Picker.Item key="_placeholder" value="" label={placeholderText} />] : []),
          ...allEntries.map(entry => {
            const key = entryKey(entry);
            return <Picker.Item key={key} value={key || ''} label={entryLabel(entry)} />;
          }),
          ...(other ?
            [<Picker.Item key="_other" value="_other" label={ph('other') as string} />]
            : [])]}
      </Picker>
      {isOtherSelected &&
        <TextInput
          ref={textInput}
          value={otherValue}
          placeholder={other?.placeholder}
          onChangeText={value => {
            setOtherValue(value);
            if (other) onEntryChange(value.length ? other.transform(value) : undefined);
          }}
        />}
    </View>
  );
}
