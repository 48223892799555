import React from "react";
import { ImageURISource, View } from "react-native";
import { IconButton, Text, useTheme } from "react-native-paper";

export type ImageViewHeaderProps = {
  images: (ImageURISource & { id: string })[];
  imageIndex: number;
  setImageIndex: React.Dispatch<React.SetStateAction<number | undefined>>;
  onRequestClose: () => void;
  deleteImage: (id: string) => void;
  dark?: boolean
}

export function ImageViewHeader({ images, imageIndex, setImageIndex, onRequestClose, deleteImage }: ImageViewHeaderProps) {
  const { colors } = useTheme();
  return (
    <View style={{ flexDirection: "row", alignContent: "space-between", backgroundColor: colors.background }}>
      <IconButton icon="close" onPress={onRequestClose} />
      <View style={{ flexDirection: "row", flexGrow: 1, justifyContent: "center" }}>
        <IconButton icon="arrow-left"
          onPress={() => setImageIndex(index => index ? index - 1 : 0)}
          style={!imageIndex ? { opacity: 0 } : {}}
          disabled={!imageIndex} />
        <Text variant="headlineSmall" style={{ alignSelf: "center" }}>
          {imageIndex + 1}/{images.length}
        </Text>
        {<IconButton icon="arrow-right"
          onPress={() => setImageIndex(
            index => index !== undefined && index < images.length - 1 ?
              index + 1 : (images.length ? images.length - 1 : 0)
          )}
          style={imageIndex >= images.length - 1 ? { opacity: 0 } : {}}
          disabled={imageIndex >= images.length - 1} />}
      </View>
      <IconButton icon="delete"
        onPress={() => deleteImage(images[imageIndex].id)} />
    </View>
  );
}
