import { DocumentData, DocumentReference, FieldValue } from "@atgof-firebase/firebase";
import { useProject } from "./useProject";
import { sheetsCollection } from "../common/sheet";
import _ from "lodash";
import { useDocuments } from "./useDocuments";

type SlateNumber = { prefix: string, n: number };

export const cardNumberAttrs = ['pa:cameraCardNumber', 'pa:soundCardNumber', 'pa:cameraCardNumbers'];

function parseSlateNumber(slate: string | undefined): SlateNumber | undefined {
  const m = slate?.match(/(\D*)(\d+)/);
  if (!m) return;
  return { prefix: m[1], n: parseInt(m[2]) };
}

function formatSlateNumber({ prefix, n }: SlateNumber) {
  return `${prefix}${n}`;
}

export async function removeSlate(
  teamRef: DocumentReference | undefined, sheetIdToIgnore: string | undefined, slate: string | undefined
) {
  if (!teamRef) return;
  const candidate = parseSlateNumber(slate);
  if (!candidate) return;
  const latest = (await teamRef.get()).get('latestSlate') as SlateNumber | undefined;
  if (candidate?.prefix === latest?.prefix && candidate?.n == latest?.n) {
    const project = teamRef?.parent?.parent;
    const res = project ?
      await sheetsCollection(project)
        .where('category', '==', 'pa').where('team', '==', teamRef).where('deleted', '==', false)
        .orderBy('createdAt', 'desc').limit(2)
        .get() :
      undefined;
    const sheet = res?.docs.find(sheet => sheet.id !== sheetIdToIgnore);
    const nextCandidate = parseSlateNumber(sheet?.get('pa:slate'));
    await teamRef.update(cardNumberAttrs.reduce(
      (data, k) => ({ ...data, [k]: sheet?.get(k) || FieldValue.delete() }),
      {
        'latestSlate': nextCandidate || FieldValue.delete(),
      }
    ));
    return nextCandidate;
  }
  return latest;
}

export async function updateLatestSlate(
  teamRef: DocumentReference | undefined, slate: string | undefined,
  oldSlate: string | undefined, sheetId: string | undefined
) {
  if (!teamRef) return;

  const latest = await removeSlate(teamRef, sheetId, oldSlate);
  const candidate = parseSlateNumber(slate);
  if (!candidate || (latest !== undefined && latest.prefix === candidate.prefix && latest.n >= candidate.n)) {
    return;
  }
  await teamRef.update('latestSlate', candidate);
  // TODO Handle exceptions
}

export async function generateNextSlateAttrs(sceneRef: DocumentReference | undefined): Promise<DocumentData> {
  let attrs = {} as DocumentData;
  if (!sceneRef) return attrs;
  const teamRef = (await sceneRef.get()).get('team');
  if (!teamRef) return attrs;
  attrs['team'] = teamRef;
  const team = await teamRef.get();
  const latest: SlateNumber | undefined = team.get('latestSlate');
  const slate: SlateNumber | undefined = latest && { ...latest, n: latest.n + 1 };
  if (slate) {
    teamRef.update('latestSlate', slate);
    attrs['pa:slate'] = formatSlateNumber(slate);
  }
  for (const k of cardNumberAttrs) {
    const v = team.get(k);
    if (v) attrs[k] = v;
  }
  const cameras = team.get('cameras');
  if (cameras) attrs['pa:cameras'] = cameras;
  return attrs;
}

export function useTeams() {
  return useDocuments(useProject()?.collection('teams'));
}
