import { CollectionReference } from "@atgof-firebase/types";
import { useDocuments } from "./useDocuments";
import { Spec } from "../common/model/Spec";

export type ItemWithId<T> = T & { id: string }

export function useItems<T>(
  spec: Spec<T>,
  ref: CollectionReference | undefined
): ItemWithId<T>[] | undefined {
  const docs = useDocuments(ref);
  return docs?.map(doc => ({ ...spec.validator.parse(doc.data()), id: doc.id }));
}
