import { DocumentReference, DocumentSnapshot } from "@atgof-firebase/types";
import { hasSubject, subjectRef, SubjectSpec } from "./subject";
import { OnNext, onSnapshots } from "./onSnapshot";
import { onSheets, SheetCategory } from "./sheet";
import { EpisodePath, getEpisodePath, perEpisodeRef } from "./episode";
import { onScenesInParent, Scene } from "./scene";
import _ from "lodash";

export type EpisodeBreakdown = {
  episodeLookSheets: Record<string, DocumentSnapshot>;
}

export function getProjectRef(
  episodeRef: DocumentReference,
  episodePath: EpisodePath = getEpisodePath(episodeRef)
) {
  return episodePath.project ?
    episodeRef.firestore.collection('projects').doc(episodePath.project) : undefined;
}

export type SceneSheets = { scene: Scene, sheets: DocumentSnapshot[] };

export function onEpisodeSceneSheets(
  episodeRef: DocumentReference,
  categories: readonly SheetCategory[],
  subject: SubjectSpec,
  onNext: OnNext<SceneSheets[] | undefined>
) {
  const projectRef = getProjectRef(episodeRef, getEpisodePath(episodeRef));
  return onScenesInParent(
    episodeRef,
    scenes =>
      onSnapshots(
        scenes,
        scene => (
          onNext => onSheets(
            projectRef,
            [scene.ref],
            categories,
            subject,
            (sheets: DocumentSnapshot[] | undefined) => sheets ?
              onNext({ scene, sheets }) : undefined
          )
        ),
        onNext
      ),
    q => hasSubject(q, subject)
  );
}

export function getEpisodeLooksRef(
  episodeRef: DocumentReference,
  subject: SubjectSpec
) {
  const episodePath = getEpisodePath(episodeRef);
  return perEpisodeRef(
    subjectRef(getProjectRef(episodeRef, episodePath), subject), episodePath
  )?.collection('looks');
}

export type EpisodeLookSheet = { category: SheetCategory, sheet: DocumentSnapshot };

export function onEpisodeLookSheets(
  episodeRef: DocumentReference,
  categories: readonly SheetCategory[],
  subject: SubjectSpec,
  onNext: OnNext<EpisodeLookSheet[] | undefined>
) {
  const episodeLooksRef = getEpisodeLooksRef(episodeRef, subject);
  if (!episodeLooksRef) return;
  return onSnapshots<SheetCategory, EpisodeLookSheet | undefined>(
    categories,
    category => {
      const ref = episodeLooksRef!.doc(category);
      return onNext => ref.onSnapshot(
        sheet => onNext(sheet.exists ? { category, sheet } : undefined)
      )
    },
    entries => onNext(entries?.filter(x => x) as EpisodeLookSheet[] | undefined)
  );
}
