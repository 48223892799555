import { FlashListProps } from "@shopify/flash-list";
import { FlatList } from "react-native";

export function FlashList<T>({ data, renderItem }: FlashListProps<T>) {
  if (!renderItem) return null;
  return (
    <FlatList
      data={data}
      renderItem={({ item, index }) => renderItem({ item, index, target: "Cell" })}
    />
  );
}
