import { overrideUris } from '../config';
import { appId } from './appId';

export const appConfig = {
  apiKey: 'AIzaSyCozwk5OZWGqvBn9juQI1N5yMu0dynEVqU',
  authDomain: 'sheets.atgof.app',
  databaseURL: 'https://atgof-app.firebaseio.com',
  projectId: 'atgof-app',
  storageBucket: 'atgof-app.appspot.com',
  messagingSenderId: '224511278641',
  appId
}

export const functionsUri =
  overrideUris?.functions ??
  "https://europe-west1-atgof-app.cloudfunctions.net";

export const storageBaseUri =
  overrideUris?.storage ??
  "https://firebasestorage.googleapis.com";

export const uploadUri = functionsUri + '/upload';

export const storageCacheName = 'storage-cache';

export function isUploadUrl(url: string) {
  return url.startsWith(uploadUri);
}
