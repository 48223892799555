import { DocumentSnapshot, FieldValue } from "@atgof-firebase/firebase";
import React from "react";
import { LanguageContext } from "../common/language";
import { useProject } from "../data/useProject";
import { sheetSubjectCollection } from "../data/sheet";
import { SheetSubjectKind, SUBJECT_KINDS } from "../common/sheet";
import SceneInfo from "./SceneInfo";
import { Modification, Modifications, modificationsFromSnapshot, ProcessedSeason, processStripBoards, subjectsForSeasons } from "../common/file-import";
import { filmingDateIdentifier, SceneData } from "../common/scene";
import { UserContext } from "../data/userContext";
import { formatDate } from "../common/util";
import { Revealer } from "./Revealer";
import { ImporterComponentProps } from "../screens/Importer";
import SeasonSelector from "./SeasonSelector";
import { TeamSelector } from "./TeamSelector";
import { View } from "react-native";
import { Badge, Button, IconButton, ProgressBar, Text, TextInput, useTheme } from "react-native-paper";

function AliasItem(
  { displayName, references, onRename, readOnly }:
    {
      displayName: string;
      references?: string[] | undefined;
      onRename: (newName: string) => void;
      readOnly: boolean
    }
) {
  const { ph } = React.useContext(LanguageContext);
  const { colors } = useTheme();
  const [isEditing, setIsEditing] = React.useState(false);
  const [newName, setNewName] = React.useState<string | undefined>(undefined);
  const showEditIcon = !readOnly && references && !isEditing;
  function rename() {
    if (readOnly) return;
    if (newName) onRename(newName);
    setIsEditing(false);
  }
  function cancelEditing() {
    if (readOnly) return;
    setIsEditing(false);
    setNewName(undefined);
  }
  return (
    <View style={{
      flexDirection: "row",
      borderBottomWidth: 1,
      borderColor: colors.outline,
      paddingTop: 8,
      paddingBottom: 8,
      gap: 8,
      alignItems: "flex-start"
    }}>
      <View style={{ gap: 4, alignItems: "stretch", flex: 1, flexGrow: 1 }}>
        <View style={{ flexDirection: "row", gap: 8, alignItems: "center" }}>
          <Badge visible={references ? true : false} size={10} />
          <Text variant="titleMedium">{displayName}</Text>
          {(isEditing || newName) && <Text variant="titleMedium" style={{ color: colors.secondary }}>→</Text>}
          {
            isEditing ?
              <View style={{ flexDirection: "row", gap: 8 }}>
                <TextInput value={newName} onChangeText={setNewName}
                  label={ph('rename-to') as string}
                />
                <View style={{ flexDirection: "row" }}>
                  <Button onPress={rename}>{ph('save') as string}</Button>
                  <Button onPress={cancelEditing}>{ph('cancel') as string}</Button>
                </View>
              </View> :
              (newName && <Text variant="titleMedium">{newName}</Text>)
          }
        </View>
        <Text variant="bodySmall">{references?.join(', ')}</Text>
      </View>
      {showEditIcon && <IconButton icon="pencil" onPress={() => setIsEditing(true)} />}
    </View>
  );
}

function AliasManager(
  { kind, addModification, items, readOnly }:
    {
      kind: SheetSubjectKind;
      addModification: (modification: Modification & { createdAt: FieldValue }) => void;
      items: Record<string, string[]> | undefined;
      readOnly: boolean
    }
) {
  const { ph } = React.useContext(LanguageContext);
  const project = useProject();
  const [existingItems, setExistingItems] = React.useState(undefined as string[] | undefined);
  React.useEffect(
    () => project &&
      sheetSubjectCollection(project, kind).orderBy('displayName').onSnapshot(
        snapshot => setExistingItems(snapshot.docs.map(doc => doc.get('displayName')))
      ),
    [project]);
  const newItems = Object.keys(items || {}).filter(item => existingItems?.indexOf(item) == -1);
  const entries: { references?: string[]; displayName: string }[] = [
    ...newItems.map(displayName => ({
      references: (items || {})[displayName],
      displayName: displayName
    })),
    ...(existingItems || []).map(displayName => ({ displayName: displayName }))
  ].sort((a, b) => a.displayName.localeCompare(b.displayName));
  return (
    <View style={{ gap: 4 }}>
      <Text variant="headlineMedium">{ph(kind + 's') as string}</Text>
      {
        items && existingItems ?
          <View style={{ marginLeft: 4 }}>
            {entries.map((entry) =>
              <AliasItem key={entry.displayName} readOnly={readOnly}
                onRename={(newName) => {
                  addModification({
                    createdAt: FieldValue.serverTimestamp(),
                    bdsIds: [],
                    subjectKind: kind,
                    originalName: entry.displayName,
                    newName: newName
                  });
                }}
                {...entry}
              />)}
          </View> :
          <ProgressBar indeterminate />
      }
    </View>
  );
}

function DayView({ scenes }: { scenes: SceneData[] }) {
  const { ph } = React.useContext(LanguageContext);
  if (!scenes.length) return null;
  const heading = formatDate(scenes[0].filmingDate);
  return (
    <Revealer title={
      <Text variant="headlineMedium">
        {heading.length ? heading : ph('unscheduled') as string}
      </Text>}>
      <View style={{ marginLeft: 8, gap: 8 }}>
        {scenes.map((scene, i) => <SceneInfo key={i} scene={scene} excludeFilmingDate />)}
      </View>
    </Revealer>
  );
}

function SeasonManager({ season }: { season: ProcessedSeason }) {
  const { ph } = React.useContext(LanguageContext);
  const days = season.scenes.reduce(
    (days, scene) => {
      const filmingDateId = filmingDateIdentifier(scene) || '';
      return { ...days, [filmingDateId]: [...(days[filmingDateId] || []), scene] };
    },
    {} as Record<string, SceneData[]>);
  return (
    <View style={{ gap: 8 }}>
      <Text variant="headlineLarge">{ph('season') + ' ' + (season.id || '??')}</Text>
      <View>
        {Object.keys(days).sort().map(dateId => <DayView key={dateId} scenes={days[dateId]} />)}
      </View>
    </View>
  );
}

export default function SeasonImporter({ content, isImporting, importButton }: ImporterComponentProps) {
  const project = useProject();
  const { user } = React.useContext(UserContext);
  const [modifications, setModifications] = React.useState(undefined as Modifications | undefined);
  const [selectedSeason, setSelectedSeason] = React.useState<DocumentSnapshot>();
  const [defaultTeam, setDefaultTeam] = React.useState<DocumentSnapshot>();
  const seasons = modifications ? processStripBoards(content, modifications) : undefined;
  const subjects = seasons ? subjectsForSeasons(seasons) : undefined;
  function addModification(modification: Modification) {
    if (isImporting) return;
    if (project) project.collection("modifications").add({
      ...modification, lastModifiedBy: user.ref
    });
  }
  React.useEffect(
    () => project?.collection('modifications').orderBy("createdAt").onSnapshot(
      snapshot => setModifications(modificationsFromSnapshot(snapshot))
    ),
    [project]
  );
  return (
    <View style={{ gap: 32, marginTop: 8, marginBottom: 32 }}>
      {SUBJECT_KINDS.map(
        kind =>
          <AliasManager key={kind} kind={kind} addModification={addModification}
            readOnly={isImporting} items={subjects && subjects[kind]} />)}
      {seasons ?
        seasons.map(
          (season, i) => <SeasonManager key={i} season={season} />
        ) :
        <ProgressBar indeterminate />}
      {seasons && importButton &&
        <View style={{ alignSelf: "center", gap: 8 }}>
          <SeasonSelector
            selectedSeason={selectedSeason} setSelectedSeason={setSelectedSeason} />
          <TeamSelector
            labelPh="default-team"
            selectedTeam={defaultTeam} onTeamChange={setDefaultTeam}
            highlightUnselected={false} />
          {
            selectedSeason &&
            importButton({
              seasonId: selectedSeason.id,
              ...(defaultTeam ? { defaultTeamId: defaultTeam.id } : {})
            })
          }
        </View>}
    </View>
  );
}
