import React from "react";
import { LanguageContext } from "../common/language";
import FileImportButton from "../components/FileImportButton";
import ListPage from "../components/pages/ListPage";
import { useSeasons } from "../data/useSeasons";
import { RootTabScreenProps } from "../types";
import { CollectionListSpec } from "../components/CollectionList";
import { QueryDocumentSnapshot, Timestamp } from "@atgof-firebase/firebase";
import { countLabel, episodeNumberLabel, formatTimestamp } from "../common/util";
import { useNavigation } from "@react-navigation/native";
import ListItem from "../components/ListItem";
import { View } from "react-native";
import { Text } from "react-native-paper";

type EpisodeData = {
  path: string;
  sceneCount: number | undefined;
  txDate: Timestamp | undefined;
  director: string | undefined
}

function process(docs: QueryDocumentSnapshot[]): EpisodeData[] {
  return docs.reverse().map(doc => ({
    path: doc.ref.path,
    sceneCount: doc.get('sceneCount'),
    txDate: doc.get('txDate'),
    director: doc.get('director')
  }));
}

export default function EpisodesScreen({ }: RootTabScreenProps<'episodes'>) {
  const { ph } = React.useContext(LanguageContext);
  const navigation = useNavigation();
  const renderItem = React.useCallback(
    ({ item: { path, sceneCount, txDate, director } }: { item: EpisodeData }) => {
      return (
        <ListItem
          onPress={() => navigation.navigate('episode', { episodePath: path })}>
          <View style={{ flexDirection: "row", alignContent: "flex-start" }}>
            <View style={{}}>
              <Text variant="headlineLarge" style={{ marginRight: 20 }}>
                {episodeNumberLabel(path)}
              </Text>
            </View>
            <View>
              <View style={{ flexDirection: "row", alignContent: "flex-end" }}>
                <View style={{ minWidth: 140 }}>
                  {txDate && <Text variant="bodyLarge" style={{ marginRight: 10 }}>
                    {ph('tx') + ' ' + formatTimestamp(txDate)}
                  </Text>}
                </View>
                <Text variant="bodyLarge">{director}</Text>
              </View>
              <Text variant="bodyLarge">{countLabel(ph, 'count-scene', sceneCount)}</Text>
            </View>
          </View>
        </ListItem>
      );
    },
    [ph, navigation]);
  const seasons = useSeasons(true);
  const [collections, setCollections] = React.useState<CollectionListSpec<EpisodeData, string>[]>();
  React.useEffect(() => setCollections(
    seasons?.map(season => ({
      collection: season.ref.collection('episodes'),
      process, renderItem,
      searchTokens: item => [
        item.director,
        formatTimestamp(item.txDate),
        ...item.path.split('/').reverse().slice(0, 2), // TODO This should be properly generalised
      ],
      headerData: ph('season') + ' ' + season.id,
      renderHeader: title => <Text variant="headlineLarge">{title}</Text>
    }))), [seasons, ph]);
  return (
    <ListPage
      headerRight={<FileImportButton />}
      collections={collections}
      estimatedItemSize={52}
    />
  );
}
