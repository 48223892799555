import React, { useMemo } from "react";
import { UserContext } from "./userContext";
import { DocumentReference } from "@atgof-firebase/types";
import { getDocumentReference } from "../common/firestore";

export function useDocumentReference<P extends string | undefined>(
  documentPath: P
): DocumentReference | (undefined extends P ? undefined : never) {
  const { user } = React.useContext(UserContext);
  return useMemo(() => getDocumentReference(user, documentPath), [user, documentPath]);
}

export function useCollectionReference(collectionPath: string) {
  const { user } = React.useContext(UserContext);
  return useMemo(() => user.ref.firestore.collection(collectionPath), [user, collectionPath]);
}
