import React from "react";
import { IconButton, Menu } from "react-native-paper";
import { ProjectContext } from "../data/projectContext";
import { LanguageContext } from "../common/language";
import { SheetCategory, sheetCategorySpecs } from "../common/sheet";

export default function NewSheetOrLookButton({ kind, restrictToCategories, actionForCategory }: {
  kind: 'sheet' | 'look' | 'episode';
  restrictToCategories?: SheetCategory[];
  actionForCategory: (category: SheetCategory) => (() => void);
}) {
  const { ph } = React.useContext(LanguageContext);
  const { categories } = React.useContext(ProjectContext);
  const [menuVisible, setMenuVisible] = React.useState(false);
  function openMenu() { setMenuVisible(true); }
  function closeMenu() { setMenuVisible(false); }
  const icon = kind === 'sheet' ? "file-image-plus" : "plus";
  const phraseKeyAttr = kind === 'sheet' ? 'newSheetPh' : 'newLookPh';
  const sheetCategories = (Object.keys(sheetCategorySpecs) as SheetCategory[])
    .filter(k =>
      categories.includes(k) && (
        restrictToCategories ? restrictToCategories.includes(k) : sheetCategorySpecs[k][phraseKeyAttr]
      )
    )
    .sort();
  const categoryCount = sheetCategories.length;
  if (categoryCount > 0) {
    if (categoryCount == 1) {
      return <IconButton icon={icon} onPress={actionForCategory(sheetCategories[0])} />;
    }
    return (
      <Menu
        visible={menuVisible}
        onDismiss={closeMenu}
        anchor={<IconButton onPress={openMenu} icon={icon} />}
        anchorPosition="bottom"
      >
        {
          sheetCategories.map(category =>
            <Menu.Item key={category}
              onPress={() => { closeMenu(); actionForCategory(category)() }}
              title={ph(sheetCategorySpecs[category][phraseKeyAttr]!) as string}
            />
          )
        }
      </Menu>
    );
  }
  return null;
}
