import { useProject } from "./useProject";
import React from "react";
import { UserContext } from "./userContext";
import { LanguageContext } from "../common/language";
import { Query, QueryDocumentSnapshot, Timestamp } from "@atgof-firebase/firebase";
import { Platform, View } from "react-native";
import SectionHeader from "../components/SectionHeader";
import Select from "../components/Select";
import SheetListEntry from "../components/SheetListEntry";
import { CollectionListSpec } from "../components/CollectionList";
import { SortByOption, dataForSheet, SheetDisplayData } from "./sheet";
import { ProjectContext } from "./projectContext";
import { sheetsCollection } from "../common/sheet";
import dayjs from "dayjs";
import DayPicker from "../components/DayPicker";
import FixtureLabel from "../common/FixtureLabel";


export function useSheetsCollectionSpec(
  { addConstraints, withoutSubject, userOnly, since, showTitle }: {
    addConstraints?: (q: Query) => Query | undefined;
    withoutSubject?: boolean;
    userOnly?: boolean;
    since?: Date | true;
    showTitle?: boolean;
  }
) {
  const { user } = React.useContext(UserContext);
  const project = useProject();
  const { categories, adminCategories } = React.useContext(ProjectContext);
  const { ph } = React.useContext(LanguageContext);
  const isPostProductionUser = categories.includes('postProduction');
  const sortByOptions = React.useMemo<SortByOption[]>(
    () => {
      const secondaryKey = 'scene';
      return [
        { key: 'createdAt', compareFn: undefined, label: `${ph('by')} ${ph('date')}` },
        {
          key: secondaryKey,
          compareFn: (a, b) => {
            const valA = a && a[secondaryKey];
            const valB = b && b[secondaryKey];
            return ('' + valB?.path).localeCompare(valA?.path || '');
          },
          label: `${ph('by')} ${ph(secondaryKey)}`
        }
      ];
    },
    [ph]
  );
  const [startDay, setStartDay] = React.useState(dayjs().subtract(7, 'day'));
  const [sortBy, setSortBy] = React.useState<SortByOption | undefined>();
  const processor = React.useCallback((docs: QueryDocumentSnapshot[]) => docs.map(doc => dataForSheet(doc)).filter(
    sheet =>
      categories.includes(sheet.category) ||
      (sheet.category === 'pa') && isPostProductionUser),
    [categories]);
  const constraintsAdder = React.useCallback((q: Query | undefined) => {
    if (!q) return;
    if (userOnly) {
      q = q.where('createdBy', '==', user.ref);
    }
    if (since) {
      q = q.where(
        'createdAt', '>',
        since === true ? Timestamp.fromMillis(startDay.toDate().getTime()) : since
      );
    }
    q = q.where('deleted', '==', false).orderBy("createdAt", "desc");
    if (addConstraints) {
      q = addConstraints(q);
    }
    return q;
  }, [userOnly, since, startDay, user, addConstraints]);
  const showMetadata = Platform.OS === 'web' && (adminCategories.includes('admin') || categories.includes('pa') || isPostProductionUser);
  const [collectionSpec, setCollectionSpec] =
    React.useState<CollectionListSpec<SheetDisplayData, SortByOption | undefined> | undefined>();
  React.useEffect(
    () => {
      setCollectionSpec(
        project ?
          {
            collection: sheetsCollection(project),
            addConstraints: constraintsAdder,
            process: processor,
            sortComparison: sortBy?.compareFn,
            headerData: sortBy,
            renderHeader: (
              (selectedEntry: SortByOption | undefined) =>
                <SectionHeader title={showTitle ? ph('sheets') as string : undefined}>
                  {since === true ?
                    <View style={{ gap: 8, flexDirection: 'row', alignContent: 'center' }}>
                      <FixtureLabel text={ph('since') as string} />
                      <DayPicker day={startDay} setDay={setStartDay} />
                    </View> :
                    null}
                  <Select
                    entries={sortByOptions}
                    entryKey={({ key }) => key}
                    entryLabel={({ label }) => label as string}
                    selectedEntry={selectedEntry || sortByOptions[0]}
                    onEntryChange={setSortBy} />
                </SectionHeader>
            ),
            renderItem: ({ item }: { item: SheetDisplayData; }) =>
              <SheetListEntry
                sheet={item}
                withoutSubject={withoutSubject}
                withMetadata={showMetadata}
                withCategory={categories.length > 1} />,
            searchTokens: item =>
              [item.character, item.set, item.episode?.id, item.scene?.id] // TODO More here
          } :
          undefined
      );
    },
    [project, constraintsAdder, processor, sortBy, showTitle, sortByOptions, withoutSubject, showMetadata, categories, ph]);
  return collectionSpec;
}
