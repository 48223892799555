import { ReactNode } from "react";
import { View } from "react-native";
import { Text } from "react-native-paper";

export default function SectionHeader(
  { title, children }: { title: string | undefined, children?: ReactNode }
) {
  return (
    <View style={{ marginTop: 10, flexDirection: 'row', alignItems: 'center', gap: 20 }}>
      {
        title &&
        <Text variant="headlineMedium">{title}</Text>
      }
      {children}
    </View>
  );
}
