import { ResponseType } from 'expo-auth-session';
import * as Google from 'expo-auth-session/providers/google';
import React from 'react';
import { Platform } from 'react-native';
import { SigninProps } from "../signin";

export default function GoogleSignin({ signinState, onToken, button }: SigninProps) {
  const [signinStatus, setSigninStatus] = signinState;
  const [_, response, promptAsync] = Google.useAuthRequest({
    androidClientId: '224511278641-uu28nc73731hup8vb2mncjgpsd8kt6iu.apps.googleusercontent.com',
    webClientId: '224511278641-49e885turbp38tk1n6avth58c7qtn4mk.apps.googleusercontent.com',
    iosClientId: '224511278641-7toqtsmu2he2scoi3g78c0bn0hih0k16.apps.googleusercontent.com',
    responseType: Platform.OS === 'web' ? ResponseType.IdToken : undefined
  });

  React.useEffect(() => {
    if (signinStatus.activeForProvider !== 'google') return;
    if (response?.type === 'success' &&
      (response.authentication?.accessToken || response.params.id_token)) {
      onToken({
        provider: 'google', value: {
          idToken: response.params.id_token, accessToken: response.authentication?.accessToken
        }
      });
    }
    else if (response) setSigninStatus({ error: response });
  }, [signinStatus.activeForProvider, response]);

  function signIn() {
    setSigninStatus({ activeForProvider: 'google' });
    promptAsync();
  }
  return button(signIn);
}
