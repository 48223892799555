import React from "react";
import { LanguageContext } from "../common/language";
import { BackendContext } from "../data/backend";
import HeaderStrip from "./HeaderStrip";
import ErrorDetail from "./ErrorDetail";
import { View } from "react-native";
import { Button, IconButton, Text } from "react-native-paper";

export default function ErrorStrip() {
  const { ph } = React.useContext(LanguageContext);
  const { latestAction } = React.useContext(BackendContext);
  const [expanded, setExpanded] = React.useState(false);

  if (!latestAction?.error) return null;

  const { error, descriptionPhraseKey } = latestAction;
  const errorDetail = expanded &&
    (typeof error === 'object' && 'message' in error ? error.message : error.toString());
  return (
    <HeaderStrip
      mode="error"
      style={{ paddingLeft: 16, paddingRight: 8, paddingTop: 8, paddingBottom: 8 }}
    >
      <View style={{ gap: 4, alignItems: "center" }}>
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <Text>
            {
              (descriptionPhraseKey ?
                (ph('unable-to') + ' ' + ph(descriptionPhraseKey)) :
                ph('unable-to-perform-action')) +
              '. ' + ph('try-again')
            }
          </Text>
          <IconButton icon="information"
            selected={expanded}
            onPress={() => setExpanded(!expanded)}
          />
        </View>
        {errorDetail ?
          <View style={{ gap: 4 }}>
            <ErrorDetail>{errorDetail}</ErrorDetail>
            <Button
              style={{ alignSelf: "center" }}
              onPress={() => setExpanded(false)}
            >
              {ph('dismiss') as string}
            </Button>
          </View> :
          null}
      </View>
    </HeaderStrip>
  );
}
