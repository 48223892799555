import { View } from "react-native";
import FixtureLabel from "../common/FixtureLabel";

export default function Labelled(
  { label, children }: { label: string; children: React.ReactNode }
) {
  return (
    <View>
      <FixtureLabel text={label} style={{ marginBottom: 8 }} />
      {children}
    </View>
  );
}
