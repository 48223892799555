import { v4 as uuidv4 } from "uuid";
import {
  ActivityIndicator, FlatList, Image, Platform, Pressable, StyleProp, View, ViewStyle
} from "react-native";
import * as ImagePicker from 'expo-image-picker';
import React from "react";
import { LanguageContext } from "../common/language";
import { DocumentReference, DocumentSnapshot, FieldValue } from "@atgof-firebase/firebase";
import { UserContext } from "../data/userContext";
import { useProject } from "../data/useProject";
import {
  BackendContext, ImageSource, uploadFile, useImageSources
} from "../data/backend";
import ImageView from "./ImageView";
import { IconButton } from "react-native-paper";
import { imagesCollection, imagesQuery, SheetCategory } from "../common/sheet";
import { useSnapshot } from "../data/useSnapshot";

function Photo(
  { source, index, showPhoto }:
    { source: ImageSource; index: number; showPhoto: (index: number) => void }
) {
  if (!source.uri) return null;
  return (
    <View style={{ alignItems: "flex-end", backgroundColor: "#888888", padding: 10, paddingTop: 14 }}>
      <Pressable
        onPress={() => showPhoto(index)}
        style={{ marginBottom: 16 }}
      >
        <Image source={source} style={{ width: 140, height: 140 }} />
      </Pressable>
    </View>
  );
}

function PhotoStripButtons({ sheetRef, sheetCategory }: { sheetRef: DocumentReference; sheetCategory: SheetCategory }) {
  const backend = React.useContext(BackendContext);
  const project = useProject();
  const { ph } = React.useContext(LanguageContext);
  const { user } = React.useContext(UserContext);
  const [cameraPermission, requestCameraPermission] = ImagePicker.useCameraPermissions();
  const [libraryPermission, requestLibraryPermission] = ImagePicker.useMediaLibraryPermissions();

  async function pickImage(fromCamera: boolean) {
    if (!project) return;
    // TODO Handle all errors
    const [launchPicker, requestPermission] =
      fromCamera ? [ImagePicker.launchCameraAsync, requestCameraPermission]
        : [ImagePicker.launchImageLibraryAsync, requestLibraryPermission];
    if (Platform.OS !== 'web') {
      let permission = fromCamera ? cameraPermission : libraryPermission;
      if (!permission?.granted && (!permission || permission.canAskAgain)) {
        permission = await requestPermission();
      }
      if (!permission?.granted) {
        alert(ph(fromCamera ? 'camera-permission-required-error' : 'library-permission-required-error'));
        return;
      }
    }
    const { assets } = await launchPicker({
      mediaTypes: sheetCategory === 'pa' ? ImagePicker.MediaTypeOptions.All : ImagePicker.MediaTypeOptions.Images,
      allowsEditing: fromCamera,
      allowsMultipleSelection: !fromCamera,
      exif: true,
    });
    if (assets) {
      for (const { uri, width, height } of assets) {
        await uploadFile(
          backend, project, sheetCategory, user, uuidv4(),
          sheetRef.collection("images"),
          uri,
          { deleted: false, width, height }
        );
      }
    }
  }
  return (
    <View style={{ flexDirection: "row", marginBottom: 8, gap: 8 }}>
      <IconButton icon="camera-plus" onPress={() => pickImage(true)} />
      <IconButton icon="image-plus" onPress={() => pickImage(false)} />
    </View>
  );
}

export default function PhotoStrip(
  { sheetRef, sheetCategory, referencePath, style, readonly }:
    {
      sheetRef: DocumentReference;
      sheetCategory: SheetCategory;
      referencePath: string | undefined;
      style: StyleProp<ViewStyle>;
      readonly?: boolean
    }
) {
  const project = useProject();
  const { user } = React.useContext(UserContext);
  const coll = imagesCollection(sheetRef);
  const images = useSnapshot<DocumentSnapshot[] | undefined>(
    onNext => imagesQuery(coll).onSnapshot(snapshot => onNext(snapshot.docs)),
    [coll.path]
  );
  const [selectedIndex, setSelectedIndex] = React.useState<number>();
  const sources = useImageSources(images, referencePath, 'sm');
  const largeSources = useImageSources(images, referencePath); // Required in order to download larger image as well

  function closeImageView() {
    setSelectedIndex(undefined);
  }
  function deleteImage(id: string) {
    if (!project) return;
    coll.doc(id).update({
      deleted: FieldValue.serverTimestamp(), lastModifiedBy: user.ref
    });
  }

  return (
    <View style={style}>
      {largeSources ?
        <ImageView images={largeSources} imageIndex={selectedIndex ?? 0}
          setImageIndex={setSelectedIndex}
          visible={selectedIndex !== undefined}
          onRequestClose={closeImageView} deleteImage={deleteImage}
        />
        :
        null}
      {!readonly && <PhotoStripButtons sheetRef={sheetRef} sheetCategory={sheetCategory} />}
      {sources && sources.length ?
        <FlatList horizontal
          data={sources}
          renderItem={({ item, index }) =>
            <Photo index={index} source={item} showPhoto={setSelectedIndex} />
          } />
        : null
      }
      {!sources && <ActivityIndicator style={{ width: 100, height: 100 }} />}
    </View>
  );
}
