import { DocumentData, QuerySnapshot, Query } from '@atgof-firebase/firebase';
import React from 'react';
import { RootTabScreenProps } from '../types';
import { LanguageContext } from '../common/language';
import { useProject } from "../data/useProject";
import { useNavigation } from '@react-navigation/native';
import { roleCategories } from '../data/user';
import NavPage from '../components/pages/NavPage';
import { Card, Chip, IconButton, Text } from 'react-native-paper';
import { FlashList } from '../components/FlashList';
import { MembershipCategory, normaliseColour } from '../common/model/project/membership';
import { ColourControl } from '../components/ColourControl';
import { View } from 'react-native';

type Entry = DocumentData;

function User({ entry }: { entry: Entry }) {
  const { ph } = React.useContext(LanguageContext);
  const navigation = useNavigation();
  const isInvitation = !('user' in entry);
  const inkColour: string | undefined =
    'inkColour' in entry ? entry.inkColour : undefined;
  return (
    <Card style={{ margin: 4 }}>
      <Card.Title
        title={isInvitation ? entry.email : entry.displayName}
        subtitle={isInvitation ? undefined : <Text>{entry.email}</Text>}
        right={
          () =>
            <IconButton
              icon="pencil"
              onPress={() => navigation.navigate('membership', {
                itemId: entry.id, isInvitation: isInvitation ? 'true' : 'false',
                displayName: entry.displayName || entry.email
              })}
            />
        }
      />
      <Card.Content style={{ gap: 8 }}>
        <View style={{
          flexDirection: "row", flexWrap: "wrap", gap: 2, alignItems: "center"
        }}>
          {roleCategories(entry.roles, 'all').map(category =>
            <Chip key={category} icon={entry.roles[category].admin ? "shield-star" : undefined}>
              {ph(category) as string}
            </Chip>)}
        </View>
        {inkColour ?
          <ColourControl value={normaliseColour(inkColour)} /> :
          null
        }
      </Card.Content>
    </Card>
  );
}

type InvitationsAndMemberships = { [kind: string]: { [category: string]: QuerySnapshot<Entry> } }

export default function UsersScreen({ categories }:
  RootTabScreenProps<'users'> & { categories: MembershipCategory[] }
) {
  const { navigate } = useNavigation();
  const project = useProject();

  const [invitationsAndMemberships, setInvitationsAndMemberships] =
    React.useState<InvitationsAndMemberships>({});
  const [invitations, setInvitations] = React.useState([] as Entry[]);
  const [memberships, setMemberships] = React.useState([] as Entry[]);

  React.useEffect(() => {
    setInvitationsAndMemberships({});
    if (project && categories.length > 0) {
      const unsubs = categories.flatMap(category => {
        function applyCategoryconstraint(q: Query) {
          return q.where('roles.' + category + '.user', '==', true);
        }
        return ['invitations', 'memberships'].map(kind => {
          const q = applyCategoryconstraint(project.collection(kind));
          return (kind === 'memberships' ? q.where('deleted', '==', false) : q)
            .onSnapshot(entries => setInvitationsAndMemberships(state =>
              ({ ...state, [kind]: { ...state[kind], [category]: entries } })
            ));
        });
      });
      return () => unsubs.forEach(unsub => unsub());
    }
  }, [project, categories]);

  React.useEffect(() => {
    [
      { kind: 'invitations', setEntries: setInvitations },
      { kind: 'memberships', setEntries: setMemberships }
    ].forEach(
      ({ kind, setEntries }) => {
        const entriesPerCategory = invitationsAndMemberships[kind];
        const snapshots = Object.keys(entriesPerCategory || {})
          .flatMap(category => entriesPerCategory[category]?.docs || []);
        const ids = new Set([...snapshots.map(snapshot => snapshot.id)]);
        setEntries([...ids].map(id => {
          const snapshot = snapshots.find(snapshot => snapshot.id == id)!;
          return { ...snapshot.data(), snapshot: snapshot, id: snapshot.id };
        }));
      });
  }, [invitationsAndMemberships]);

  const { ph } = React.useContext(LanguageContext);

  const data = React.useMemo(
    () => ([
      ...(invitations.length ?
        [ph('invitations'), ...invitations.sort((a, b) => a.email?.localeCompare(b.email))] :
        []),
      ...(memberships.length ?
        [ph('members'), ...memberships.sort((a, b) => a.displayName?.localeCompare(b.displayName))] :
        [])
    ]),
    [ph, invitations, memberships]
  );

  return (
    <NavPage fullScreen addItem={() => navigate('newInvitation', {})}>
      <FlashList
        data={data} estimatedItemSize={100}
        renderItem={({ item }: { item: string | DocumentData }) =>
          typeof item === 'string' ?
            <Text variant="headlineMedium" style={{ marginTop: 8, marginBottom: 8 }}>
              {item}
            </Text> :
            <User entry={item} />
        }
      />
    </NavPage>
  );
}
