import NavPage, { NavPageProps } from "./NavPage";
import React from "react";
import CollectionList, { CollectionListProps } from "../CollectionList";

export default function ListPage(props: NavPageProps & CollectionListProps) {
  return (
    <NavPage fullScreen {...props}>
      <CollectionList {...props} />
    </NavPage>
  );
}
