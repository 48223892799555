import { CollectionReference, DocumentReference, DocumentSnapshot, getStorage } from '@atgof-firebase/firebase';
import React from 'react';
import { PhraseKey } from '../common/phrases';
import * as Network from 'expo-network';
import { Uploader, UploadMetadata, UploadStatus } from './backend/uploader';
import { Downloader, Downloads, referencePathForScene } from './backend/downloader';
import { StorageManager } from './backend/storage-manager';
import { UserContext } from './userContext';
import { ImageSizeSuffix, ImageSpec, withSizeSuffix } from '../common/sheet';
import { MembershipCategory } from '../common/model/project/membership';

type BackendError = any

type BackendAction = {
  descriptionPhraseKey?: PhraseKey;
  error?: BackendError
}

type BackendState = {
  functionsURI: string;
  latestAction: BackendAction | undefined;
  setLatestAction: (action: BackendAction | undefined) => void;
  storageManager: StorageManager;
  uploader: Uploader;
  downloader: Downloader;
  uploads: UploadStatus[];
  downloads: Downloads;
  updateAvailable: boolean
}

export const BackendContext = React.createContext({} as BackendState);

export async function transact<T>(
  backend: BackendState, descriptionPhraseKey: PhraseKey, transaction: () => Promise<T>
): Promise<T> {
  const { setLatestAction } = backend;
  const action = { descriptionPhraseKey: descriptionPhraseKey };
  setLatestAction(action);
  try {
    const result = await transaction();
    return result;
  }
  catch (e) {
    if (setLatestAction) setLatestAction({ ...action, error: e });
    return Promise.reject(e);
  }
}

export async function uploadFile(
  backend: BackendState,
  project: DocumentReference,
  category: MembershipCategory,
  user: DocumentSnapshot,
  uploadIdentifier: string,
  collectionRef: CollectionReference, input: string | File,
  metadata: UploadMetadata
) {
  const storagePath = [project.id, category, user.id, uploadIdentifier].join('/');
  return backend.uploader.uploadFile(
    input, storagePath, collectionRef.doc(uploadIdentifier), user, metadata
  );
}

type ImageStatus = {
  uri?: string;
  hasError?: boolean;
  inProgress?: 'upload' | 'download',
  isDownloaded?: boolean
}

export type ImageSource = ImageSpec & ImageStatus

export async function getScript(
  downloader: Downloader,
  sceneRef: DocumentReference,
  path: string
): Promise<string> {
  const storage = getStorage();
  return downloader.retrieveAsString(
    storage, path, 'scripts', referencePathForScene(sceneRef, 'scripts')
  );
}

export function useImageSources(
  docs: DocumentSnapshot[] | undefined,
  referencePath: string | undefined,
  sizeSuffix?: ImageSizeSuffix
) {
  const storage = getStorage();
  const { uploader, downloader, uploads } = React.useContext(BackendContext);
  const [sources, setSources] = React.useState<ImageSource[]>();
  React.useEffect(
    () => {
      if (!docs) {
        setSources(undefined);
        return;
      }
      Promise.all(docs.map(async image => {
        const { id } = image;
        const [width, height]: (number | undefined)[] = ['width', 'height'].map(k => image.get(k));
        const source = { id, width, height };
        const path = withSizeSuffix(image.get('path'), sizeSuffix);
        if (!path) return { ...source, hasError: true };
        const upload = uploads.find(({ docRefPath }) => docRefPath === image.ref.path);
        const cacheKey = upload?.cacheKey;
        if (cacheKey) {
          const uploadURI = await uploader.getUriForCacheKey(cacheKey);
          if (uploadURI) {
            return { ...source, uri: uploadURI };
          }
        }
        try {
          const uri = await downloader.getURI(
            storage, path, 'images', referencePath
          );
          return { ...source, uri };
        }
        catch (error) {
          console.error(error);
          return { ...source, hasError: true };
        }
      })).then(setSources).catch(error => console.error(error));
    },
    [storage, docs, uploader, downloader, uploads]
  );
  return sources;
}

export function onBackendConnected(backend: BackendState, user: DocumentSnapshot) {
  backend.uploader.onUserConnected(user);
}

export function usePendingWrites() {
  const { user } = React.useContext(UserContext);
  const [hasPendingWrites, setHasPendingWrites] = React.useState(false);
  const [isInternetReachable, setIsInternetReachable] = React.useState<boolean>();
  const waitStatus = React.useRef<{ id: number; returned: boolean }>();
  const poll = React.useCallback((delay: number = 0) => {
    if (!user) return;
    const id = new Date().getTime();
    waitStatus.current = { id, returned: false };
    const pollTimeout = setTimeout(() => {
      Network.getNetworkStateAsync().then(state => setIsInternetReachable(state.isInternetReachable));
      user.ref.firestore.waitForPendingWrites().finally(() => {
        if (waitStatus.current?.id != id) return;
        waitStatus.current = { id, returned: true };
        setHasPendingWrites(false);
        poll(1000);
      });
    }, delay);
    const processTimeout = setTimeout(() => {
      if (waitStatus.current?.id != id) return;
      setHasPendingWrites(!waitStatus.current.returned);
    }, delay + 10);
    return () => {
      waitStatus.current = undefined;
      clearTimeout(processTimeout);
      clearTimeout(pollTimeout);
    };
  }, [user.ref.firestore]);

  React.useEffect(poll, [poll]);

  return { isInternetReachable, hasPendingWrites };
}

export function useBackend(functionsURI: string, storageManager: StorageManager) {
  const [latestAction, setLatestAction] = React.useState<BackendAction>();
  const { uploader, downloader } = storageManager;
  const [uploads, setUploads] = React.useState<UploadStatus[]>([]);
  React.useEffect(() => uploader.onUploads(setUploads), [uploader]);
  const [downloads, setDownloads] = React.useState<Downloads>({});
  React.useEffect(() => downloader.onDownloads(setDownloads), [downloader]);
  const [updateAvailable, setUpdateAvailable] = React.useState<boolean>(false);
  React.useEffect(() => storageManager.onUpdateAvailable(setUpdateAvailable), [storageManager]);

  return React.useMemo(
    () => ({
      functionsURI, latestAction, setLatestAction,
      storageManager, uploader, downloader, uploads, downloads,
      updateAvailable
    }),
    [functionsURI, latestAction, uploader, downloader, uploads, downloads, updateAvailable]
  );
}
