import React, { ReactNode } from "react";
import { LanguageContext } from "../common/language";
import IntroDialog from "./IntroDialog";
import { AlertBox } from "./AlertBox";

type ErrorBoundaryProps = { toplevel?: boolean, children: ReactNode | undefined }
type ErrorBoundaryState = { error?: any; errorInfo?: any }

export default class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  static contextType = LanguageContext;

  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {};
  }

  componentDidCatch(error: any, errorInfo: any) {
    this.setState({ ...this.state, error: error, errorInfo: errorInfo });
  }

  render() {
    const { toplevel } = this.props;
    const { error, errorInfo } = this.state;
    const { ph } = this.context as any;
    if (error) {
      console.error(error, errorInfo);
      const alertBox =
        <AlertBox
          status="error" title={ph('unexpected-error') as string}
          message={ph((toplevel ? 'toplevel-' : '') + 'unexpected-error-prompt') as string}
        />
      return toplevel ? <IntroDialog>{alertBox}</IntroDialog> : alertBox;
    }
    return this.props.children || null;
  }
}
