import { NavigationProp, ParamListBase } from "@react-navigation/native";
import { Appbar } from "react-native-paper";

export default function OpenDrawerButton(
  { navigation }: { navigation: NavigationProp<ParamListBase> }
) {
  if ('openDrawer' in navigation && navigation.openDrawer) {
    return <Appbar.Action icon="menu" isLeading onPress={navigation.openDrawer as () => void} />;
  }
  return null;
}
