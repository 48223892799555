import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { DocumentReference, QueryDocumentSnapshot } from "@atgof-firebase/firebase";
import React from "react";
import { RootStackParamList } from "../types";
import CollectionList, { CollectionListSpec } from "../components/CollectionList";
import { Scene, compareScenes, toScene } from "../common/scene";
import { Platform, useWindowDimensions } from "react-native";
import { TabBar, TabView } from "react-native-tab-view";
import NavPage from "../components/pages/NavPage";
import { BreakdownView } from "../components/BreakdownView";
import { LanguageContext } from "../common/language";
import { ScriptButton } from "../components/ScriptButton";
import { useTheme } from "react-native-paper";
import { toEpisode } from "../common/episode";
import ListItem from "../components/ListItem";
import SceneInfo from "../components/SceneInfo";
import { useNavigation } from "@react-navigation/native";
import { formatDate } from "../common/util";
import { ProjectContext } from "../data/projectContext";
import { EpisodeTimingsView } from "../components/EpisodeTimingsView";
import { useDocumentReference } from "../data/firestore";
import { useDocument } from "../data/useDocument";

function ScenesView({ episodeRef }: { episodeRef: DocumentReference | undefined }) {
  const navigation = useNavigation();
  const [collections, setCollections] =
    React.useState<CollectionListSpec<Scene, void>[] | undefined>();
  React.useEffect(() => {
    setCollections(
      episodeRef ?
        [{
          collection: episodeRef.collection('scenes'),
          sortComparison: compareScenes,
          process: (snapshots: QueryDocumentSnapshot[]) =>
            snapshots.map(snapshot => toScene(snapshot)),
          renderItem: ({ item }) =>
            <ListItem onPress={() => navigation.navigate('scene', { scenePath: item.ref.path })}>
              <SceneInfo style={{ marginTop: 8, marginBottom: 8 }} scene={item} />
            </ListItem>,
          searchTokens: item => [
            item.id,
            item.episodeId,
            ...(item.cast ?? []),
            item.comment,
            formatDate(item.filmingDate, true),
            item.location,
            ...(item.sets ?? []),
            item.synopsis,
          ]
        }] :
        undefined);
  }, [episodeRef, navigation]);
  return <CollectionList collections={collections} estimatedItemSize={100} />;
}

function renderTabBar(props: any) {
  const { colors } = useTheme();
  return (
    <TabBar
      {...props}
      indicatorStyle={{ backgroundColor: colors.primary }}
      style={{ backgroundColor: colors.secondaryContainer, color: colors.onSecondaryContainer }}
      activeColor={colors.onSecondaryContainer}
      inactiveColor={colors.onSecondaryContainer}
    />
  );
}

function Tabs({ episodeRef }:
  {
    episodeRef: DocumentReference | undefined;
  }
) {
  const layout = useWindowDimensions();
  const { ph } = React.useContext(LanguageContext);
  const { categories } = React.useContext(ProjectContext);
  const [index, setIndex] = React.useState(0);
  const routes = React.useMemo(
    () => [
      { key: 'scenes', title: ph('scenes') as string },
      ...(
        categories.includes('pa') ? [
          { key: 'timings', title: ph('timings') as string }
        ] : []
      ),
      { key: 'breakdown', title: ph('breakdown') as string }
    ],
    [ph, categories]
  );
  const renderScene = React.useCallback(
    ({ route }: { route: { key: string } }) => {
      switch (route.key) {
        case 'scenes':
          return <ScenesView episodeRef={episodeRef} />;
        case 'timings':
          return <EpisodeTimingsView episodeRef={episodeRef} />;
        case 'breakdown':
          return <BreakdownView episodeRef={episodeRef} />; // TODO
      }
    },
    [episodeRef?.path]
  );
  return (
    <TabView
      lazy
      navigationState={{ index, routes }}
      renderTabBar={renderTabBar}
      renderScene={renderScene}
      onIndexChange={setIndex}
      initialLayout={{ width: layout.width }}
    />
  );
}

export default function EpisodeScreen({ route }: NativeStackScreenProps<RootStackParamList, 'episode'>) {
  const { episodePath } = route.params;
  const episodeRef = useDocumentReference(episodePath);
  const episode = useDocument(episodeRef);
  return (
    <NavPage fullScreen
      newSheetContext={{ episodeRef }}
      headerRight={<ScriptButton wholeEpisode parent={episode ? toEpisode(episode) : undefined} />}
    >
      {
        Platform.OS === 'web' ?
          <Tabs episodeRef={episodeRef} /> :
          <ScenesView episodeRef={episodeRef} />
      }
    </NavPage>
  );
}
