import { DocumentReference } from "@atgof-firebase/firebase";
import React from "react";
import { LanguageContext } from "../common/language";
import { useSeasons } from "../data/useSeasons";
import { getScenePath, sortScenes } from "../common/scene";
import Labelled from "./LabelledSelect";
import { getEpisodePath } from "../common/episode";
import { View, ViewStyle } from "react-native";
import Select from "./Select";

function getId(doc: DocumentReference) { return doc.id; }

export type ReferentKind = 'scene' | 'episode';

export default function ReferentSelector({
  kind, defaultReferentId, referent, onReferentChange, style,
  includeHistorical = false, allowOther = false
}:
  {
    kind: ReferentKind;
    defaultReferentId?: string | undefined;
    referent?: DocumentReference;
    onReferentChange: (referent: DocumentReference | undefined) => void;
    style?: ViewStyle;
    includeHistorical?: boolean;
    allowOther?: boolean
  }) {
  const { ph } = React.useContext(LanguageContext);
  const seasonId = ((kind === 'scene' ? getScenePath : getEpisodePath)(referent)).season;
  const seasons = useSeasons(includeHistorical, seasonId);
  const [episodes, setEpisodes] = React.useState<DocumentReference[] | undefined>(
    kind === 'episode' && referent ? [referent] : []
  );
  const [scenes, setScenes] = React.useState<DocumentReference[] | undefined>(
    kind === 'scene' && referent ? [referent] : []
  );
  const [selectedSeason, setSelectedSeason] = React.useState<DocumentReference>();
  const [selectedEpisode, setSelectedEpisode] = React.useState<DocumentReference>();
  React.useEffect(() => {
    if (seasonId && !selectedSeason) {
      setSelectedSeason(seasons?.find(s => s.id == seasonId)?.ref);
    }
  }, [seasons, seasonId]);
  React.useEffect(() => {
    if (!selectedEpisode) {
      const defaultId = (kind === 'episode' ? defaultReferentId : undefined);
      const id =
        ((kind === 'scene' ? getScenePath : getEpisodePath)(referent)).episode ??
        defaultId;
      const ep = episodes?.find(ep => ep.id == id) ??
        (id ? selectedSeason?.collection('episodes').doc(id) : undefined);
      setSelectedEpisode(ep);
      if (kind === 'episode' && referent?.path !== ep?.path) onReferentChange(ep);
    }
  }, [selectedSeason, episodes, kind, referent?.path, defaultReferentId]);
  React.useEffect(() => {
    if (kind === 'scene' && referent === undefined && defaultReferentId) {
      onReferentChange(scenes?.find(sc => sc.id == defaultReferentId));
    }
  }, [scenes, kind, referent === undefined, defaultReferentId]);
  React.useEffect(() => {
    if (selectedSeason) {
      setEpisodes(undefined);
      return selectedSeason.collection('episodes').onSnapshot(
        q => setEpisodes(q.docs.map(doc => doc.ref))
      );
    }
    setEpisodes([]);
  }, [selectedSeason]);
  React.useEffect(() => {
    if (selectedEpisode && kind === 'scene') {
      setScenes(undefined);
      return selectedEpisode.collection('scenes').onSnapshot(
        q => setScenes(sortScenes(q.docs).map(doc => doc.ref))
      );
    }
    setScenes([]);
  }, [selectedEpisode, kind]);
  return (
    <View style={{ flexDirection: "row", flexWrap: "wrap", gap: 16, ...style }}>
      <Labelled label={ph('season') as string}>
        <Select
          entries={seasons?.map(doc => doc.ref)}
          entryKey={getId} entryLabel={getId}
          selectedEntry={selectedSeason}
          onEntryChange={setSelectedSeason}
          autoselect
        />
      </Labelled>
      {(!episodes || episodes.length > 0 || (allowOther && kind === 'episode')) &&
        <Labelled label={ph('episode') as string}>
          <Select
            entries={episodes} entryKey={getId} entryLabel={getId}
            selectedEntry={kind === 'episode' ? referent : selectedEpisode}
            onEntryChange={kind === 'episode' ? onReferentChange : setSelectedEpisode}
            resetSelectionIfNotPresent={false}
            other={
              allowOther && kind === 'episode' ? {
                placeholder: ph('episode-number') as string,
                transform:
                  // TODO Validate enteredValue
                  (enteredValue => selectedSeason?.collection('episodes').doc(enteredValue))
              } :
                undefined
            }
          />
        </Labelled>
      }
      <View>
        {kind === 'scene' && (!scenes || scenes.length > 0 || allowOther) &&
          <Labelled label={ph('scene') as string}>
            <Select
              entries={scenes} entryKey={getId} entryLabel={getId}
              selectedEntry={referent}
              onEntryChange={onReferentChange}
              resetSelectionIfNotPresent={false}
            />
          </Labelled>
        }
      </View>
    </View>
  );
}
