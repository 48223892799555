import { DocumentData, Query, QuerySnapshot } from "@atgof-firebase/types";

export type Unsubscribe = () => void;
export type OnNext<S> = (next: S) => Unsubscribe | void;
export type OnSnapshot<S, U> = (onNext: OnNext<S>) => U;

export function onSnapshot<S, R>(
  onSnapshot_: OnSnapshot<S, Unsubscribe | void> | undefined,
  transform: (next: S) => R,
  onNext: OnNext<R | undefined>
) {
  let innerUnsub = onNext(undefined);
  if (onSnapshot_) {
    const snapshotUnsub = onSnapshot_(snapshot => {
      if (innerUnsub) innerUnsub();
      innerUnsub = onNext(transform(snapshot));
    });
    return () => {
      if (innerUnsub) innerUnsub();
      if (snapshotUnsub) snapshotUnsub();
    };
  }
  return innerUnsub;
}


export function onQuerySnapshot<R>(
  query: Query | 0 | false | null | undefined,
  transform: (next: QuerySnapshot<DocumentData>) => R,
  onNext: OnNext<R | undefined>
) {
  return onSnapshot(
    query ? (onNext_ => query.onSnapshot(onNext_)) : undefined,
    transform, onNext
  );
}

export function onSnapshots<V, S>(
  values: readonly V[] | undefined,
  onSnapshotForValue: (value: V) => OnSnapshot<S, Unsubscribe | void>,
  onNext: OnNext<S[] | undefined>
): Unsubscribe | void {
  return onSnapshot(
    values ?
      (onNext_ => {
        if (!values.length) return onNext_([]);
        const acc = new Map<number, S>();
        const unsubs = values.map((v, i) => onSnapshotForValue(v)(s => {
          acc.set(i, s);
          if (acc.size == values.length) onNext_(values.map((_, i) => acc.get(i)!!));
        }));
        return () => {
          for (const unsub of unsubs) if (unsub) unsub();
        };
      }) : undefined,
    (next: S[]) => next,
    onNext
  );
}

function flattenUndefineds<S>(arrs: (S[] | undefined)[] | undefined): S[][] | undefined {
  return arrs?.every(x => x !== undefined) ? (arrs as S[][]) : undefined;
}

function flatten<S>(arrs: (S[] | undefined)[] | undefined): S[] | undefined {
  return flattenUndefineds(arrs)?.flat();
}

export function flattening<S>(
  onNext: OnNext<S[] | undefined>
): OnNext<(S[] | undefined)[] | undefined> {
  return next => onNext(flatten(next));
}

export function flatteningUndefineds<S>(
  onNext: OnNext<S[][] | undefined>
): OnNext<(S[] | undefined)[] | undefined> {
  return next => onNext(flattenUndefineds(next));
}
