import React from "react";
import { ActivityIndicator, Modal, SafeAreaView, View } from "react-native";
import { useSwipeable } from "react-swipeable";
import { ImageViewHeaderProps, ImageViewHeader } from "./ImageViewHeader";

export default function ImageView(
  { images, imageIndex, setImageIndex, visible, onRequestClose, ...props }:
    ImageViewHeaderProps & { visible: boolean }
) {
  const [loaded, setLoaded] = React.useState(false);
  React.useEffect(() => setLoaded(false), [images, imageIndex, visible]);
  const handlers = useSwipeable({
    onSwipedUp: onRequestClose,
    onSwipedRight: () => setImageIndex(index => index ? index - 1 : 0),
    onSwipedLeft: () => setImageIndex(
      index => index !== undefined && index < images.length - 1 ?
        index + 1 : (images.length ? images.length - 1 : 0)
    )
  });
  if (!(visible && imageIndex < images.length)) return null;
  return (
    <Modal visible={visible} onRequestClose={onRequestClose} style={{ width: "100%", height: "100%", marginTop: 20 }}>
      <SafeAreaView style={{ height: "100%" }}>
        <ImageViewHeader
          images={images} imageIndex={imageIndex} setImageIndex={setImageIndex}
          onRequestClose={onRequestClose}
          {...props}
        />
        {!loaded && <ActivityIndicator />}
        <img
          {...handlers}
          src={images[imageIndex].uri}
          style={{
            ...(loaded ? {} : { display: "none" }),
            width: "100%",
            height: "100%",
            objectFit: "contain"
          }}
          onLoad={() => setLoaded(true)}
        />
      </SafeAreaView>
    </Modal>
  );
}
