import { useNavigation } from "@react-navigation/native";
import { IconButton } from "react-native-paper";
import { Scene } from "../common/scene";
import { MaterialIcons } from "@expo/vector-icons";

export function SceneButton({ scene }: { scene: Scene }) {
  const { navigate } = useNavigation();
  return (
    <IconButton
      icon={({ size, color }) => <MaterialIcons name="description" size={size} color={color} />}
      onPress={() => navigate('scene', { scenePath: scene.path })}
    />
  );
}
