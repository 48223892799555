import {
  CollectionReference, Query, QueryDocumentSnapshot
} from '@atgof-firebase/firebase';
import React from "react";
import { ListRenderItem, ListRenderItemInfo } from "@shopify/flash-list";
import { View } from 'react-native';
import { FlashList } from './FlashList';
import { Searchbar } from 'react-native-paper';
import { LanguageContext } from '../common/language';
import { matchesSearch, tokeniseQuery } from '../common/search';

export type CollectionSpec<T> = {
  collection: CollectionReference;
  addConstraints?: (q: Query) => Query | undefined;
  sortComparison?: ((a: T, b: T) => number) | undefined;
  process: (items: QueryDocumentSnapshot[]) => T[];
  onCountChanged?: (count: number) => void;
  includeMetadata?: boolean;
}

export type CollectionListSpec<T, H> = CollectionSpec<T> & {
  renderItem: ListRenderItem<T>;
  searchTokens: (item: T) => (string | undefined)[];
  renderHeader?: (headerData?: H) => React.ReactElement;
  headerData?: H;
}

export type CollectionListProps = {
  collections: CollectionListSpec<any, any>[] | undefined;
  estimatedItemSize: number
}

type Entry = { collIdx: number; obj: any; header?: boolean }

function sortItems<T>(compare: ((a: T, b: T) => number) | undefined, items: T[] | undefined) {
  if (!compare) return items;
  return items?.sort(compare);
}


function onCollectionItems<T>(collSpec: CollectionSpec<T> | undefined, onItems: (items: T[] | undefined) => void) {
  if (!collSpec) return;
  const { collection, addConstraints, sortComparison, process, onCountChanged, includeMetadata } = collSpec;
  const q = addConstraints ? addConstraints(collection) : collection;
  return q?.onSnapshot({ includeMetadataChanges: includeMetadata }, querySnapshot => {
    onItems(querySnapshot ? sortItems(sortComparison, process(querySnapshot.docs)) : undefined);
    onCountChanged && onCountChanged(querySnapshot.size);
  });
}

export function useCollectionItems<T>(collSpec: CollectionSpec<T> | undefined) {
  const [items, setItems] = React.useState<T[] | undefined>();
  React.useEffect(() => onCollectionItems(collSpec, setItems), [collSpec]);
  return items;
}

export default function CollectionList(
  { collections, estimatedItemSize }: CollectionListProps
) {
  const { ph } = React.useContext(LanguageContext);
  const [searchQuery, setSearchQuery] = React.useState('');
  const [items, setItems] = React.useState<Record<number, any[] | undefined>>();
  React.useEffect(() => {
    setItems(undefined);
    const unsubs = collections?.map(
      (collSpec, collIdx) => onCollectionItems(collSpec, collItems => setItems(items => ({ ...items, [collIdx]: collItems })))
    );
    return () => { for (const unsub of (unsubs || [])) if (unsub) unsub(); };
  }, [collections]);

  const [data, setData] = React.useState<Entry[] | undefined>(undefined);
  React.useEffect(
    () => {
      const queryTokens = tokeniseQuery(searchQuery);
      setData(
        collections?.flatMap(({ renderHeader, headerData, searchTokens }, collIdx) => {
          let dataItems = ((items && items[collIdx]) || []);
          if (queryTokens?.length) {
            dataItems = dataItems.filter(item => matchesSearch(queryTokens, searchTokens(item)));
          }
          return [
            ...(renderHeader ? [{ collIdx, obj: headerData, header: true }] : []),
            ...dataItems.map(obj => ({ collIdx, obj }))
          ];
        })
      )
    },
    [items, searchQuery]
  );

  const renderItem = React.useCallback((info: ListRenderItemInfo<Entry>) => {
    const { item } = info;
    if (!collections || item.collIdx >= collections.length) return null;
    if (item.header) {
      const { renderHeader } = collections[item.collIdx];
      if (renderHeader) return renderHeader(item.obj);
    }
    else {
      const { renderItem } = collections[item.collIdx];
      return renderItem({ ...info, item: item.obj });
    }
    return null;
  }, [collections]);

  const getItemType = React.useCallback((item: Entry) => {
    if (typeof item === 'number') return `header-${item}`;
    return `item-${item.collIdx}`;
  }, [collections]);

  return (
    <View style={{ height: "100%" }}>
      <Searchbar
        style={{ marginVertical: 4 }}
        placeholder={ph('search') as string}
        onChangeText={setSearchQuery}
        value={searchQuery}
      />
      <FlashList data={data}
        renderItem={renderItem}
        getItemType={getItemType}
        estimatedItemSize={estimatedItemSize}
      />
    </View>
  );
}
