import React from "react";
import { LanguageContext } from "../common/language";
import SessionPreferences from "./SessionPreferences";
import { Dialog, Portal } from "react-native-paper";

export default function IntroDialog(
  { title, children, actions }:
    { title?: string; children: React.ReactNode; actions?: React.ReactNode }) {
  const { ph } = React.useContext(LanguageContext);
  return (
    <Portal>
      <Dialog visible={true} onDismiss={() => { }}>
        <Dialog.Title>{title ?? (ph('app-name') as string)}</Dialog.Title>
        <Dialog.Content style={{ gap: 8 }}>
          <SessionPreferences />
          {children}
        </Dialog.Content>
        <Dialog.Actions style={{ gap: 8, flexWrap: "wrap" }}>
          {actions}
        </Dialog.Actions>
      </Dialog>
    </Portal>
  );
}
