import { DocumentReference } from "@atgof-firebase/types";
import { Checkbox, ProgressBar, Text } from "react-native-paper";
import { ScenesTable, ScenesTableColumn, ScenesTableColumnGroup, locationColumn, sceneColumn, useScenesTableSectionForEpisode } from "./ScenesTable";
import React from "react";
import { LanguageContext } from "../common/language";
import { Phrase, PhraseKey } from "../common/phrases";
import { Scene, TimingData } from "../common/scene";
import { View } from "react-native";
import { Editable } from "./Editable.web";
import { FieldValue } from "@atgof-firebase/firebase";
import { ScriptButton } from "./ScriptButton";
import FixtureLabel from "../common/FixtureLabel";

function formatSeconds(seconds: number | undefined) {
  if (seconds === undefined) return "";
  const minutes = Math.floor(seconds / 60);
  const remainder = seconds % 60;
  return (minutes ? (minutes + "'") : '') +
    (remainder < 10 ? ('0' + remainder) : remainder) + '"';
}

function parseSeconds(s: string | undefined) {
  if (!s?.length) return;
  s = s.toLocaleLowerCase();
  const parts = s?.split(/\D/).filter(part => part.match(/^\d+$/)).reverse();
  if (!parts.length) return;
  const n = parseInt(parts[0]);
  if (s.endsWith("m") || s.endsWith("'")) return 60 * n; // TODO Not very robust
  return n + 60 * (parts.length > 1 ? parseInt(parts[1]) : 0);
}

function timingColumn(
  ph: (k: PhraseKey) => Phrase,
  key: keyof TimingData
): ScenesTableColumn<Scene> {
  return {
    key,
    label: ph(`timings:${key}`) as string,
    render: ({ item }) => {
      const { timings } = item;
      const seconds = timings ? timings[key] : undefined;
      const onChange = React.useCallback(
        (v: string) => {
          const newSeconds = parseSeconds(v);
          item.ref.update({
            ["timings." + key]: newSeconds ?? FieldValue.delete(), // TODO lastModified etc
          })
        },
        [item.ref.path, key]
      );
      return (
        <Editable
          style={{ textAlign: "right" }}
          text={formatSeconds(seconds)}
          onChange={onChange}
        />
      );
    }
  };
}

function mostDefinitiveTiming(scene: Scene) {
  const { timings } = scene;
  if (!timings) return;
  const { pa, rehearsal, shot, edit } = timings;
  return edit ?? shot ?? rehearsal ?? pa;
}

export function EpisodeTimingsView({ episodeRef }: {
  episodeRef: DocumentReference | undefined;
}) {
  const { ph } = React.useContext(LanguageContext);
  const sections = useScenesTableSectionForEpisode(episodeRef, undefined);
  const columnGroups = React.useMemo<ScenesTableColumnGroup[]>(
    () => [
      {
        key: 'core',
        kind: 'scene',
        columns: [
          sceneColumn(ph, scene => <ScriptButton parent={scene} />),
          locationColumn(ph),
          {
            key: 'ignore',
            label: ph('ignore') as string,
            render: ({ item }) =>
              <Checkbox
                status={item.ignoreInTimings ? 'checked' : 'unchecked'}
                onPress={() => item.ref.update({ ignoreInTimings: !item.ignoreInTimings }) /* TODO lastModified etc */}
              />
          },
          {
            key: 'notes',
            label: ph('notes') as string,
            render: ({ item }) =>
              <Editable text={item.paNotes ?? ""}
                onChange={paNotes => item.ref.update({ paNotes }) /* TODO lastModified etc*/}
              />
          },
          timingColumn(ph, 'pa'),
          timingColumn(ph, 'rehearsal'),
          timingColumn(ph, 'shot'),
          timingColumn(ph, 'edit'),
          {
            key: 'total',
            label: ph('running-total') as string,
            render: ({ item }) => {
              const seconds = mostDefinitiveTiming(item);
              if (seconds === undefined) return null;
              return <Text style={{ textAlign: "right" }}>{formatSeconds(seconds)}</Text>;
            }
          }
        ]
      }
    ],
    [ph]
  );
  if (!sections) return <ProgressBar indeterminate />;
  const runningTotal = sections.reduce(
    (total, section) =>
      total +
      (
        section.scenes?.reduce(
          (total, scene) =>
            total + ((scene.ignoreInTimings ? 0 : mostDefinitiveTiming(scene)) ?? 0),
          0
        ) ?? 0
      ),
    0);
  return (
    <View style={{ gap: 10, flex: 1 }}>
      <View style={{ alignSelf: "flex-end" }}>
        <FixtureLabel text={ph('running-total') as string} />
        <Text variant="headlineMedium" style={{ textAlign: "right" }}>
          {formatSeconds(runningTotal)}
        </Text>
      </View>
      <ScenesTable sections={sections} columnGroups={columnGroups} />
    </View>
  );
}
