import { DocumentReference, DocumentSnapshot } from "@atgof-firebase/types";

export function getDocumentReference<P extends string | undefined>(
  user: DocumentSnapshot,
  documentPath: P
): DocumentReference | (undefined extends P ? undefined : never) {
  if (documentPath === undefined)
    return undefined as (undefined extends P ? undefined : never);
  return user.ref.firestore.doc(documentPath);
}
