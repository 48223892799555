import { SegmentedButtons } from "react-native-paper";
import { languages } from "../common/phrases";
import { LanguageContext } from "../common/language";
import React from "react";

export default function LanguagePicker() {
  const { language, setLanguage } = React.useContext(LanguageContext);
  return (
    <SegmentedButtons
      value={language}
      onValueChange={setLanguage}
      buttons={
        languages.map(({ lang, label }) => ({ value: lang, label }))
      }
      style={{ maxWidth: 200 }}
    />
  );
};
