import { DocumentData, DocumentReference, DocumentSnapshot, FirestoreError, QuerySnapshot } from "@atgof-firebase/types";
import { Colour, DarkCompatibleColour, MembershipCategory, normaliseColour } from "./model/project/membership";
import { OnNext, onQuerySnapshot, onSnapshots } from "./onSnapshot";
import { notDeleted } from "./general";

export type MembershipResults = null | { error: FirestoreError } | { snapshot: QuerySnapshot<DocumentData> }

export type UserInkColour = {
  displayName: string;
  userId: string;
  inkColour: DarkCompatibleColour
}

function userInkColourForMembership(doc: DocumentSnapshot): UserInkColour | undefined {
  const inkColour: Colour | undefined = doc.get('inkColour');
  if (!inkColour) return;
  const user: DocumentReference = doc.get('user');
  return {
    displayName: doc.get('displayName'),
    userId: user.id,
    inkColour: normaliseColour(inkColour)
  };
}

export function onUserInkColours(
  project: DocumentReference | undefined,
  categories: readonly MembershipCategory[],
  onNext: OnNext<UserInkColour[] | undefined>
) {
  if (!categories.length) {
    onNext([]);
    return;
  }
  if (!project) {
    onNext(undefined);
    return;
  }
  return onSnapshots<MembershipCategory, UserInkColour[]>(
    categories,
    category => {
      return onNext => notDeleted(
        project.collection('memberships')
          .where('roles.' + category + '.user', '==', true)
      ).onSnapshot(
        ({ docs }) => onNext(
          docs.map(userInkColourForMembership).filter(x => x) as UserInkColour[]
        )
      );
    },
    entries => onNext(entries?.flat())
  );
}
