import { Icon, useTheme } from "react-native-paper";
import { DarkCompatibleColour } from "../common/model/project/membership";
import { Text, TouchableOpacity, View } from "react-native";
import React from "react";
import ColorPicker, { Panel1, Swatches, HueSlider } from 'reanimated-color-picker';
import { LanguageContext } from "../common/language";
import { ColourWell } from "./ColourWell";

function ColourIndicator({ value, dark, withExplanation, selected }: {
  value: string; dark: boolean; withExplanation?: boolean; selected?: boolean
}) {
  const { ph } = React.useContext(LanguageContext);
  const { colors } = useTheme();
  const wellSize = withExplanation ? 18 : 10;
  return (
    <View
      style={{
        gap: 4,
        justifyContent: "center",
        alignItems: "center",
        minWidth: 26, minHeight: 26,
        padding: 8,
        borderRadius: 8,
        borderColor: selected ? colors.primary : (dark ? '#ffffff' : '#000000'),
        borderWidth: 1,
        backgroundColor: dark ? '#000000' : '#ffffff'
      }}
    >
      <ColourWell value={value} size={wellSize} />
      {withExplanation ?
        <Text style={{ color: value }} >
          {ph(dark ? 'on-dark-background' : 'on-light-background') as string}
        </Text> :
        null}
    </View >
  );
}

export function ColourControl({
  value, onChange
}: {
  value: DarkCompatibleColour,
  onChange?: (value: DarkCompatibleColour) => void
}) {
  const { colors } = useTheme();
  const [editing, setEditing] = React.useState<'light' | 'dark'>();
  return (
    <View style={{ gap: 4 }}>
      <View style={{ flexDirection: "row", gap: 8 }}>
        {[false, true].map(
          dark => {
            const k = dark ? 'dark' : 'light';
            const c = value[k];
            return onChange ?
              <TouchableOpacity
                key={k}
                onPress={() => setEditing(k)}
              >
                <ColourIndicator
                  value={c} dark={dark} withExplanation
                  selected={editing === k}
                />
              </TouchableOpacity> :
              <View key={k} style={{
                gap: 2, flexDirection: "row", alignItems: "center"
              }}>
                <Icon
                  source={dark ? 'weather-night' : 'weather-sunny'}
                  size={18}
                  color={colors.onBackground}
                />
                <ColourIndicator key={k} value={c} dark={dark} />
              </View>;

          }
        )}
      </View>
      {onChange && editing ?
        <ColorPicker
          value={editing ? value[editing] : undefined}
          onComplete={c => onChange({ ...value, [editing]: c.hex })}
        >
          <Panel1 />
          <HueSlider />
          <Swatches />
        </ColorPicker> : null}
    </View>
  );
}

