import React from "react";
import { Phrase } from "./phrases";
import { CoreComponentsContext } from "./core-components";
import { Scene, SceneData } from "./scene";
import { LanguageContext } from "./language";
import { ScriptScene } from "./file-import";

const SCENE_LABEL_SEPARATOR = ' > ';

export function SceneLabel(
  { sceneId, continuousFilmingSequence, fontSize, color,
    separator = SCENE_LABEL_SEPARATOR }:
    {
      sceneId: string;
      continuousFilmingSequence: string[] | undefined;
      fontSize?: number | undefined,
      color?: string | undefined,
      separator?: string
    }
) {
  const posInSequence = continuousFilmingSequence?.findIndex(id => id === sceneId) ?? -1;
  const [sceneRefsBefore, sceneRefsAfter] = posInSequence === -1 ? [undefined, undefined] :
    [
      continuousFilmingSequence?.slice(0, posInSequence),
      continuousFilmingSequence?.slice(posInSequence + 1)
    ];
  const primaryTextStyle = {
    marginLeft: 0, marginRight: 0, paddingLeft: 0, paddingRight: 0,
    fontSize, color
  };
  return (
    <CoreComponentsContext.Consumer>
      {({ Text }) =>
        <React.Fragment>
          <Text style={primaryTextStyle}>{
            (sceneRefsBefore?.join(separator) ?? '') +
            (sceneRefsBefore?.length ? separator : '')
          }</Text>
          <Text style={{ ...primaryTextStyle, fontWeight: "bold" }}>
            {sceneId}
          </Text>
          <Text style={primaryTextStyle}>{
            (sceneRefsAfter?.length ? separator : '') +
            (sceneRefsAfter?.join(separator) ?? '')
          }</Text>
        </React.Fragment>
      }
    </CoreComponentsContext.Consumer>
  );
}

type SceneStatus = {
  isComplete: boolean;
  hasPendingActions: boolean;
  episodeId: string;
  sceneId: string;
  continuousFilmingSequence: string[] | undefined;
  timeOfDay: string
};

export function statusForScene(scene: Scene | SceneData | ScriptScene): SceneStatus {
  const { id } = scene;
  const episodeId = 'episodeId' in scene ? scene.episodeId : undefined;
  const comment = 'comment' in scene ? scene.comment : undefined;
  return {
    isComplete: 'isComplete' in scene && scene.isComplete ? true : false,
    hasPendingActions: 'actions' in scene && scene.actions?.find(({ isDone }) => !isDone) ?
      true : false,
    episodeId: episodeId ?? '',
    sceneId: id ?? '',
    continuousFilmingSequence: 'continuousFilmingSequence' in scene ?
      scene.continuousFilmingSequence?.map(({ id }) => id) : undefined,
    timeOfDay: comment ?? ''
  };
}

export function SceneStatusView(
  { scene, excludeTimeOfDay, style, fontSize = 18, color, sceneLabelSeparator }: {
    scene: Scene | SceneData | ScriptScene, excludeTimeOfDay?: boolean,
    style?: {
      flex: number,
      backgroundColor?: string | undefined,
    },
    color?: string | undefined,
    fontSize?: number | null | undefined,
    sceneLabelSeparator?: string
  }) {
  const {
    isComplete,
    hasPendingActions,
    episodeId,
    sceneId,
    continuousFilmingSequence,
    timeOfDay
  } = statusForScene(scene);
  const fontSize_ = fontSize === null ? undefined : fontSize;
  return (
    <CoreComponentsContext.Consumer>
      {({ View, Text }) =>
        <View style={{ flexDirection: "row", alignItems: "center", gap: 8, ...style }}>
          {isComplete ?
            (hasPendingActions ?
              <Text style={{ color: "#FD8D0E" }}>⬤</Text>
              : <Text>✅</Text>) :
            null}
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <Text style={{
              marginLeft: 0, paddingLeft: 0,
              fontSize: fontSize_, fontWeight: "bold", color
            }}>
              {episodeId + '/'}
            </Text>
            <SceneLabel
              sceneId={sceneId}
              continuousFilmingSequence={continuousFilmingSequence}
              fontSize={fontSize_}
              color={color}
              separator={sceneLabelSeparator}
            />
          </View>
          {excludeTimeOfDay ? null : <Text>{timeOfDay}</Text>}
        </View>
      }
    </CoreComponentsContext.Consumer>
  );
}

export type SceneLocationInfo = {
  intExtDesc: string;
  setsDesc: string;
  timeOfDay?: string
}

export function intExtDescription(
  scene: SceneData | ScriptScene,
  ph: (key: string) => Phrase
): string {
  return (scene.isInterior ?
    ph('int') + (scene.isExterior ? ' & ' + ph('ext') : '') :
    (scene.isExterior ? ph('ext') : '')) as string;
}

export function setsDescription(scene: SceneData | ScriptScene) {
  return ('sets' in scene ? scene.sets?.join(', ') : scene.location) ?? '';
}

export function SceneLocation(
  {
    scene,
    fontSize,
    preexistingSets
  }: {
    scene: Scene | SceneData | ScriptScene;
    fontSize?: number | undefined;
    preexistingSets?: string[] | undefined
  }
) {
  const primaryTextStyle = { fontSize };
  const timeOfDay = 'timeOfDay' in scene ? scene.timeOfDay : undefined;
  const sets = 'sets' in scene ? scene.sets : (scene.location ? [scene.location] : []) ?? [];
  const isNew = preexistingSets && sets?.some(s => !preexistingSets.includes(s));
  return (
    <CoreComponentsContext.Consumer>
      {({ Text, NewItemIndicator, TimeOfDayRepresentation }) =>
        <LanguageContext.Consumer>
          {({ ph }) =>
            <React.Fragment>
              <Text style={primaryTextStyle}>{
                intExtDescription(scene, ph).toLocaleUpperCase() + '  '
              }</Text>
              {isNew ? <NewItemIndicator /> : null}
              <Text style={{ ...primaryTextStyle, fontWeight: 'bold' }}>{
                setsDescription(scene)
              }</Text>
              <TimeOfDayRepresentation timeOfDay={timeOfDay} />
            </React.Fragment>
          }
        </LanguageContext.Consumer>
      }
    </CoreComponentsContext.Consumer>
  );
}

